import React, { useCallback, useEffect, useState } from "react";
import styles from "./VehcileMap.module.css";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import VehcileMapCard from "../VehcileMapCard/VehcileMapCard";
import { MapStyles } from "../../constants";
import MapCar from "../../assets/img/mapCar.png";

function VehcileMap({ vehicles, onClick }) {
  console.log(vehicles, "mapVehicle");
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedElementLocation, setSelectedElementLocation] = useState(null);
  const [activeMarker, setActiveMarker] = useState();
  const [currentPosition, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ["geometry", "places"],
  });
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  // function to find center of all vehicles
  useEffect(() => {
    if (!isLoaded) {
      const locationsArr = vehicles?.map((item) => JSON.parse(item?.location));

      const latitudeArr = locationsArr?.filter((item) => {
        const parsedValue = Number(item?.coords?.latitude);
        return parsedValue;
      });
      const longitudeArr = locationsArr?.filter((item) => {
        const parsedValue = Number(item?.coords?.longitude);
        return parsedValue;
      });

      const sumLatitude = latitudeArr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumLongitude = longitudeArr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      const centerLat = sumLatitude / locationsArr?.length;
      const centerLng = sumLongitude / locationsArr?.length;

      const isValidCenter = !isNaN(centerLat) && !isNaN(centerLng);

      const fallbackCenter = {
        lat: 24.9346328,
        lng: 67.0847751,
      };

      const mapCenter = isValidCenter
        ? { lat: centerLat, lng: centerLng }
        : fallbackCenter;

      setCurrentLocation(mapCenter);
    }
  }, [vehicles, isLoaded]);

  // Function to calculate bounds and set the auto-zoom
  const onLoad = useCallback((map) => {
    map.setOptions({
      styles: MapStyles.styles,
    });

    //Type definitions for non-npm package Google Maps JavaScript API 3.50
    var bounds = new window.google.maps.LatLngBounds();

    vehicles?.forEach((item) => {
      const location = item?.location ? JSON.parse(item?.location) : {};

      if (
        isNaN(location?.coords?.latitude) ||
        isNaN(location?.coords?.longitude)
      )
        return;

      bounds.extend({
        lat: Number(location?.coords?.latitude),
        lng: Number(location?.coords?.longitude),
      });
    });

    map.fitBounds(bounds); // <-------- resize based on markers, native
  }, []);

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const mapLocationLoad = (item) => {
    const location = item?.location && JSON.parse(item?.location);

    Geocode.fromLatLng(
      `${location?.coords?.latitude}`,
      `${location?.coords?.longitude}`
    ).then(
      (response) => {
        const currentAddress = response.results[0].formatted_address;
        setSelectedElementLocation(currentAddress);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return isLoaded ? (
    <div style={{ width: "100%", height: "100vh" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        disableDefaultUI={true}
        options={mapOptions}
        onLoad={onLoad}
        zoom={14}
        center={currentPosition}
      >
        {vehicles?.map((item, index) => {
          const location = item?.location ? JSON.parse(item?.location) : {};

          return (
            <MarkerF
              key={index}
              icon={{
                url: MapCar,
                anchor: new window.google.maps.Point(50, 100),
                scaledSize: new window.google.maps.Size(100, 100),
              }}
              position={{
                lat: location?.coords?.latitude,
                lng: location?.coords?.longitude,
              }}
              onClick={(marker) => {
                mapLocationLoad(item);
                setSelectedElement(item);
                setActiveMarker(marker);
              }}
            >
              {selectedElement?.id === item?.id ? (
                <InfoWindow
                  position={activeMarker?.latLng}
                  className={styles.infoWindow}
                  onCloseClick={() => {
                    setSelectedElement(null);
                    setActiveMarker(null);
                  }}
                  zIndex={100}
                >
                  <VehcileMapCard
                    map={true}
                    item={selectedElement}
                    address={selectedElementLocation}
                    onClick={onClick}
                  />
                </InfoWindow>
              ) : null}
            </MarkerF>
          );
        })}
      </GoogleMap>
    </div>
  ) : (
    <div>Loading Map...</div>
  );
}

export default VehcileMap;
