import React from "react";
import styles from "./ContactsTable.module.css";
import { Table } from "react-bootstrap";
import Checkbox from "../Checkbox/Checkbox";

function ContactsTable({
  data,
  check,
  onCheck,
  checked,
  onClickEdit,
  onClickDelete,
}) {
  console.log(data, "contactsDetails");
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className={styles.th}>Name</th>
          <th className={styles.th}>Email</th>
          <th className={styles.th}>Mobile No</th>
          <th className={styles.thAddress}>Home Address</th>
          <th className={styles.thAddress}>Office Address</th>
          <th className={styles.th}>Company</th>
          <th className={styles.th}></th>
          <th className={styles.th}></th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            {/* <td className={styles.td}>
              <Checkbox
                checked={checked}
                name={item.id}
                value={check}
                onChange={(e) => onCheck(e, item)}
              />
            </td> */}
            <td className={styles.td}>{item?.full_name}</td>
            <td className={styles.td}>{item?.email}</td>
            <td className={styles.td}>
              {item?.mobile_no ? `+${item?.mobile_no}` : ""}
            </td>

            <td className={styles.td}>{item?.home_address}</td>
            <td className={styles.td}>{item?.office_address}</td>
            <td className={styles.td}>{item?.company}</td>
            <td className={styles.id} onClick={() => onClickEdit(item)}>
              Edit
            </td>
            <td>
              <span
                className={styles.delete}
                onClick={() => onClickDelete(item)}
              >
                Delete
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ContactsTable;
