import React from "react";
import styles from "./DashboardCard.module.css";

function DashboardCard({ count, text }) {
  return (
    <div className={styles.card}>
      <div className={styles.count}>{count}</div>
      <div className={styles.details}>{text}</div>
    </div>
  );
}

export default DashboardCard;
