export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return;

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isBlobUrl = (url) => {
  const protocol = window.location.protocol;
  return url.startsWith("blob:" + protocol);
};
