import React, { useEffect } from "react";

const ScrollLock = ({ isLocked }) => {
  useEffect(() => {
    if (isLocked) {
      // Disable scrolling by adding a CSS class to the <body> element
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling by removing the CSS class from the <body> element
      document.body.style.overflow = "auto";
    }

    // Cleanup: Restore scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLocked]);

  return null; // This component doesn't render anything
};

export default ScrollLock;
