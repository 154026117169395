import React, { useRef, useState } from "react";
import styles from "./JobBatchUpload.module.css";
import {
  BatchTable,
  BatchTableNew,
  Block,
  Button,
  Header,
  ImagePOD,
  ScrollLock,
} from "../../components";
import { Deliveries } from "../../assets/svg";
import { AuthService, ContactsService, TasksService } from "../../services";
import Papa from "papaparse";
import { DateTimeHelper, FileSizeHelper } from "../../helper";
import { useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import { LoadingContext } from "../../context";
import { getToken } from "../../services/auth-service";

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
Geocode.setLanguage("en");

function JobBatchUpload() {
  const hiddenFileInput = useRef(null);
  const [rowCount, setRowCount] = useState(0);
  const [batchData, setBatchData] = useState([]);
  const [csvFile, setCsvFile] = useState();
  const [error, setError] = useState();
  const [errorIndex, setErrorIndex] = useState();
  const [disable, setDisable] = useState(false);
  const { setLoading } = LoadingContext.useLoading();

  const navigate = useNavigate();

  const onChangeFile = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    setCsvFile(file);
    setError(null);
    setErrorIndex(null);
    setBatchData(null);
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        setRowCount(results?.data.length);
      },
    });
  };

  const processCSV = async () => {
    let validation = null;
    let validationIndex = null;

    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Check if the required headers are available
        console.log(results?.meta?.fields, "meta");
        const requiredHeaders = [
          "Delivery Date(DD/MM/YYYY)",
          "Delivery Time(HH:mm)",
          "Delivery Address(Required)",
          "Pickup Date(MM/DD/YYYY)",
          "Pickup Time(HH:mm)",
          "Pickup Address",
        ];
        const missingHeaders = requiredHeaders.filter(
          (header) => !results.meta.fields.includes(header)
        );

        if (missingHeaders.length > 0) {
          validation = `${missingHeaders.join(", ")} ${
            missingHeaders.length > 1 ? "are" : "is"
          } missing in the CSV file.`;
        }

        if (results.data.length > 100) {
          validation = "Maximum 100 rows can be uploaded in one file.";
        }
        if (validation) {
          setError(validation);
          setErrorIndex(validationIndex);
        } else {
          setError(null);
          setErrorIndex(null);
          const transformedData = results.data.map(extractData);
          console.log(transformedData, "transformedData");
          setBatchData(transformedData);
        }
      },
    });
  };
  const extractData = (item) => {
    const extractedData = {};

    for (let key in item) {
      let formattedKey = key.replace(/\s+/g, "_"); // Replace spaces with underscores
      formattedKey = formattedKey.replace(/[:\/\(\)]+/g, ""); // Remove colons, slashes, and brackets
      formattedKey = formattedKey.replace(/[\W\d]+/g, ""); // Remove all non-word characters (including numbers)
      formattedKey =
        formattedKey.charAt(0).toLowerCase() + formattedKey.slice(1); // Convert first letter to lowercase

      extractedData[formattedKey] = item[key];
    }

    return extractedData;
  };

  const onUpload = async () => {
    setLoading(true);
    const { tenant_id } = await getToken();
    const updatedBatchData = batchData.map((element) => ({
      ...element,
      pickup_address: element.pickup_Address,
      dropoff_address: element.delivery_AddressRequired,
      pickup_date: DateTimeHelper.excelDateToJSDate(
        element.pickup_DateMMDDYYYY
      ),
      delivery_date: DateTimeHelper.excelDateToJSDate(
        element.delivery_DateDDMMYYYY
      ),
      recipient_phone_no: element.customer_Phoneex_,
      recipient_name: element?.customer_Name,
      recipient_email: element?.customer_Email,
      save_to_contacts_recipient:
        element?.save_to_contacts_truefalse === "TRUE" ? true : false,
      delivery_order_no: element?.dO_No,
      tenant_id,
      status: "Pending",
      sender_email: element?.sender_Email,
      sender_name: element?.sender_Name,
      sender_phone_no: element?.sender_Phoneex_,
      delivery_type: element?.delivery_Type,
      delivery_vehicle_type: element?.delivery_Vehicle_Type,
      delivery_window: element?.delivery_window,
    }));
    console.log(updatedBatchData, "updatedBatchData");

    TasksService.addBatchDeliveryTask(updatedBatchData)
      .then(async (res) => {
        if (res?.data) {
          console.log(res?.data, "batchData");
          await saveToContacts();
          res?.data && navigate("/delivery-jobs");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const saveToContacts = async () => {
    const { tenant_id } = await getToken();
    const savingContacts = batchData.map((element) => ({
      full_name: element?.customer_Name,
      email: element?.customer_Email,
      mobile_no: element?.customer_Phoneex_,
      home_address: element?.delivery_AddressRequired,
      type: "Recipient",
      tenant_id,
    }));
    console.log(savingContacts, "savingContacts");

    await ContactsService.addBatchContacts(savingContacts)
      .then((res) => console.log(res?.data, "contactsUploadingResponse"))
      .catch((err) => console.log(err));
  };
  return (
    <Block
      style={{ padding: "0px 25px", overflowX: "hidden", overflowY: "hidden" }}
    >
      <Header
        icon={Deliveries}
        title="Batch Upload Delivery Tasks"
        search={false}
      />
      <div className="row">
        <div className={`col-sm-6`}>
          <ImagePOD
            height={"170px"}
            accept=".csv"
            hiddenFileInputRef={hiddenFileInput}
            onClick={() => {
              hiddenFileInput.current.click();
            }}
            onChange={onChangeFile}
            label="Upload File"
          />
        </div>
        <div className={`col-sm-6 ${styles.fileinfo}`}>
          <p>Uploaded File: {csvFile?.name}</p>
          <p>File Size: {FileSizeHelper.formatBytes(csvFile?.size)}</p>
          <p>Number of Rows: {rowCount}</p>

          <Button
            padding="10px 30px"
            btnText="Submit"
            margin="20px 0px"
            disabled={!csvFile}
            backgroundColor={csvFile ? "#000" : "#b5b5b5"}
            onClick={processCSV}
          />

          <Button
            padding="10px 30px"
            btnText="Clear"
            margin="20px 10px"
            disabled={!csvFile}
            backgroundColor={csvFile ? "#000" : "#b5b5b5"}
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      </div>
      {error ? <div className={styles.error}>{`${error}`}</div> : null}

      {batchData?.length ? (
        <>
          <BatchTableNew
            onUpload={onUpload}
            data={batchData}
            disable={disable}
            setDisable={setDisable}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              btnText="Upload"
              margin="20px 0px"
              disabled={disable}
              backgroundColor={disable ? "#b5b5b5" : "#000"}
              onClick={onUpload}
            />
          </div>
        </>
      ) : null}
    </Block>
  );
}

export default JobBatchUpload;
