import React, { useState } from "react";
import styles from "./Signup.module.css";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Button, DropdownForm, InputForm } from "../../components";
import { Logo } from "../../assets/svg";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/delivery-guy.json";
import { AuthService } from "../../services";
import { BsEye, BsEyeSlash } from "react-icons/bs";

// Context
import { AuthContext } from "../../context";
import { useNavigate } from "react-router-dom";

function Signup() {
  const navigate = useNavigate();

  const { login } = AuthContext.useAuth();

  const [loading, setLoading] = useState();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState({
    isVisible: false,
    message: "",
    toastType: "",
  });
  const [userData, setUserData] = useState({
    email: "",
    company_name: "",
    company_size: "Company Size",
    password: "",
    confirm_password: "",
  });

  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };

  const validateForm = () => {
    const { email, company_name, company_size, password, confirm_password } =
      userData;

    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;

    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!company_name) {
      newErrors.company_name = "Company Name is required";
    } else if (company_size === "Company Size") {
      newErrors.company_size = "Company Size is required";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    } else if (password !== confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
    } else if (!strongPasswordRegex.test(password)) {
      newErrors.password =
        "Password must contain at least 1 uppercase, at least 1 digit and 1 special character characters";
    }

    return newErrors;
  };

  const onLogin = (event) => {
    event.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setLoading(true);
      AuthService.signup(userData)
        .then((json) => {
          if (json?.errors?.length > 0) {
            setShow({
              isVisible: true,
              toastType: "danger",
              message: json?.errors[0]?.message,
            });
          } else {
            login(json);
          }
        })
        .catch((err) => console.log({ err }))
        .finally(() => setLoading(false));
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice",
      trasnparent: true,
    },
  };

  return (
    <div className={`container-fluid ${styles.container}`}>
      <div className="row">
        <div className={`col-sm-6 ${styles.leftContainer}`}>
          <div className={styles.headingContainer}>
            <Logo className={styles.logo} />
            <div className={styles.back}>Register your account</div>
            <span className={styles.heading}>
              Join the elite club of companies using trackbuddy
            </span>
          </div>

          <Form onSubmit={onLogin}>
            <InputForm
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Email *"
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange(setUserData)}
              lastIcon="@"
              inValidMessage={errors.email}
            />
            <InputForm
              type={"text"}
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Company Name *"
              name="company_name"
              value={userData.company_name}
              onChange={handleChange(setUserData)}
              inValidMessage={errors.company_name}
            />
            <DropdownForm
              type={"text"}
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Company Size"
              name="company_size"
              value={userData.company_size}
              onChange={handleChange(setUserData)}
              options={["0-5", "10-15", "11-50"]}
              inValidMessage={errors.company_size}
            />
            <InputForm
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Password *"
              type={viewPassword ? "text" : "password"}
              name="password"
              value={userData.password}
              onChange={handleChange(setUserData)}
              lastIcon={viewPassword ? <BsEye /> : <BsEyeSlash />}
              onClickLastIcon={() => setViewPassword(!viewPassword)}
              inValidMessage={errors.password}
            />
            <InputForm
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Confirm Password *"
              type={viewConfirmPassword ? "text" : "password"}
              name="confirm_password"
              value={userData.confirm_password}
              onChange={handleChange(setUserData)}
              lastIcon={viewConfirmPassword ? <BsEye /> : <BsEyeSlash />}
              onClickLastIcon={() =>
                setViewConfirmPassword(!viewConfirmPassword)
              }
              inValidMessage={errors.confirm_password}
            />

            <div className={styles.btnWrapper}>
              <Button loading={loading} type="submit" btnText="Register" />
            </div>
          </Form>

          <div className={styles.forgot} onClick={() => navigate("/signin")}>
            Already have an account?
            <span style={{ textDecoration: "underline", marginLeft: 5 }}>
              Login
            </span>
          </div>
        </div>
        <div className={`col-sm-6 ${styles.rightContainer}`}>
          <div className={styles.leftHeadingContainer}>
            <div className={styles.back}>Lets start real-time tracking!</div>
          </div>

          <div
            style={{
              height: isMobile ? "50vh" : "100vh",
              width: "90%",
            }}
          >
            <Lottie
              options={defaultOptions}
              height={isMobile ? "50vh" : "100vh"}
              width={"100%"}
            />
          </div>
        </div>
      </div>

      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg={show?.toastType}
          onClose={() => setShow({ isVisible: false })}
          show={show.isVisible}
          delay={3000}
          autohide
        >
          <Toast.Body>{show?.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Signup;
