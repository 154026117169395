import React from "react";
import styles from "./ImagePOD.module.css";
import { BsUpload } from "react-icons/bs";

function ImagePOD({
  accept,
  img,
  onClick,
  onChange,
  hiddenFileInputRef,
  label,
  width,
  height,
}) {
  return (
    <div className={styles.card} style={{ width, height }} onClick={onClick}>
      <input
        ref={hiddenFileInputRef}
        type="file"
        accept={accept || "image/*"}
        onChange={onChange}
      />
      {img ? (
        <img
          className={styles.preview}
          src={URL.createObjectURL(img)}
          alt="pod"
        />
      ) : (
        <div className={styles.text}>
          <BsUpload size={50} />
          <p>{label || "Attach proof of delivery"}</p>
        </div>
      )}
    </div>
  );
}

export default ImagePOD;
