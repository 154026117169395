import React from "react";
import styles from "./MenuItem.module.css";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

function MenuItem({ active, path, icon, label, isOpen }) {
  const Icon = icon;
  let className;
  if (isOpen && active) {
    className = styles.iconContainerOpenActive;
  } else if (isOpen) {
    className = styles.iconContainerOpen;
  } else if (!isOpen && active) {
    className = styles.iconContainerActive;
  } else {
    className = styles.iconContainer;
  }
  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <div className={className}>
        <Icon
          fill={active ? "#FFF" : null}
          stroke={active ? "##F8CB5E" : null}
        />
        {isOpen ? (
          <CSSTransition
            in={isOpen}
            timeout={500}
            classNames={"fade"}
            unmountOnExit
          >
            <span className={`${active ? styles.labelActive : styles.label}`}>
              {label}
            </span>
          </CSSTransition>
        ) : null}
      </div>
    </Link>
  );
}

export default MenuItem;
