import React, { useState } from "react";
import styles from "./AutoCompleteCustomer.module.css";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { IoIosPerson } from "react-icons/io";

const Autocomplete = ({
  label,
  width,
  selectedValue,
  onSelect,
  options,
  inValidMessage,
  icon,
  iconSize,
  iconColor,
  lastIcon,
  onClickLastIcon,
}) => {
  const [selected, setSelected] = useState(selectedValue || null);
  const [filteredOptions, setFilteredOptions] = useState();
  const [focus, setFocus] = useState();

  const handleInputChange = (text) => {
    const filteredOptions = options?.filter((option) =>
      option?.full_name?.toLowerCase()?.includes(text.toLowerCase())
    );

    setFilteredOptions(filteredOptions);

    if (text && !options?.some((option) => option?.full_name === text)) {
      setSelected([{ full_name: text }]);
      onSelect({ full_name: text, email: "", mobile_no: "", new: true });
    } else {
      setSelected([]);
    }
  };

  const handleSelection = (selectedOptions) => {
    setSelected(selectedOptions);
    if (selectedOptions?.length) {
      onSelect({ ...selectedOptions[0], new: false });
    }
  };

  return (
    <Form.Group
      className="mb-3"
      style={{ width }}
      controlId="exampleForm.ControlInput1"
    >
      <Form.Label className={styles.label}>{label}</Form.Label>

      <InputGroup>
        {icon && (
          <InputGroup.Text className={focus ? styles.iconFocus : styles.icon}>
            {icon}
          </InputGroup.Text>
        )}

        <Typeahead
          id="rendering-example"
          className={styles.input}
          options={filteredOptions || options}
          allowNew
          selected={selected}
          onChange={handleSelection}
          onInputChange={handleInputChange}
          newSelectionPrefix="Add new contact: "
          labelKey="full_name"
          isInvalid={inValidMessage ? true : false}
          filterBy={(option) => option?.full_name}
          renderMenuItemChildren={(option, { text }) => (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <div className={styles.carImgWrapper}>
                  <IoIosPerson size={30} color="#000" />
                </div>
                <div>
                  <div className={styles.name} search={text}>
                    {option?.full_name}
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div className={styles.id}>
                      <b>Mobile:</b> {option?.mobile_no}
                    </div>
                    &ensp;
                    <div className={styles.id}>
                      <b>Email:</b> {option?.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        />

        {lastIcon && (
          <InputGroup.Text
            style={{ color: iconColor }}
            className={focus ? styles.iconFocus : styles.icon}
            onClick={onClickLastIcon}
          >
            {lastIcon}
          </InputGroup.Text>
        )}

        {inValidMessage ? (
          <Form.Control.Feedback type="invalid">
            {inValidMessage}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Field is required
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default Autocomplete;
