import React from "react";
import styles from "./AutoConplete.module.css";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { API } from "../../utils/api";
import Van from "../../assets/img/van.png";
import Suv from "../../assets/img/suv.png";
import Truck from "../../assets/img/truck.png";
import Bike from "../../assets/img/bike.png";
import defaultVehicle from "../../assets/img/default-vehicle.png";
import { DateTimeHelper } from "../../helper";
import { BsFillCircleFill } from "react-icons/bs";

const Autocomplete = ({ options, label, width, onSelect }) => {
  return (
    <Form.Group
      className="mb-3"
      style={{ width }}
      controlId="exampleForm.ControlInput1"
    >
      <Form.Label className={styles.label}>{label}</Form.Label>
      <Typeahead
        id="rendering-example"
        labelKey="name"
        options={options}
        className={styles.input}
        onChange={(e) => onSelect(e)}
        renderMenuItemChildren={(option, { text }) => (
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <div className={styles.carImgWrapper}>
                {option?.vehicle_img ? (
                  <img
                    src={`${API.assets}/${option?.vehicle_img}`}
                    alt={option?.name}
                    className={styles.carImg}
                  />
                ) : (
                  <img
                    src={defaultVehicle}
                    alt={option?.name}
                    className={styles.defaultCarImg}
                  />
                )}
              </div>
              <div>
                <div className={styles.name} search={text}>
                  {option?.name}{" "}
                  <span className={styles.status}>
                    <BsFillCircleFill
                      size={8}
                      color={
                        DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                          option?.last_ping
                        ) < 1
                          ? "#64C937"
                          : "black"
                      }
                      style={{ marginRight: 2 }}
                    />
                    {DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                      option?.last_ping
                    ) < 1
                      ? "Online"
                      : "Offline"}
                  </span>
                </div>
                <span className={styles.id}>{option?.id}</span>
              </div>
            </div>
            <div>
              <img
                src={
                  option?.type === "suv"
                    ? Suv
                    : option?.type === "truck"
                    ? Truck
                    : option?.type === "van"
                    ? Van
                    : Bike
                }
                alt={option?.type}
                className={styles.typeImg}
              />
              <div>{option?.type}</div>
            </div>
          </div>
        )}
      />
    </Form.Group>
  );
};
export default Autocomplete;
