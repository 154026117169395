import React from "react";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import styles from "./Block.module.css";
import { SidebarContext } from "../../context";

function Block({
  children,
  style,
  onClick,
  onSubmit,
  validated,
  onCloseError,
  errorVisible,
  errorMessage,
  withForm,
}) {
  const { isSidebarOpen, toggleSidebar } = SidebarContext.useSidebar();
  return (
    <div
      className={`container-fluid ${styles.container} ${
        isSidebarOpen ? styles.sidebarOpen : styles.sidebarClose
      }`}
      style={style}
    >
      {withForm ? (
        <Form
          noValidate
          validated={validated}
          onClick={onClick}
          onSubmit={(event) => {
            typeof onSubmit === "function" && event.preventDefault();
            onSubmit(event);
          }}
        >
          {children}

          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="danger"
              onClose={onCloseError}
              show={errorVisible || false}
              delay={3000}
              autohide
            >
              <Toast.Body>{errorMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        </Form>
      ) : (
        <div>
          {children}

          <ToastContainer position="bottom-start" className="p-3">
            <Toast
              bg="danger"
              onClose={onCloseError}
              show={errorVisible || false}
              delay={3000}
              autohide
            >
              <Toast.Body>{errorMessage}</Toast.Body>
            </Toast>
          </ToastContainer>
        </div>
      )}
    </div>
  );
}

export default Block;
