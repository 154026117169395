import { RolesHelper } from "../constants";
import { roles } from "../constants/roles";
import { API } from "../utils/api";
import jwtDecode from "jwt-decode";
import { trialRemainingDays } from "./subscription-service";

export const signup = async ({
  company_name,
  company_size,
  email,
  password,
}) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: company_name,
        company_size,
        email,
        password,
        role: roles.tenantAdmin,
      }),
    };

    const response = await fetch(API.users, obj);
    let json;

    if (response.ok) {
      json = await login({ email, password });
    } else {
      json = await response.json();
    }
    console.log(json, "signupJsaon");
    return json;
  } catch (e) {
    return e;
  }
};

export const login = async ({ email, password }) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    };

    const response = await fetch(API.login, obj);
    const json = await response.json();

    return json;
  } catch (e) {
    return e;
  }
};

export const logout = async ({ refresh_token }) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token,
      }),
    };

    const response = await fetch(API.logout, obj);

    return response;
  } catch (e) {
    return e;
  }
};

let cachedTenantId = null;
let tenantIdPromise = null;

const getAndCacheTenantId = async (id, role) => {
  const tenant_id = await getTenantByUid(id, role);
  cachedTenantId = tenant_id;
  return tenant_id;
};

export const getToken = async () => {
  const userString = await localStorage.getItem("user");
  if (userString === "{}") {
    localStorage.removeItem("user");
    window.location.reload();
  } else if (userString) {
    const user = JSON.parse(userString);
    const { exp, id, role } = await jwtDecode(user?.data?.access_token);
    // Check if tenant_id is already cached
    if (cachedTenantId !== null) {
      return { token: user?.data?.access_token, tenant_id: cachedTenantId };
    }
    // Check if tenantIdPromise is null or resolved
    if (!tenantIdPromise) {
      tenantIdPromise = getAndCacheTenantId(id, role);
    }
    const tenant_id = await tenantIdPromise;
    // if token expired refreshing token
    if (parseFloat(new Date() / 1000) > exp) {
      console.log("tokenExpired");
      try {
        const newToken = await refreshToken(user?.data?.refresh_token);
        localStorage.setItem("user", JSON.stringify(newToken));
        return { token: newToken?.data?.access_token, tenant_id };
      } catch (err) {
        localStorage.removeItem("user");
        window.location.reload();
      }
    } else {
      console.log("tokenNotExpired");
      return { token: user?.data?.access_token, tenant_id };
    }
  } else {
    localStorage.removeItem("user");
    window.location.reload();
  }
};

const refreshToken = async (refresh_token) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mode: "json",
        refresh_token,
      }),
    };
    const response = await fetch(API.refresh, obj);
    const json = await response.json();
    if (json?.errors?.length > 0) {
      throw new Error("Error refreshing token");
    } else return json;
  } catch (e) {
    throw e;
  }
};

export const getTenantByUid = async (user_id, role) => {
  const obj = {
    method: "GET",
  };

  // if role is app user role
  if (role === RolesHelper.roles.tenantAdmin) {
    try {
      const response = await fetch(
        `${API.tenants}?filter[user_id]=${user_id}`,
        obj
      );
      const json = await response.json();

      if (!response?.ok) throw new Error("Something went wrong");

      return json?.data?.[0]?.id;
    } catch (err) {
      throw err;
    }
  } else {
    try {
      const response = await fetch(`${API.users}/${user_id}`, obj);
      const json = await response.json();

      if (!response?.ok) throw new Error("Something went wrong");

      return json?.data?.tenant_id;
    } catch (err) {
      throw err;
    }
  }
};

export const resetPasswordRequest = async (email) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        reset_url: `${window?.location?.origin}/reset-password`,
      }),
    };

    const response = await fetch(API.resetPasswordRequest, obj);
    const json = await response.json();

    return json;
  } catch (e) {
    return e;
  }
};

export const resetPasswordUpdate = async ({ token, password }) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        password,
      }),
    };

    const response = await fetch(API.resetPassword, obj);
    const json = await response.json();

    return json;
  } catch (e) {
    return e;
  }
};

export const acceptUserInvite = async ({ token, password }) => {
  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
        password,
      }),
    };

    const response = await fetch(API.acceptInvite, obj);
    const json = await response.json();

    return json;
  } catch (e) {
    return e;
  }
};
