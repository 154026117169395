import React, { useState, useEffect } from "react";
import styles from "./AddProductSidebar.module.css";
import { RxCross2 } from "react-icons/rx";
import InputForm from "../InputForm/InputForm";
import Button from "../Button/Button";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ImagePOD from "../ImagePOD/ImagePOD";

function AddProductSidebar({
  accept,
  onClickToAddImage,
  onChangeImage,
  hiddenFileInputRef,
  isOpen,
  onClose,
  onAddClick,
  product,
  onChange,
  errors,
  mode,
  img,
}) {
  console.log(mode, "mode");

  return (
    <div
      className={`${styles.sidebarContainer} ${
        isOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <RxCross2
        size={22}
        className={styles.cross}
        onClick={() => {
          onClose();
        }}
      />
      {/* <Modal.Body className={styles.modalBody}> */}
      <p className={styles.mainHead}>{mode ? "Edit product" : "Add Product"}</p>

      <InputForm
        width="80%"
        label="Title *"
        name="title"
        value={product?.title}
        onChange={onChange}
        inValidMessage={errors?.title}
      />
      <InputForm
        width="80%"
        label="SKU ID *"
        name="sku_id"
        value={product?.sku_id}
        onChange={onChange}
        inValidMessage={errors?.sku_id}
      />
      <InputForm
        width="80%"
        label="Weight *"
        name="weight"
        value={product?.weight}
        onChange={onChange}
        inValidMessage={errors?.weight}
        disabled={false}
      />
      <InputForm
        width="80%"
        label="Dimensions *"
        name="dimensions"
        value={product?.dimensions}
        onChange={onChange}
        inValidMessage={errors?.dimensions}
      />
      <InputForm
        width="80%"
        label="Price *"
        name="price"
        value={product?.price}
        onChange={onChange}
        inValidMessage={errors?.price}
      />
      <InputForm
        width="80%"
        label="Quantity *"
        name="quantity"
        value={product?.quantity}
        onChange={onChange}
        inValidMessage={errors?.quantity}
        disabled={false}
      />
      <div className={styles.btn}>
        <ImagePOD
          label={"Upload product Image"}
          width={"80%"}
          height={170}
          onClick={onClickToAddImage}
          accept={accept || "image/*"}
          onChange={onChangeImage}
          hiddenFileInputRef={hiddenFileInputRef}
          img={img}
        />
      </div>

      <div className={styles.btn}>
        <Button
          width="30%"
          btnText={mode ? "Save" : "Add"}
          onClick={onAddClick}
        />
      </div>

      {/* </Modal.Body> */}
    </div>
  );
}

export default AddProductSidebar;
