import React from "react";
import styles from "./OrderDetails.module.css";
import { Block, DashboardRecentActivities, Header } from "../../components";
import HeaderIcon from "../../assets/svg/ordersActive.svg";
import { DashboardDummy } from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";

function OrderDetails() {
  const navigate = useNavigate();
  const [data] = useSearchParams() || {};

  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header
        icon={HeaderIcon}
        title={data?.get("orderNo")}
        breadcrumb="Home > Orders"
        search={false}
        btnText="Assign"
        btnText2="Add pickup time"
        btnText3="Edit Pickup location"
        onAddClick={() => navigate("/orders/assign-driver")}
      />

      <div className={`row  ${styles.block}`}>
        <div className="col-sm-4">
          <div className={`row ${styles.orderDetailsHeader}`}>
            <div className="col-sm-6">
              <div className={styles.label}>Order no#</div>
              <div className={styles.field}>{data?.get("orderNo")}</div>
            </div>
            <div className="col-sm-6">
              <div className={styles.label}>Date</div>
              <div className={styles.field}>{data?.get("date")}</div>
            </div>
          </div>

          <div className={styles.locationArea}>
            <div className={styles.label}>Pickup</div>
            <div className={styles.locationRow}>
              <div className={styles.circle} />
              <span className={styles.field}>{data.get("pickup")}</span>
            </div>
            <div className={styles.progress} />
            <div className={styles.label}>Drop off</div>
            <div className={styles.locationRow}>
              <div className={styles.circle} />
              <span className={styles.field}>{data.get("dropOff")}</span>
            </div>
          </div>

          <div className={`row ${styles.orderDetailsHeader}`}>
            <div className="col-sm-6">
              <div className={styles.label}>Assigned to</div>
              <div className={styles.field}>…</div>
            </div>
            <div className="col-sm-6">
              <div className={styles.label}>Pickup time & Date</div>
              <div className={styles.field}>…</div>
            </div>
          </div>

          <div className={styles.label}>Description</div>
          <div className={styles.desc}>
            Cras gravida bibendum dolor eu varius. Morbi fermentum velit nisl,
            eget vehicula lorem sodales eget. Donec quis volutpat orci. Sed
            ipsum felis, tristique id egestas et, convallis ac velit. In
            consequat dolor libero, nec luctus orci rutrum nec. Phasellus vel
            arcu sed nibh ornare accumsan. Vestibulum in elementum erat.
            Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean
            laoreet rhoncus ipsum eget tempus. Praesent convallis fermentum
            sagittis.
          </div>
        </div>
        <div className="col-sm-5">
          <div class="mapouter" style={{ height: "100%" }}>
            <iframe
              width="100%"
              height="100%"
              title="map"
              src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
        <div className="col-sm-3">
          <div className={styles.recentActivities}>
            <span className={styles.history}>Order History</span>
            {DashboardDummy?.recentActivities?.map((item, index) => (
              <DashboardRecentActivities
                key={index}
                time={item?.time}
                text={item?.desc}
              />
            ))}
          </div>
        </div>
      </div>
    </Block>
  );
}

export default OrderDetails;
