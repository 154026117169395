export const baseUrl = "https://api.trackbuddy.io/";
export const websocketBaseUrl = "wss://api.trackbuddy.io/realtime";
const sortnLimit = "sort=-date_created&limit=20";

export const API = {
  sortnLimit: "sort=-date_created&limit=20&sort=-date_created",
  login: `${baseUrl}auth/login`,
  logout: `${baseUrl}auth/logout`,
  refresh: `${baseUrl}auth/refresh`,
  randomString: `${baseUrl}utils/random/string`,
  resetPasswordRequest: `${baseUrl}auth/password/request`,
  resetPassword: `${baseUrl}auth/password/reset`,
  registration: `${baseUrl}items/registrations`,
  assets: `${baseUrl}assets`,
  files: `${baseUrl}files`,
  users: `${baseUrl}users`,
  tenants: `${baseUrl}items/tenants`,
  current_user: `${baseUrl}users/me`,
  vehicle: `${baseUrl}items/vehicles`,
  drivers: `${baseUrl}items/drivers?${sortnLimit}`,
  products: `${baseUrl}items/products`,
  delivery_tasks: `${baseUrl}items/delivery_tasks`,
  vehicles_status_tab: `${baseUrl}vehicles/status`,
  delivery_status: `${baseUrl}delivery/status`,
  batch_upload: `${baseUrl}items/batch_upload`,
  contacts: `${baseUrl}items/contacts`,
  contacts_status_tab: `${baseUrl}contacts/type`,
  delivery_items: `${baseUrl}delivery_items?${sortnLimit}`,
  settings: `${baseUrl}items/settings`,
  app_settings: `${baseUrl}items/app_settings`,
  app_usage: `${baseUrl}items/app_usage`,
  tenant_api_keys: `${baseUrl}items/tenant_api_keys`,
  inviteUser: `${baseUrl}users/invite`,
  acceptInvite: `${baseUrl}users/invite/accept`,
  importData: `${baseUrl}/utils/import`,
};
