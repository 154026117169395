import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Button, Checkbox, InputForm } from "../../components";
import { Logo } from "../../assets/svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { AuthService } from "../../services";

// Context
import { AuthContext } from "../../context";
import { useNavigate } from "react-router-dom";

function Login() {
  const { login } = AuthContext.useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [show, setShow] = useState({
    isVisible: false,
    message: "",
  });
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [viewPassword, setViewPassword] = useState(false);

  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    // Retrieve the value of "remember me" from local storage
    const rememberMeValue = localStorage.getItem("rememberMe");
    console.log(rememberMeValue);
    const remeber = JSON.parse(rememberMeValue);
    console.log(remeber);
    // Update the state with the retrieved value
    if (remeber) {
      setRememberMe(true);
      setUserData(remeber);
    }
  }, []);

  const validateForm = () => {
    const { email, password } = userData;

    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    }

    return newErrors;
  };

  const onLogin = (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setLoading(true);

      if (rememberMe) {
        localStorage.setItem("rememberMe", JSON.stringify(userData));
      }

      AuthService.login(userData)
        .then((json) => {
          if (json?.errors?.length > 0) {
            setShow({ isVisible: true, message: json?.errors[0]?.message });
          } else {
            login(json);
          }
        })
        .catch((err) => console.log({ err }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className={`container-fluid ${styles.container}`}>
      <div className="row">
        <Form className={`col-sm-6 ${styles.leftContainer}`} onSubmit={onLogin}>
          <div className={styles.headingContainer}>
            <Logo className={styles.logo} />
            <div className={styles.back}>Welcome back!</div>
            <span className={styles.heading}>Login to your accounts</span>
          </div>

          <InputForm
            required={true}
            width={isMobile ? "100%" : "80%"}
            label="Email *"
            type="email"
            name="email"
            value={userData.email}
            onChange={handleChange(setUserData)}
            lastIcon="@"
            inValidMessage={errors.email}
          />
          <InputForm
            required={true}
            width={isMobile ? "100%" : "80%"}
            label="Password *"
            type={viewPassword ? "text" : "password"}
            name="password"
            value={userData.password}
            onChange={handleChange(setUserData)}
            lastIcon={viewPassword ? <BsEye /> : <BsEyeSlash />}
            onClickLastIcon={() => setViewPassword(!viewPassword)}
            inValidMessage={errors.password}
          />
          <div className={styles.forgotRow}>
            <Checkbox
              checked={rememberMe}
              onChange={handleRememberMeChange}
              label="Remember me"
            />
            <span
              onClick={() => navigate("/forgot-password")}
              className={styles.forgot}
            >
              Forgot password?
            </span>
          </div>

          <div className={`row ${styles.mobileBtns}`}>
            <div className={`col-sm-12 ${styles.leftContainer}`}>
              <Button type="submit" btnText="Login" onClick={onLogin} />
              <Button
                margin="10px 0px 0px"
                btnText="Get Started For Free"
                backgroundColor="#ffc53a"
                color="black"
                onClick={() => navigate("/signup")}
              />
            </div>
          </div>
        </Form>
        <div className={`col-sm-6 ${styles.rightContainer}`}>
          <div className={styles.leftHeadingContainer}>
            <div className={styles.back}>Not a member yet? Register today</div>
            <div className={styles.signupHeading}>
              Join thousands of companies using TrackBuddy to streamline their
              deliveries. With trackbuddy you can track your deliveries and
              vehicles in real-time with our super easy to use admin.
            </div>
            <p className={styles.signupHeading}>
              Learn more here about our subscription plans to choose the plan
              best suited for your needs.
            </p>
            <p className={styles.freeHeading}>
              It’s free to get started, no credit card is needed.
            </p>
          </div>
        </div>
      </div>

      <div className={`row ${styles.webBtns}`}>
        <div className={`col-sm-6 ${styles.leftContainer}`}>
          <Button
            loading={loading}
            type="submit"
            btnText="Login"
            onClick={onLogin}
          />
        </div>
        <div className={`col-sm-6`}>
          <Button
            margin="0px 0px 0px "
            btnText="Get Started For Free"
            backgroundColor="#ffc53a"
            color="black"
            onClick={() => navigate("/signup")}
          />
        </div>
      </div>

      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg="danger"
          onClose={() => setShow({ isVisible: false })}
          show={show.isVisible}
          delay={3000}
          autohide
        >
          <Toast.Body>{show?.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Login;
