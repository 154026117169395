import React from "react";
import styles from "./TasksTable.module.css";
import { Table } from "react-bootstrap";
import Checkbox from "../Checkbox/Checkbox";
import DeliveryStatus from "../DeliveryStatus/DeliveryStatus";

function TasksTable({
  data,
  onClickRow,
  check,
  onCheck,
  checked,
  onDispatch,
  onClickEdit,
  onClickDelete,
}) {
  console.log(data, "deliveryData");
  return (
    <Table responsive>
      <thead>
        <tr>
          {/* <th className={styles.th}>Select</th> */}
          <th className={styles.th}>Job ID</th>
          <th className={styles.th}>Status</th>
          <th className={styles.th}>Assigned To</th>
          <th className={styles.th}>Pickup</th>
          <th className={styles.th}>Drop off</th>
          <th className={styles.th}>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            {/* <td>
              <Checkbox
                checked={checked}
                name={item.id}
                value={check}
                onChange={(e) => onCheck(e?.target?.checked, item)}
              />
            </td> */}
            <td className={styles.id} onClick={() => onClickRow(item)}>
              {item.id}
              {/* {item.id && item.id.length > 15
                ? `${item.id.slice(0, 15)}...`
                : item.id} */}
            </td>
            <td onClick={() => onClickRow(item)} className={styles.statusLabel}>
              <DeliveryStatus status={item.status}></DeliveryStatus>
            </td>
            <td className={styles.id} onClick={() => onClickRow(item)}>
              {/* {item.vehicle_id} */}
              {item.vehicle_id && item.vehicle_id.length > 15
                ? `${item.vehicle_id.slice(0, 15)}...`
                : item.vehicle_id}
            </td>
            <td onClick={() => onClickRow(item)}>
              {item.pickup_address && item.pickup_address.length > 30
                ? `${item.pickup_address.slice(0, 30)}...`
                : item.pickup_address}
            </td>
            <td onClick={() => onClickRow(item)}>
              {item.dropoff_address && item.dropoff_address.length > 30
                ? `${item.dropoff_address.slice(0, 30)}...`
                : item.dropoff_address}
            </td>
            <td>
              {item.status === "Pending" ? (
                <div className={styles.action}>
                  <span className={styles.id} onClick={() => onDispatch(item)}>
                    Assign
                  </span>

                  <span className={styles.separator}>|</span>

                  <span className={styles.id} onClick={() => onClickEdit(item)}>
                    Edit
                  </span>

                  <span className={styles.separator}>|</span>

                  <span
                    className={styles.id}
                    onClick={() => onClickDelete(item)}
                  >
                    Delete
                  </span>
                </div>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default TasksTable;
