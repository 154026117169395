import React from "react";
import styles from "./ProductCard.module.css";
import { ProductImg } from "../../assets/svg";
import { IoTrash } from "react-icons/io5";

const ProductCard = ({ data, onRemove, onDelete, onClickCard }) => {
  console.log(data, "routeProducts");
  return (
    <>
      {data
        ?.filter((item) => item)
        .map((item, index) => {
          console.log(item?.title, "routeItems");
          return (
            <div className={styles.container} key={index}>
              <div className={styles.trashProdut}>
                <IoTrash
                  size={20}
                  onClick={() => {
                    onDelete && onDelete(item?.id);
                    onRemove && onRemove(index);
                  }}
                />
              </div>
              <div
                className={`d-flex ${styles.cardContainer}`}
                onClick={() => onClickCard(index)}
              >
                <div className={` ${styles.image}`}>
                  {item?.productImage ? (
                    <img
                      className={` ${styles.image}`}
                      src={URL.createObjectURL(item?.productImage)}
                      alt="product"
                    />
                  ) : (
                    <img
                      src={ProductImg}
                      alt="product"
                      className={styles.tick}
                    />
                  )}
                </div>
                <div className={`col ${styles.center}`}>
                  <span className={styles.productHead}>{item?.title}</span>
                  <div>
                    <span className={styles.productSpecs}>SKU ID : </span>
                    <span className={styles.productValue}>{item?.sku_id}</span>
                  </div>
                  <div>
                    <span className={styles.productSpecs}>Weight :</span>
                    <span className={styles.productValue}>{item?.weight}</span>
                  </div>
                  <div>
                    <span className={styles.productSpecs}>Price : </span>
                    <span className={styles.productValue}>{item?.price}$</span>
                  </div>
                </div>
                <div className={` col ${styles.right}`}>
                  <span></span>
                  <div>
                    <span className={styles.productSpecs}>Quantity :</span>
                    <span className={styles.productValue}>
                      {item?.quantity}
                    </span>
                  </div>
                  <div>
                    <span className={styles.productSpecs}>Dimensions :</span>
                    <span className={styles.productValue}>
                      {" "}
                      {item?.dimensions}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ProductCard;
