import React from "react";
// import styles from "./Products.module.css";
import { Block, Header } from "../../components";
import { Product } from "../../assets/svg";

function Products() {
  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header icon={Product} title="Products" search={false} />
    </Block>
  );
}

export default Products;
