import React from "react";
import styles from "./Pagination.module.css";

function Pagination({ page, totalPages, onPrev, onNext }) {
  return (
    <div className={styles.nextPrev}>
      <span
        className={
          page > 1 ? styles?.enabledPagination : styles.disabledPagination
        }
        onClick={onPrev}
      >
        Previous
      </span>{" "}
      <span
        className={
          page < totalPages
            ? styles?.enabledPagination
            : styles.disabledPagination
        }
        onClick={onNext}
      >
        | Next
      </span>
    </div>
  );
}

export default Pagination;
