import React, { useEffect, useState, useCallback } from "react";
import styles from "./OrderTracking.module.css";
import { Logo } from "../../assets/svg";
import { useParams } from "react-router-dom";
import { TasksService, VehiclesService } from "../../services";
import PinMarker from "../../assets/img/pin-marker.png";

import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
// import VehcileMapCard from "../VehcileMapCard/VehcileMapCard";
import { MapStyles } from "../../constants";
import MapCar from "../../assets/img/mapCar.png";
import { formatTimeNew } from "../../helper/dateTime";
import { formatTime } from "../../constants/helper";

const OrderTracking = () => {
  const { jobId } = useParams();
  const [vehicleData, setvehicleData] = useState(null);
  const [deliveryData, setDeliveryData] = useState(null);
  const [currentPosition, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [vehicleLat, setVehicleLat] = useState(null);
  const [vehicleLng, setVehicleLng] = useState(null);
  const [directions, setDirections] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ["geometry", "places"],
  });
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    fetchData();
  }, [jobId]);

  const fetchData = async () => {
    if (jobId) {
      console.log(jobId, "jobId");
      try {
        const data = await TasksService.getTasksForTracking(jobId);
        const vehicleId = data?.data?.[0]?.vehicle_id || "";
        setDeliveryData(data?.data?.[0]);
        console.log(data?.data, "deliveryData");

        if (vehicleId) {
          const vehicleData = await VehiclesService.getVehiclesForTracking(
            vehicleId
          );
          console.log(vehicleData?.data?.[0], "vehicleData");
          setvehicleData(vehicleData?.data?.[0]);
        } else {
          console.error("No vehicleId found in the response.");
        }
      } catch (err) {
        console.error(err, "error fetching data");
      }
    }
  };

  // function to find center of the selected vehicle
  useEffect(() => {
    const vehicleLocation = vehicleData?.location
      ? JSON.parse(vehicleData?.location)
      : {};
    {
      vehicleLocation &&
        setVehicleLat(Number(vehicleLocation?.coords?.latitude));
      setVehicleLng(Number(vehicleLocation?.coords?.longitude));
    }
    const centerLat =
      vehicleLat + Number(deliveryData?.dropoff_address_lat) / 2;
    const centerLng =
      vehicleLng + Number(deliveryData?.dropoff_address_lng) / 2;

    const isValidCenter = !isNaN(centerLat) && !isNaN(centerLng);

    const fallbackCenter = {
      lat: 24.9346328,
      lng: 67.0847751,
    };

    const mapCenter = isValidCenter
      ? { lat: centerLat, lng: centerLng }
      : fallbackCenter;

    setCurrentLocation(mapCenter);
  }, [vehicleData, isLoaded]);
  useEffect(() => {
    if (isLoaded) {
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(vehicleLat, vehicleLng),
          destination: new window.google.maps.LatLng(
            Number(deliveryData?.dropoff_address_lat),
            Number(deliveryData?.dropoff_address_lng)
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.log(`Error fetching directions: ${status}`);
          }
        }
      );
    }
  }, [isLoaded, vehicleLat, vehicleLng]);
  // Function to calculate bounds and set the auto-zoom
  const onLoad = useCallback(
    (map) => {
      // map.setOptions({
      //   styles: MapStyles.styles,
      // });

      //Type definitions for non-npm package Google Maps JavaScript API 3.50
      var bounds = new window.google.maps.LatLngBounds();

      const locations = [
        {
          lat: vehicleLat,
          lng: vehicleLng,
        },
        {
          lat: Number(deliveryData?.dropoff_address_lat),
          lng: Number(deliveryData?.dropoff_address_lng),
        },
      ];

      locations.forEach((location) => {
        if (isNaN(location?.lat) || isNaN(location?.lng)) return;

        bounds.extend({ lat: location.lat, lng: location.lng });
      });

      map.fitBounds(bounds); // <-------- resize based on markers, native
    },
    [isLoaded]
  );

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  return (
    <div className={`container-fluid ${styles.container}`}>
      <div className={` ${styles.flexContainer}`}>
        <Logo className={styles.logo} />
        <div>
          {" "}
          <span style={{ fontWeight: "bold" }}>Estimated Delivery Time : </span>
          {formatTime(deliveryData?.delivery_date)}{" "}
        </div>
        <div>
          <span style={{ fontWeight: "bold" }}>Delivery Job ID : </span>
          {deliveryData?.id}
        </div>
      </div>
      {isLoaded ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            disableDefaultUI={true}
            options={mapOptions}
            // zoom={14}
            onLoad={onLoad}
            center={currentPosition}
          >
            {/* marker for pickup address */}
            <MarkerF
              position={{
                lat: vehicleLat,
                lng: vehicleLng,
              }}
              icon={{
                url: MapCar,
                anchor: new window.google.maps.Point(50, 100),
                scaledSize: new window.google.maps.Size(100, 100),
              }}
            />

            {/* marker for dropoff address */}
            <MarkerF
              position={{
                lat: Number(deliveryData?.dropoff_address_lat),
                lng: Number(deliveryData?.dropoff_address_lng),
              }}
              icon={{
                url: PinMarker,
                anchor: new window.google.maps.Point(12, 25),
                scaledSize: new window.google.maps.Size(25, 25),
              }}
            />

            {/* direction in map between two points */}
            {directions && (
              <Polyline
                path={directions?.routes[0]?.overview_path?.map((point) => ({
                  lat: point.lat(),
                  lng: point.lng(),
                }))}
                options={{
                  strokeColor: "#000", // Red color
                  strokeOpacity: 2,
                  strokeWeight: 3,
                  fillOpacity: 1.85,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  radius: 30000,
                  zIndex: 100,
                }}
              />
            )}
          </GoogleMap>
        </div>
      ) : (
        <div>Loading Map...</div>
      )}
    </div>
  );
};

export default OrderTracking;
