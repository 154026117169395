import React, { useEffect, useRef, useState } from "react";
import styles from "./Header.module.css";
import Notification from "../../assets/svg/notification.svg";
import Button from "../Button/Button";
import { SidebarMobile } from "..";
import { Search } from "../../assets/svg";
import menu from "../../assets/img/menu-icon-mobile.png";
import MonkeyFace from "../../assets/img/monkey-face.png";
import { CSVLink } from "react-csv";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { MdOutlineViewCompact } from "react-icons/md";
import { FaRegMap } from "react-icons/fa";
import { GrFormRefresh } from "react-icons/gr";
import { Nav, NavDropdown } from "react-bootstrap";
import { SubscriptionService, TasksService } from "../../services";
import SampleFile from "../../assets/template.csv";

function Header({
  icon,
  title,
  searchMargin,
  btnText,
  btnText2,
  btnText3,
  btnText4,
  onAddClick,
  onAddClick2,
  onAddClick3,
  onAddClick4,
  search,
  searchValue,
  onChangeSearch,
  svg,
  exportBtn,
  exportData,
  exportAllData,
  exportFile,
  importBtn,
  onImport,
  actionBtn,
  onActionDelete,
  onActionEdit,
  onNotificationClick,
  viewStyles,
  viewStylesColor,
  onChangView,
  cards,
  lists,
  refreshText,
  onRefreshClick,
  disableBtn1,
  trial,
}) {
  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const [csvData, setCsvData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [trialRemaining, setTrialRemaining] = useState("");

  const Icon = icon;

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const filterColumns = () => {
    // Get column names
    const headers = [
      "delivery_job_id",
      "pickup_location",
      "pickup_latitude",
      "pickup_longitude",
      "dropoff_location",
      "dropoff_latitude",
      "dropoff_longitude",
      "pickup_datetime",
      "dropoff_datetime",
      "recipient_name",
      "recipient_phone_number",
      "recipient_email",
      "recipient_save_to_contact",
      "contact",
      "sender_name",
      "sender_phone_number",
      "sender_email",
      "sender_save_to_contact",
      "payment_status",
      "delivery_type",
      "customer_id",
    ];

    const contactHeaders = [
      "full_name",
      "mobile_no",
      "home_address",
      "office_address",
      "company",
    ];

    return exportFile === "Contacts" ? contactHeaders : headers;
  };

  const exportCsv = (data) => {
    const exportArr = data;

    const newExportData = exportArr?.map((item) => ({
      delivery_job_id: item?.id,
      pickup_location: item?.pickup_address,
      pickup_latitude: item?.pickup_address_lat,
      pickup_longitude: item?.pickup_address_lng,
      dropoff_location: item?.dropoff_address,
      dropoff_latitude: item?.dropoff_address_lat,
      dropoff_longitude: item?.dropoff_address_lng,
      pickup_datetime: item?.pickup_date,
      dropoff_datetime: item?.dropoff_time,
      recipient_name: item?.recipient_name,
      recipient_phone_number: item?.recipient_phone_number,
      recipient_email: item?.recipient_email,
      recipient_save_to_contact: item?.recipient_save_to_contact,
      sender_name: item?.sender_name,
      sender_phone_number: item?.sender_phone_number,
      sender_email: item?.sender_email,
      sender_save_to_contact: item?.sender_save_to_contact,
      payment_status: item?.payment_status,
      delivery_type: item?.delivery_type,
      customer_id: item?.customer_id,
    }));

    exportFile === "Contacts" ? setCsvData(data) : setCsvData(newExportData);

    setTimeout(() => {
      csvLink?.current?.link?.click();
    }, 200);
  };

  useEffect(() => {
    SubscriptionService.trialRemainingDays()
      .then((res) => {
        setTrialRemaining(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Nav>
        <div className={styles.container}>
          <div className={styles.titleRow}>
            {svg ? (
              <Icon
                fill={"#28262c"}
                stroke={null}
                className={styles.titleIcon}
              />
            ) : (
              <img src={icon} alt={title} className={styles.titleIcon} />
            )}
            <div className={styles.title}>{title}</div>

            {btnText ? (
              <div className={styles.headerBtn}>
                <div className={styles.separator} />
                <Button
                  backgroundColor={disableBtn1 ? "gray" : "#000"}
                  disabled={disableBtn1}
                  fontSize="13px"
                  btnText={btnText}
                  onClick={onAddClick}
                  padding="0px 25px"
                  height="25px"
                />
              </div>
            ) : null}

            {btnText2 ? (
              <div className={styles.headerBtn}>
                <Button
                  fontSize="13px"
                  btnText={btnText2}
                  onClick={onAddClick2}
                  margin="0px 20px 0px 20px"
                  backgroundColor="white"
                  color="black"
                  padding="0px 25px"
                  height="25px"
                />
              </div>
            ) : null}

            {btnText3 ? (
              <div className={styles.headerBtn}>
                <Button
                  fontSize="13px"
                  btnText={btnText3}
                  onClick={onAddClick3}
                  backgroundColor="white"
                  color="black"
                  padding="0px 25px"
                  height="25px"
                />
              </div>
            ) : null}

            {search === false ? null : (
              <div
                style={{ margin: searchMargin }}
                className={styles.searchContainer}
              >
                <input
                  placeholder="Search"
                  className={styles.searchInput}
                  value={searchValue}
                  onChange={onChangeSearch}
                />
                <img src={Search} alt="search" className={styles.searchIcon} />
              </div>
            )}
            {trial ? (
              <>
                {/* {svg ? (
                  <Icon
                    fill={"#28262c"}
                    stroke={null}
                    className={styles.titleIcon}
                  />
                ) : (
                  <img src={icon} alt={title} className={styles.titleIcon} />
                )} */}
                <div
                  className={styles.title}
                  style={{ color: "green", marginLeft: "auto" }}
                >
                  {`${trialRemaining} Days Left in Trial: Seize Now!`}
                </div>
              </>
            ) : null}

            {viewStyles && (
              <div className={styles.viewStyles}>
                {cards === false ? null : (
                  <MdOutlineViewCompact
                    size={20}
                    color={viewStylesColor === "cards" ? "#000000" : "#b7b6b6"}
                    onClick={() => onChangView("cards")}
                    className={styles.listIcon}
                  />
                )}
                {lists === false ? null : (
                  <AiOutlineUnorderedList
                    size={20}
                    color={viewStylesColor === "list" ? "#000000" : "#b7b6b6"}
                    onClick={() => onChangView("list")}
                    className={styles.listIcon}
                  />
                )}
                <FaRegMap
                  size={20}
                  color={viewStylesColor === "map" ? "#000000" : "#b7b6b6"}
                  onClick={() => onChangView("map")}
                  className={styles.listIcon}
                />
              </div>
            )}

            {importBtn ? (
              <NavDropdown
                title="Import"
                id="nav-dropdown"
                className={styles.importBtn}
              >
                <NavDropdown.Item onClick={onImport} eventKey="4.2">
                  Import File
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    TasksService.downloadTemplate(
                      SampleFile,
                      "delivery-job-upload-example-template"
                    )
                  }
                  eventKey="4.3"
                >
                  Download Template
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}

            {exportBtn ? (
              <NavDropdown
                title="Export"
                id="nav-dropdown"
                className={styles.importBtn}
              >
                <NavDropdown.Item
                  onClick={() => exportCsv(exportData)}
                  eventKey="4.2"
                  disabled={exportData?.length ? false : true}
                >
                  Export Selected
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => exportCsv(exportAllData)}
                  eventKey="4.3"
                >
                  Export All
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}

            {actionBtn ? (
              <NavDropdown
                title="Actions"
                id="nav-dropdown"
                className={styles.importBtn}
              >
                <NavDropdown.Item onClick={onActionEdit} eventKey="4.3">
                  Edit
                </NavDropdown.Item>
                <NavDropdown.Item onClick={onActionDelete} eventKey="4.3">
                  Delete
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}

            {(typeof onRefreshClick === "function" || refreshText) && (
              <div className={styles.rightWrapper} onClick={onRefreshClick}>
                <GrFormRefresh size={20} />
                <div className={styles.refreshText}>{refreshText}</div>
              </div>
            )}
          </div>

          {typeof onNotificationClick === "function" && (
            <div className={styles.rightWrapper} onClick={onNotificationClick}>
              <img
                src={Notification}
                alt="notification"
                className={styles.notification}
              />
              <div className={styles.notificationText}>Notifications</div>
            </div>
          )}
          {btnText4 ? (
            <div className={styles.headerBtn}>
              <Button
                fontSize="13px"
                btnText={btnText4}
                onClick={onAddClick4}
                backgroundColor="white"
                color="black"
                padding="0px 25px"
                height="25px"
                margin={"10px"}
              />
            </div>
          ) : null}
        </div>
      </Nav>

      <CSVLink
        ref={csvLink}
        className={styles.excelBtn}
        data={csvData || []}
        headers={filterColumns()}
        filename={`${exportFile} ${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}`}
      />

      {/* mobile header */}
      <div className={styles.mobileContainer}>
        <div className={styles.headerRow}>
          <img
            src={MonkeyFace}
            alt="MonkeyFace"
            className={styles.monkeyLogo}
          />

          {search === false ? null : (
            <div className={styles.searchContainer}>
              <input placeholder="Search" className={styles.searchInput} />
              <img src={Search} alt="search" className={styles.searchIcon} />
            </div>
          )}

          <div className={styles.btnRow}>
            {btnText ? (
              <div className={styles.headerMobileBtn} onClick={onAddClick}>
                {btnText}
              </div>
            ) : null}
          </div>

          <img
            src={menu}
            alt="menu"
            className={styles.mobileMenu}
            onClick={toggleDrawer}
          />
        </div>

        <div className={styles.headerRow}>
          <div className={styles.titleRow}>
            {svg ? (
              <Icon
                fill={"#28262c"}
                stroke={"#28262c"}
                className={styles.titleIcon}
              />
            ) : (
              <img src={icon} alt={title} className={styles.titleIcon} />
            )}
            <div className={styles.title}>{title}</div>
          </div>

          {viewStyles && (
            <div className={styles.viewStyles}>
              {cards === false ? null : (
                <MdOutlineViewCompact
                  size={20}
                  color={viewStylesColor === "cards" ? "#000000" : "#b7b6b6"}
                  onClick={() => onChangView("cards")}
                  className={styles.listIcon}
                />
              )}

              {lists === false ? null : (
                <AiOutlineUnorderedList
                  size={20}
                  color={viewStylesColor === "list" ? "#000000" : "#b7b6b6"}
                  className={styles.listIcon}
                  onClick={() => onChangView("list")}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <SidebarMobile open={isOpen} onClose={toggleDrawer} />
    </>
  );
}

export default Header;
