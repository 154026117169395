import {
  AcceptInvite,
  ForgotPassword,
  Login,
  OrderTracking,
  ResetPassword,
  Signup,
} from "../views";

export const routes = [
  {
    path: "/signin",
    component: <Login />,
  },
  {
    path: "/signup",
    component: <Signup />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    component: <ResetPassword />,
  },
  {
    path: "/accept-invite",
    component: <AcceptInvite />,
  },
  {
    path: "/track-order/:jobId",
    component: <OrderTracking />,
  },
];
