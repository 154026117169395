import React, { useEffect, useRef, useState } from "react";
import styles from "./EditJob.module.css";
import {
  AutoComplete,
  AutoCompleteCustomer,
  Block,
  ConfirmationAlert,
  DropdownForm,
  FormPhoneInput,
  Header,
  ImagePOD,
  InputForm,
  ProductCard,
} from "../../components";
import { Tasks } from "../../assets/svg";
import {
  ContactsService,
  DriversService,
  ProductService,
  SettingsService,
  TasksService,
  VehiclesService,
} from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { BiMailSend } from "react-icons/bi";
import { ImLocation2 } from "react-icons/im";
import Switch from "react-switch";
import { CountryHelpder, DateTimeHelper } from "../../helper";
import { LoadingContext, SocketContext } from "../../context";
import { FaUser } from "react-icons/fa";
import { ErrorList } from "../../constants";

function EditJob() {
  const navigate = useNavigate();

  // context
  const { setLoading } = LoadingContext.useLoading();
  const { socket, connectSocket, disconnectSocket } = SocketContext.useSocket();

  // hooks
  const location = useLocation(); // Access the location object
  const { jobDetails } = location.state; // Access the passed data from the "state" property

  // Create a reference to the hidden file input element

  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    toastMessage: null,
    toastType: null,
  });
  const [vehicles, setVehicles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [newTask, setNewTask] = useState({
    pickup_address: jobDetails?.pickup_address || "",
    pickup_address_lat: jobDetails?.pickup_address_lat || "",
    pickup_address_lng: jobDetails?.pickup_address_lng || "",
    dropoff_address: jobDetails?.dropoff_address || "",
    dropoff_address_lat: jobDetails?.dropoff_address_lat || "",
    dropoff_address_lng: jobDetails?.dropoff_address_lng || "",
    pickup_date: jobDetails?.pickup_date || "",
    status: jobDetails?.status || "Pending",
    payment_status: jobDetails?.payment_status || "",
    delivery_date: jobDetails?.delivery_date || "",
    delivery_type: jobDetails?.delivery_type || "",
    vehicle_id: jobDetails?.vehicle_id || null,
    recipient_name: jobDetails?.recipient_name || "",
    recipient_phone_no: jobDetails?.recipient_phone_no || "",
    recipient_email: jobDetails?.recipient_email || "",
    new_recipient: jobDetails?.recipient_name ? false : true,
    sender_name: jobDetails?.sender_name || "",
    sender_phone_no: jobDetails?.sender_phone_no || "",
    sender_email: jobDetails?.sender_email || "",
    new_sender: jobDetails?.sender_name ? false : true,
    save_to_contacts_recipient: true,
    save_to_contacts_sender: true,
    delivery_window: jobDetails?.delivery_window || "",
    customer_id: jobDetails?.customer_id || "",
    order_id: jobDetails?.order_id || "",
    delivery_vehicle_type: jobDetails?.delivery_vehicle_type || "",
    package_id: jobDetails?.package_id || "",
    delivery_cost: jobDetails?.delivery_cost || "",
    delivery_instructions: jobDetails?.delivery_instructions || "",
    podImg1: jobDetails?.podImg1 || null,
    deliveryImg1: jobDetails?.deliveryImg1 || null,
    deliveryImg2: jobDetails?.deliveryImg2 || null,
    deliveryImg3: jobDetails?.deliveryImg3 || null,
  });
  const [country, setCountry] = useState();
  const [options, setOptions] = useState({
    delivery_type: [],
    payment_status: [],
    delivery_window: [],
  });
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
  });
  const [products, setProducts] = useState([]);
  console.log(jobDetails, "jobdetails");
  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };

  const handleSwitchChange = (name, value) => {
    setNewTask((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressChange = (name, value) => {
    setNewTask((prev) => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleChangeImage = (name, event) => {
    setNewTask((prev) => ({ ...prev, [name]: event.target.files[0] }));
  };

  const getTenantVehiclesAndContacts = async () => {
    const [country, vehicles, contacts, settings] = await Promise.all([
      CountryHelpder.fetchCountry(),
      VehiclesService.getVehicles(),
      ContactsService.getContacts(null, 1),
      SettingsService.getTenantAppSetting(),
    ]);
    const deliveryType = settings.filter(
      (entry) => entry.key === "default_delivery_type"
    );
    const vehicleType = settings.filter(
      (entry) => entry.key === "vehicle_default_type"
    );
    console.log({ deliveryType });
    setCountry(country);
    setVehicles(vehicles?.data);
    setContacts(contacts?.data);
    if (deliveryType?.length) {
      let deliveryOptionsJson = JSON.parse(deliveryType?.[0]?.value);

      const defaultDeliveryType = deliveryOptionsJson.find(
        (option) => option.is_default === true
      );
      console.log(defaultDeliveryType?.option);
      setOptions({
        delivery_type: JSON.parse(deliveryType?.[0]?.value) || [],
        selected_delivery_type: defaultDeliveryType,
        // delivery_window: settings?.[0]?.delivery_window || [],
        // payment_status: settings?.[0]?.payment_status || [],
      });
    }
    if (vehicleType?.length) {
      setNewTask({
        ...newTask,
        delivery_vehicle_type: vehicleType?.[0]?.value,
      });
    }
  };

  useEffect(() => {
    setLoading(true);

    getTenantVehiclesAndContacts()
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  // connect websocket
  useEffect(() => {
    if (!socket) connectSocket();

    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // send a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        // Send a message to the server
        VehiclesService.subscribeVehicleSocket(socket);

        const timer = setInterval(() => {
          VehiclesService.getVehiclesSocket(socket);
        }, 15000);

        return () => clearInterval(timer);
      }
    }, 3000);

    return () => {
      socket && VehiclesService.unSubscribeVehicleSocket(socket);
    };
  }, [socket]);

  // received a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.onmessage = (e) => {
          const parsed = JSON.parse(e.data);
          // console.log(parsed);

          if (parsed?.type === "RESPONSE") {
            setVehicles(parsed?.data);
          } else if (parsed?.type === "SUBSCRIPTION") {
            VehiclesService.getVehiclesSocket(socket);
          }

          if (parsed?.data?.length) {
            setVehicles(parsed?.data);
          }
        };
      }
    }, 3000);
  }, [socket]);

  const validateForm = () => {
    const newErrors = {};

    if (!newTask.pickup_address) {
      newErrors.pickup_address = "Pickup address is required";
    } else if (!newTask.dropoff_address) {
      newErrors.dropoff_address = "Dropoff address is required";
    } else if (!newTask.pickup_date) {
      newErrors.pickup_date = "Pickup date is required";
    } else if (!newTask.delivery_date) {
      newErrors.delivery_date = "Delivery date is required";
    } else if (!newTask.recipient_phone_no) {
      newErrors.recipient_phone_no = "Recipient phone no is required";
    }

    return newErrors;
  };
  const onUpdateTask = (event) => {
    event.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      TasksService.updateTask({ id: jobDetails?.id, task: newTask })
        .then(async () => {
          await saveToContacts();
          navigate("/delivery-jobs");
        })
        .catch((err) => console.log(err));
    }
  };

  const saveToContacts = async () => {
    if (
      newTask.save_to_contacts_sender &&
      newTask.save_to_contacts_recipient &&
      newTask.new_recipient &&
      newTask.new_sender
    ) {
      await ContactsService.addContact({
        full_name: newTask?.sender_name,
        email: newTask?.sender_email,
        mobile_no: newTask?.sender_phone_no,
        home_address: newTask?.pickup_address,
        home_address_lat: newTask?.pickup_address_lat,
        home_address_lng: newTask?.pickup_address_lng,
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));

      await ContactsService.addContact({
        full_name: newTask?.recipient_name,
        email: newTask?.recipient_email,
        mobile_no: newTask?.recipient_phone_no,
        home_address: newTask?.dropoff_address,
        home_address_lat: newTask?.dropoff_address_lat,
        home_address_lng: newTask?.dropoff_address_lng,
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));
    } else if (newTask.save_to_contacts_sender && newTask.new_sender) {
      await ContactsService.addContact({
        full_name: newTask?.sender_name,
        email: newTask?.sender_email,
        mobile_no: newTask?.sender_phone_no,
        home_address: newTask?.pickup_address,
        home_address_lat: newTask?.pickup_address_lat,
        home_address_lng: newTask?.pickup_address_lng,
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));
    } else if (newTask.save_to_contacts_recipient && newTask.new_recipient) {
      await ContactsService.addContact({
        fullname: newTask?.recipient_name,
        email: newTask?.recipient_email,
        mobile_no: newTask?.recipient_phone_no,
        home_address: newTask?.dropoff_address,
        home_address_lat: newTask?.dropoff_address_lat,
        home_address_lng: newTask?.dropoff_address_lng,
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));
    }
  };
  //  --------Product handling------------

  const onDeleteProduct = async () => {
    setLoading(true);
    ProductService.deleteProducts({ productID: deleteModal?.id })
      .then((res) => {
        setToastMessage({
          toastType: "success",
          toastMessage: res,
        });
        setShow(true);

        getProduct();
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      })
      .finally(() => {
        setLoading(false);

        setDeleteModal({ show: false, id: null });
      });
  };
  const getProduct = () => {
    // setLoading(true);

    ProductService.getProducts()
      .then((data) => {
        const productsArray = jobDetails?.products?.map((job) =>
          data.find((product) => product.id === job)
        );
        console.log(productsArray, "productsArray");
        setProducts(productsArray);
      })
      .catch((err) => console.log(err));
    // .finally(() => setLoading(false));
  };
  useEffect(() => {
    getProduct();
  }, [jobDetails]);

  return (
    <Block
      style={{ padding: "0px 30px" }}
      withForm={true}
      onSubmit={onUpdateTask}
    >
      <Header icon={Tasks} title="Edit Delivery Job" search={false} />
      <ConfirmationAlert
        message="Are you sure want to delete this product?"
        show={deleteModal?.show}
        onDone={onDeleteProduct}
        onClose={() => setDeleteModal({ show: false, id: null })}
      />
      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg={toastMessage?.toastType || "success"}
          onClose={() => {
            setShow(false);
            setToastMessage({ toastMessage: null });
          }}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Body className={"text-white"}>
            {toastMessage?.toastMessage || "Copied to clipboard"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className={`row ${styles.form}`}>
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              <InputForm
                required={true}
                type="autocomplete"
                label="Pick up Address *"
                name="pickup_address"
                value={newTask.pickup_address}
                onPlaceSelect={(place) => {
                  handleAddressChange(
                    "pickup_address",
                    place?.formatted_address
                  );
                  handleAddressChange(
                    "pickup_address_lat",
                    place?.geometry?.location.lat()
                  );
                  handleAddressChange(
                    "pickup_address_lng",
                    place?.geometry?.location.lng()
                  );
                }}
                lastIcon={<ImLocation2 size={20} />}
                lastIconBackgroundColor="#000"
                inValidMessage={errors.pickup_address}
              />
              <InputForm
                required={true}
                type="autocomplete"
                label="Delivery Address *"
                name="dropoff_address"
                value={newTask.dropoff_address}
                onPlaceSelect={(place) => {
                  handleAddressChange(
                    "dropoff_address",
                    place?.formatted_address
                  );
                  handleAddressChange(
                    "dropoff_address_lat",
                    place?.geometry?.location.lat()
                  );
                  handleAddressChange(
                    "dropoff_address_lng",
                    place?.geometry?.location.lng()
                  );
                }}
                lastIcon={<ImLocation2 size={20} />}
                lastIconBackgroundColor={"#F7F7F7"}
                inValidMessage={errors.dropoff_address}
              />

              {/* <InputForm
                required={true}
                label="Status *"
                name="status"
                disabled={true}
                value={newTask.status}
                onChange={handleChange(setNewTask)}
              />
              <DropdownForm
                label="Select Payment Status"
                name="payment_status"
                value={newTask.payment_status}
                options={options?.payment_status?.map(
                  (item) => item?.tenant_payment_status
                )}
                onChange={handleChange(setNewTask)}
              /> */}

              <AutoCompleteCustomer
                options={contacts}
                label="Customer Name "
                lastIcon={<FaUser size={20} />}
                iconColor={"#888888"}
                selectedValue={[{ full_name: newTask.recipient_name }]}
                onSelect={(e) => {
                  setNewTask({
                    ...newTask,
                    recipient_name: e?.full_name,
                    recipient_email: e?.email,
                    recipient_phone_no: e?.mobile_no,
                    new_recipient: e?.new,
                    save_to_contacts_recipient: e?.new ? true : false,
                  });
                }}
                inValidMessage={errors.recipient_name}
              />
              <InputForm
                // lastIcon={<BiMailSend size={20} />}
                label="Customer Email Address"
                name="recipient_email"
                value={newTask.recipient_email}
                onChange={handleChange(setNewTask)}
              />
              <InputForm
                label="Order ID"
                name="order_id"
                value={newTask.order_id}
                onChange={handleChange(setNewTask)}
              />
            </div>

            <div className="col-sm-4">
              <InputForm
                required={true}
                label="Pick up Date & Time *"
                type="datetime-local"
                name="pickup_date"
                min={DateTimeHelper.minimumDate()}
                value={newTask.pickup_date}
                onChange={handleChange(setNewTask)}
                inValidMessage={errors.pickup_date}
              />
              <InputForm
                required={true}
                label="Delivery Date & Time *"
                type="datetime-local"
                name="delivery_date"
                min={newTask?.pickup_date}
                value={newTask.delivery_date}
                onChange={handleChange(setNewTask)}
                inValidMessage={errors.delivery_date}
              />
              <FormPhoneInput
                validated={true}
                label="Customer Phone Number *"
                name="recipient_phone_no"
                country={newTask.recipient_phone_no ? null : country}
                value={newTask.recipient_phone_no}
                onChange={(e) =>
                  setNewTask({ ...newTask, recipient_phone_no: e })
                }
                inValidMessage={errors.recipient_phone_no}
              />
              <label className={styles.switch}>
                <Switch
                  checked={newTask.save_to_contacts_recipient}
                  onChange={(e) =>
                    handleSwitchChange("save_to_contacts_recipient", e)
                  }
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#000"
                  onHandleColor="#fff"
                  // disabled={!newTask.new_recipient}
                />
                <span>Save to contacts</span>
              </label>

              <AutoComplete
                label="Assign Vehicle"
                options={vehicles}
                name="vehicle_id"
                value={newTask.vehicle_id}
                onSelect={(item) =>
                  setNewTask({
                    ...newTask,
                    vehicle_id: item?.[0]?.id,
                    status: item?.[0]?.id ? "Assigned" : "Pending",
                    delivery_vehicle_type: item?.[0]?.type,
                  })
                }
              />
            </div>
          </div>

          <div className={`d-flex ${styles.productCardView}`}>
            <ProductCard
              data={products}
              onDelete={(productId) =>
                setDeleteModal({
                  show: true,
                  id: productId,
                })
              }
            />
          </div>

          <div className="col-sm-12">
            <InputForm
              label="Special Instructions"
              name="delivery_instructions"
              type="multiline"
              value={newTask.delivery_instructions}
              onChange={handleChange(setNewTask)}
            />
          </div>

          <div className="row">
            <div className="col-sm-6">
              <DropdownForm
                options={options?.delivery_type?.map((item) => item?.option)}
                // disabled={true}
                label="Delivery Type"
                name="delivery_type"
                value={options?.selected_delivery_type?.option}
                onChange={handleChange(setNewTask)}
              />
              <AutoCompleteCustomer
                options={contacts}
                label="Sender Full Name *"
                selectedValue={[{ full_name: newTask.sender_name }]}
                onSelect={(e) => {
                  setNewTask({
                    ...newTask,
                    sender_name: e?.full_name,
                    sender_email: e?.email,
                    sender_phone_no: e?.mobile_no,
                    new_sender: e?.new,
                    save_to_contacts_sender: e?.new ? true : false,
                  });
                }}
                inValidMessage={errors.sender_name}
              />
              <InputForm
                lastIcon={<BiMailSend size={20} />}
                label="Sender Email Address"
                type="email"
                name="sender_email"
                value={newTask.sender_email}
                onChange={handleChange(setNewTask)}
              />
              <InputForm
                label="Customer ID"
                name="customer_id"
                value={newTask.customer_id}
                onChange={handleChange(setNewTask)}
              />
            </div>
            <div className="col-sm-6">
              <InputForm
                disabled={true}
                label="Delivery Vehicle Type"
                name="delivery_vehicle_type"
                value={newTask.delivery_vehicle_type}
                onChange={handleChange(setNewTask)}
              />
              <FormPhoneInput
                required={true}
                label="Sender Phone Number *"
                name="sender_phone_no"
                country={newTask.sender_phone_no ? null : country}
                value={newTask.sender_phone_no}
                onChange={(e) => setNewTask({ ...newTask, sender_phone_no: e })}
                inValidMessage={errors.sender_phone_no}
              />
              <label className={styles.switch}>
                <Switch
                  onChange={(e) =>
                    handleSwitchChange("save_to_contacts_sender", e)
                  }
                  checked={newTask.save_to_contacts_sender}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#000"
                  onHandleColor="#fff"
                  // disabled={!newTask.new_sender}
                />
                <span>Save to contacts</span>
              </label>
              <InputForm
                label="Package ID"
                name="package_id"
                value={newTask.package_id}
                onChange={handleChange(setNewTask)}
              />
            </div>
          </div>

          {/* <div className="row">
            <div className="col-sm-6">
              <DropdownForm
                options={options?.delivery_window?.map(
                  (item) => item?.tenant_delivery_window
                )}
                label="Delivery Window"
                name="delivery_window"
                value={newTask.delivery_window}
                onChange={handleChange(setNewTask)}
              />
              <ImagePOD
                label="Attach proof of delivery"
                hiddenFileInputRef={hiddenFileInput1}
                onClick={() => hiddenFileInput1.current.click()}
                onChange={(e) => handleChangeImage("podImg1", e)}
                img={newTask.podImg1}
              />
             
            
            </div>
            <div className="col-sm-6">
             
              <div className={styles.row}>
                <ImagePOD
                  width="32%"
                  label="Attach delivery photos"
                  hiddenFileInputRef={deliveryFileRef1}
                  onClick={() => deliveryFileRef1.current.click()}
                  onChange={(e) => handleChangeImage("deliveryImg1", e)}
                  img={newTask.deliveryImg1}
                />
                <ImagePOD
                  width="32%"
                  label="Attach delivery photos"
                  hiddenFileInputRef={deliveryFileRef2}
                  onClick={() => deliveryFileRef2.current.click()}
                  onChange={(e) => handleChangeImage("deliveryImg2", e)}
                  img={newTask.deliveryImg2}
                />
                <ImagePOD
                  width="32%"
                  label="Attach delivery photos"
                  hiddenFileInputRef={deliveryFileRef3}
                  onClick={() => deliveryFileRef3.current.click()}
                  onChange={(e) => handleChangeImage("deliveryImg3", e)}
                  img={newTask.deliveryImg3}
                />
              </div>
             
              <InputForm
                label="Delivery Cost"
                name="delivery_cost"
                value={newTask.delivery_cost}
                onChange={handleChange(setNewTask)}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className={` ${styles.btnContainer}`}>
        <div className="d-flex">
          <Button
            variant="dark"
            type="submit"
            className={styles.submit}
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            Submit
          </Button>

          {/* <Button
            type="submit"
            className={`${styles.submit} `}
            style={{ backgroundColor: "#E8E8E8", color: "#000" }}
            variant="dark"
          >
            Reset
          </Button> */}
        </div>
      </div>
    </Block>
  );
}

export default EditJob;
