import jwtDecode from "jwt-decode";
import { API } from "../utils/api";
import { getToken } from "./auth-service";
import { RolesHelper } from "../constants";

export const getCurrentUser = async () => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(API.current_user, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  } catch (error) {
    return error;
  }
};

export const getTenantSetting = async () => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.settings}?filter[tenant_id]=${tenant_id}`,
      obj
    );

    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  } catch (error) {
    throw error;
  }
};

// vehicle
export const getTenantAppSetting = async () => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.app_settings}?filter[tenant_id]=${tenant_id}`,
      obj
    );

    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  } catch (error) {
    throw error;
  }
};

export const saveVehicleAppSetting = async ({ name, ping_time, value }) => {
  try {
    const { token, tenant_id } = await getToken();

    const setting = await getTenantAppSetting();
    const vehicleTypeSetting = setting.find(
      (entry) => entry.key === "vehicle_default_type"
    );

    if (vehicleTypeSetting) {
      const obj = {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name,
          value,
        }),
      };
      const response = await fetch(
        `${API.app_settings}/${vehicleTypeSetting?.id}`,
        obj
      );
      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      // const filteredData =
      //   json?.data?.type === "vehicle" && json?.data?.tenant_id === tenant_id;

      return json?.data;
    } else {
      const obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          tenant_id,
          name,
          value,
          key: "vehicle_default_type",
          type: "vehicle",
          description:
            "Select default type for your vehicle, when you will create a new vehicle, it will set this type automatically.",
        }),
      };
      const response = await fetch(API.app_settings, obj);
      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      return json?.data;
    }
  } catch (error) {
    return error;
  }
};

export const getUsers = async () => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.users}?filter[tenant_id]=${tenant_id}&filter[role][_in]=${RolesHelper.roles.deliveryOps},${RolesHelper.roles.vehicleOps},${RolesHelper.roles.deliveryAndVehicleOps},${RolesHelper.roles.tenantAdmin}&fields=*.*&meta=*`,
      obj
    );
    const json = await response.json();
    console.log(json, "getUsers");
    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return { data: json?.data, total_count: json?.meta?.filter_count };
  } catch (e) {
    throw e;
  }
};

export const removeUser = async (id) => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API.users}/${id}`, obj);

    if (!response?.ok) throw new Error("Something went wrong");

    return await getUsers();
  } catch (e) {
    throw e;
  }
};

export const saveDeliveyTypeSetting = async (deliveryTypeValue) => {
  try {
    const { token } = await getToken();

    const setting = await getTenantAppSetting();
    const deliveryTypeSetting = setting.find(
      (entry) => entry.key === "default_delivery_type"
    );

    if (deliveryTypeSetting) {
      // Clone the array to avoid mutating the original state
      const updatedOptionsArray = [...JSON.parse(deliveryTypeSetting.value)];

      // Find the selected option in the array and set its is_default to true
      const selectedOptionIndex = updatedOptionsArray.findIndex(
        (option) => option.option === deliveryTypeValue
      );

      if (selectedOptionIndex !== -1) {
        // Set is_default to true for the selected option
        updatedOptionsArray[selectedOptionIndex].is_default = true;
        // Set is_default to false for all other options
        updatedOptionsArray.forEach((option, index) => {
          if (index !== selectedOptionIndex) {
            option.is_default = false;
          }
        });
      }

      // Make the PATCH request
      const obj = {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          value: updatedOptionsArray,
        }),
      };

      const response = await fetch(
        `${API.app_settings}/${deliveryTypeSetting?.id}`,
        obj
      );

      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      return json?.data;
    } else {
      // Handle the case where the setting is not present
      console.error("Setting not found");
    }
  } catch (error) {
    return error;
  }
};
export const saveDeliveyWindowSetting = async (deliveryWindowValue) => {
  try {
    const { token } = await getToken();

    const setting = await getTenantAppSetting();
    const deliveryWindowSetting = setting.find(
      (entry) => entry.key === "default_delivery_window"
    );

    if (deliveryWindowSetting) {
      // Clone the array to avoid mutating the original state
      const updatedOptionsArray = [...JSON.parse(deliveryWindowSetting.value)];

      // Find the selected option in the array and set its is_default to true
      const selectedOptionIndex = updatedOptionsArray.findIndex(
        (option) => option.option === deliveryWindowValue
      );

      if (selectedOptionIndex !== -1) {
        // Set is_default to true for the selected option
        updatedOptionsArray[selectedOptionIndex].is_default = true;
        // Set is_default to false for all other options
        updatedOptionsArray.forEach((option, index) => {
          if (index !== selectedOptionIndex) {
            option.is_default = false;
          }
        });
      }

      // Make the PATCH request
      const obj = {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          value: updatedOptionsArray,
        }),
      };

      const response = await fetch(
        `${API.app_settings}/${deliveryWindowSetting?.id}`,
        obj
      );

      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      return json?.data;
    } else {
      // Handle the case where the setting is not present
      console.error("Setting not found");
    }
  } catch (error) {
    return error;
  }
};
export const saveDeliveyPaymentSetting = async (deliveryPaymentValue) => {
  try {
    const { token } = await getToken();

    const setting = await getTenantAppSetting();
    const deliveryPaymentSetting = setting.find(
      (entry) => entry.key === "default_payment_status"
    );

    if (deliveryPaymentSetting) {
      // Clone the array to avoid mutating the original state
      const updatedOptionsArray = [...JSON.parse(deliveryPaymentSetting.value)];

      // Find the selected option in the array and set its is_default to true
      const selectedOptionIndex = updatedOptionsArray.findIndex(
        (option) => option.option === deliveryPaymentValue
      );

      if (selectedOptionIndex !== -1) {
        // Set is_default to true for the selected option
        updatedOptionsArray[selectedOptionIndex].is_default = true;
        // Set is_default to false for all other options
        updatedOptionsArray.forEach((option, index) => {
          if (index !== selectedOptionIndex) {
            option.is_default = false;
          }
        });
      }

      // Make the PATCH request
      const obj = {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          value: updatedOptionsArray,
        }),
      };

      const response = await fetch(
        `${API.app_settings}/${deliveryPaymentSetting?.id}`,
        obj
      );

      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      return json?.data;
    }
  } catch (error) {
    return error;
  }
};

export const addDeliveryTypeSettingOption = async ({ value }) => {
  const { token, tenant_id } = await getToken();

  const setting = await getTenantAppSetting();
  const deliveryTypeSetting = setting.find(
    (entry) => entry.key === "default_delivery_type"
  );

  // Existing array or an empty array if not present

  if (deliveryTypeSetting) {
    const existingOptionsArray = JSON.parse(deliveryTypeSetting?.value) || [];

    // Append the new object to the existing array
    const updatedOptionsArray = [
      ...existingOptionsArray,
      { option: value, is_default: false },
    ];
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: updatedOptionsArray,
      }),
    };
    const response = await fetch(
      `${API.app_settings}/${deliveryTypeSetting?.id}`,
      obj
    );
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);
    // console.log(json?.data, "addDeliveryTypeSettingOptionPatch");

    return json?.data;
  } else {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: [{ option: value, is_default: false }], // Use updatedOptionsArray instead of postArray
        name: "Default Delivery Type",
        key: "default_delivery_type",
        type: "delivery",
        description:
          "Select default type for your delivery, when you will create a new delivery, it will set this type automatically.",
      }),
    };
    const response = await fetch(API.app_settings, obj);
    const json = await response.json();
    // console.log(json?.data, "addDeliveryTypeSettingOption");
    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  }
};
export const addDeliveryWindowSettingOption = async ({ value }) => {
  const { token, tenant_id } = await getToken();

  const setting = await getTenantAppSetting();
  const deliveryWindowSetting = setting.find(
    (entry) => entry.key === "default_delivery_window"
  );

  // Existing array or an empty array if not present

  if (deliveryWindowSetting) {
    const existingOptionsArray = JSON.parse(deliveryWindowSetting?.value) || [];

    // Append the new object to the existing array
    const updatedOptionsArray = [
      ...existingOptionsArray,
      { option: value, is_default: false },
    ];
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: updatedOptionsArray,
      }),
    };
    const response = await fetch(
      `${API.app_settings}/${deliveryWindowSetting?.id}`,
      obj
    );
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);
    // console.log(json?.data, "addDeliveryWindowSettingOptionPatch");

    return json?.data;
  } else {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: [{ option: value, is_default: false }], // Use updatedOptionsArray instead of postArray
        name: "Default Delivery Window",
        key: "default_delivery_window",
        type: "delivery",
        description:
          "Select default window for your delivery, when you will create a new delivery, it will set this type automatically.",
      }),
    };
    const response = await fetch(API.app_settings, obj);
    const json = await response.json();
    // console.log(json?.data, "addDeliveryWindowSettingOption");
    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  }
};
export const addDeliveryPaymentSettingOption = async ({ value }) => {
  const { token, tenant_id } = await getToken();

  const setting = await getTenantAppSetting();
  const deliveryPaymentSetting = setting.find(
    (entry) => entry.key === "default_payment_status"
  );

  // Existing array or an empty array if not present

  if (deliveryPaymentSetting) {
    const existingOptionsArray =
      JSON.parse(deliveryPaymentSetting?.value) || [];

    // Append the new object to the existing array
    const updatedOptionsArray = [
      ...existingOptionsArray,
      { option: value, is_default: false },
    ];
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: updatedOptionsArray,
      }),
    };
    const response = await fetch(
      `${API.app_settings}/${deliveryPaymentSetting?.id}`,
      obj
    );
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);
    // console.log(json?.data, "addDeliveryPaymentSettingOptionPatch");

    return json?.data;
  } else {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: [{ option: value, is_default: false }], // Use updatedOptionsArray instead of postArray
        name: "Default Delivery Payment",
        key: "default_payment_status",
        type: "delivery",
        description:
          "Select default payment for your delivery, when you will create a new delivery, it will set this type automatically.",
      }),
    };
    const response = await fetch(API.app_settings, obj);
    const json = await response.json();
    // console.log(json?.data, "addDeliveryPaymentSettingOption");
    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  }
};

//  for notifications

export const addCustomerEmailNotification = async ({
  customer_email_notification,
  delivery_start,
  delivery_complete,
  delivery_arrived,
}) => {
  const { token, tenant_id } = await getToken();
  console.log(
    customer_email_notification,
    delivery_start,
    delivery_complete,
    delivery_arrived
  );
  const setting = await getTenantAppSetting();
  const customerNotification = setting.find(
    (entry) => entry.key === "customer_email_notifications"
  );

  // Existing array or an empty array if not present

  if (customerNotification) {
    // const existingOptionsArray = JSON.parse(customerNotification?.value) || [];

    // Append the new object to the existing array
    const updatedOptionsArray = [
      {
        property: "customer_email_notifications",
        enabled: customer_email_notification || false,
      },
      { property: "driver_starts", enabled: delivery_start || false },
      { property: "driver_complete", enabled: delivery_complete || false },
      { property: "driver_arrived", enabled: delivery_arrived || false },
    ];

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: updatedOptionsArray,
      }),
    };

    const response = await fetch(
      `${API.app_settings}/${customerNotification?.id}`,
      obj
    );

    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  } else {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: [
          {
            property: "customer_email_notifications",
            enabled: customer_email_notification || false,
          },
        ],
        name: "Customer Email Notifications",
        key: "customer_email_notifications",
        type: "notifications",
        description: "Notification Description here",
      }),
    };

    const response = await fetch(API.app_settings, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  }
};
export const addAdminEmailNotification = async ({
  admin_email_notification,
  vehicle_added,
  delivery_not_successful,
  delivery_complete_admin,
}) => {
  const { token, tenant_id } = await getToken();
  // console.log(admin_email_notification);

  const setting = await getTenantAppSetting();
  const adminNotification = setting.find(
    (entry) => entry.key === "admin_email_notifications"
  );

  // Existing array or an empty array if not present

  if (adminNotification) {
    // const existingOptionsArray = JSON.parse(adminNotification?.value) || [];

    // Append the new object to the existing array
    const updatedOptionsArray = [
      {
        property: "admin_email_notifications",
        enabled: admin_email_notification || false,
      },
      { property: "vehicle_added", enabled: vehicle_added || false },
      {
        property: "delivery_not_successful",
        enabled: delivery_not_successful || false,
      },
      {
        property: "delivery_complete_admin",
        enabled: delivery_complete_admin || false,
      },
    ];

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: updatedOptionsArray,
      }),
    };

    const response = await fetch(
      `${API.app_settings}/${adminNotification?.id}`,
      obj
    );

    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data || {};
  } else {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        tenant_id,
        value: [
          {
            property: "admin_email_notifications",
            enabled: admin_email_notification || false,
          },
        ],
        name: "Admin Email Notifications",
        key: "admin_email_notifications",
        type: "notifications",
        description: "Admin Notification Description here",
      }),
    };

    const response = await fetch(API.app_settings, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data || {};
  }
};

export const getQuotaForCustomerEmail = async () => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.app_usage}?filter[tenant_id]=${tenant_id}`,
      obj
    );

    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json?.data;
  } catch (error) {
    throw error;
  }
};
export const generateApiKey = async () => {
  try {
    const response = await fetch(API.randomString);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return updateApiKeySetting(json?.data);
  } catch (err) {
    throw err;
  }
};

const updateUserToken = async (apiKeyString) => {
  const { token } = await getToken();
  const { id } = await jwtDecode(token);

  try {
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        token: apiKeyString,
      }),
    };
    const response = await fetch(`${API.users}/${id}`, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return true;
  } catch (err) {
    throw err;
  }
};

export const updateUserPassword = async (password) => {
  const { token } = await getToken();
  const { id } = await jwtDecode(token);

  try {
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password: password,
      }),
    };
    const response = await fetch(`${API.users}/${id}`, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return true;
  } catch (err) {
    throw err;
  }
};

const updateApiKeySetting = async (apiKeyString) => {
  const { token, tenant_id } = await getToken();
  const setting = await getTenantSetting();

  try {
    if (setting?.length) {
      const obj = {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          isApiKey: true,
        }),
      };
      const response = await fetch(`${API.settings}/${setting?.[0]?.id}`, obj);
      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      await updateUserToken(apiKeyString);
      return { apiKey: apiKeyString, tenant_id };
    } else {
      const obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          isApiKey: true,
        }),
      };
      const response = await fetch(API.settings, obj);
      const json = await response.json();

      if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

      await updateUserToken(apiKeyString);
      return { apiKey: apiKeyString, tenant_id };
    }
  } catch (err) {
    throw err;
  }
};

export const getApiKey = async () => {
  const { tenant_id, token } = await getToken();

  const obj = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${API.settings}?filter[tenant_id]=${tenant_id}`,
    obj
  );
  const json = await response.json();

  if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

  return json?.data?.[0];
};

export const deleteApiKey = async () => {
  const { token } = await getToken();
  const setting = await getTenantSetting();

  try {
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        isApiKey: false,
      }),
    };
    const response = await fetch(`${API.settings}/${setting?.[0]?.id}`, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    await updateUserToken(null);
    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const inviteUser = async ({ email, role }) => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
        role,
        tenant_id,
        invite_url: `${window?.location?.origin}/accept-invite/`,
      }),
    };

    const response = await fetch(API.inviteUser, obj);

    if (response.ok) return "User invited successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
