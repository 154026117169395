import React, { useEffect, useRef, useState } from "react";
import styles from "./Vehicle.module.css";
import {
  AddVehicleModal,
  Block,
  Header,
  VehcileCard,
  VehcileMap,
  VehicleSidebarDetails,
  VehicleTable,
  Tabs,
  LoadingSpinner,
  ScrollLock,
  TrialEnd,
} from "../../components";
import { ReactComponent as VanIcon } from "../../assets/svg/van.svg";
import {
  AuthService,
  SubscriptionService,
  VehiclesService,
} from "../../services";
import { isMobile } from "react-device-detect";
import { LoadingContext, SocketContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { getSubscriptionByTenantId } from "../../services/subscription-service";
import { SubscriptionId } from "../../constants";
import { getToken } from "../../services/auth-service";

function Vehicle() {
  // context
  const { setLoading } = LoadingContext.useLoading();
  const { socket, connectSocket, disconnectSocket } = SocketContext.useSocket();

  // ref
  const vehicleSidebar = useRef();
  const hiddenFileInputRef = useRef(null);

  // states
  const [vehicles, setVehicles] = useState([]);
  const [count, setCount] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [open, setOpen] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState();
  const [viewStyle, setViewStyle] = useState("cards");
  const [tab, setTab] = useState("All");
  const [search, setSearch] = useState();
  const [tabLoading, setTabLoading] = useState(false);
  const [trialRemaining, setTrialRemaining] = useState(null);

  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const fetchSubscription = async () => {
    try {
      const { subscription } = await getSubscriptionByTenantId();
      subscription && setSubscriptionDetails(subscription);
      console.log(subscription, "subscriptionDetails");
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };
  const handleChangeImage = (event, item) => {
    const fileUploaded = event.target.files[0];

    const vehiclesArr = vehicles;

    var index = vehiclesArr?.findIndex((x) => x.id === item?.id);

    if (index !== -1) {
      // Create a copy of the original item to update
      const updated = { ...vehiclesArr[index] };
      // Update the properties of the item
      Object.assign(updated, {
        vehicle_img: URL.createObjectURL(fileUploaded),
      });
      // Replace the original item with the updated item in the array
      vehiclesArr[index] = updated;

      setVehicleDetails({
        ...vehicleDetails,
        vehicle_img: URL.createObjectURL(fileUploaded),
      });

      setVehicles(vehiclesArr);

      VehiclesService.updateVehcile({ id: item?.id, img: fileUploaded })
        .then((data) => console.log(data))
        .catch((data) => console.log(data));
    }
  };

  // refresh timer
  useEffect(() => {
    setLoading(true);
    fetchSubscription();
    SubscriptionService.trialRemainingDays()
      .then((res) => {
        setTrialRemaining(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAll().finally(() => setLoading(false));
  }, [setLoading]);

  // connect websocket
  useEffect(() => {
    if (!socket) connectSocket();

    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // send a message from the server
  useEffect(() => {
    // console.log(socket.readyState, "socket");
    // console.log(WebSocket.OPEN, "WebSocket");

    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        // Send a message to the server
        VehiclesService.subscribeVehicleSocket(socket);

        const timer = setInterval(() => {
          VehiclesService.getVehiclesSocket(socket);
        }, 15000);

        return () => clearInterval(timer);
      }
    }, 3000);

    return () => {
      socket && VehiclesService.unSubscribeVehicleSocket(socket);
    };
  }, [socket]);

  // received a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.onmessage = (e) => {
          const parsed = JSON.parse(e.data);
          console.log(parsed, "parsed");

          if (parsed?.type === "RESPONSE" && tab === "All" && !search) {
            setVehicles(parsed?.data);
            VehiclesService.getVehilceTabs()
              .then((res) => setCount(res))
              .catch((err) => console.log(err));

            parsed?.action === "create" && setModalShow(false);
          } else if (parsed?.type === "SUBSCRIPTION") {
            parsed?.keys?.[0] === vehicleSidebar?.current?.id &&
              setVehicleDetails(parsed?.payload?.[0]);

            VehiclesService.getVehiclesSocket(socket, tab);
            VehiclesService.getVehilceTabs()
              .then((res) => setCount(res))
              .catch((err) => console.log(err));
            parsed?.action === "create" && setModalShow(false);
          }

          if (parsed?.data?.length && tab === "All" && !search) {
            setVehicles(parsed?.data);
            setTotalCount(parsed?.data?.length);
          }
        };
      }
    }, 3000);
  }, [socket, tab, search]);

  const getAll = async () => {
    const [data, count] = await Promise.all([
      VehiclesService.getVehicles(),

      VehiclesService.getVehilceTabs(),
    ]);

    setVehicles(data?.data);
    setTotalCount(data?.total_count);
    setCount(count);
  };

  useEffect(() => {
    VehiclesService.getVehicles(search)
      .then((data) => setVehicles(data?.data))
      .catch((err) => console.log(err));
  }, [search]);

  useEffect(() => {
    setTabLoading(true);
    if (tab !== "All")
      VehiclesService.getVehiclesTabData(tab)
        .then((data) => {
          console.log(data, "tab Data");
          setVehicles(data?.data);
        })
        .catch((err) => console.log(err))
        .finally(() => setTabLoading(false));
    else getAll().finally(() => setTabLoading(false));
  }, [tab]);

  // create QR code for new vehicle
  const createQRCode = async (event) => {
    event.preventDefault();

    const { tenant_id } = await getToken();

    setQrCode(tenant_id);
    setModalShow(true);
  };
  console.log(vehicles, "AllVehiclesDetails");
  return (
    <>
      <Block
        style={{ padding: `0px ${isMobile ? "0px" : "25px"}` }}
        onClick={() => open && setOpen(false)}
      >
        <Header
          svg={true}
          viewStyles={true}
          icon={VanIcon}
          title="Vehicles"
          searchMargin="0px 0px 0px 50px"
          // btnText="+ Add"
          // onAddClick={createQRCode}
          searchValue={search}
          onChangeSearch={(e) => setSearch(e?.target?.value)}
          viewStylesColor={viewStyle}
          onChangView={(e) => setViewStyle(e)}
        />
        {/* <AddVehicleModal
          value={qrCode}
          show={modalShow}
          onHide={() => setModalShow(false)}
        /> */}

        {/* tabs */}

        {vehicles?.length || viewStyle === "cards" ? (
          <Tabs
            className={styles.tab}
            allCount={totalCount}
            activeKey={tab}
            onSelect={(e) => setTab(e)}
            tabs={count}
          />
        ) : null}

        {tabLoading ? (
          <LoadingSpinner componentLoading={tabLoading} />
        ) : viewStyle === "cards" ? (
          <div className={styles.cardsRow}>
            {vehicles?.length
              ? vehicles?.map((item, index) => (
                  <VehcileCard
                    item={item}
                    key={index}
                    onClick={() => {
                      setOpen(true);
                      setVehicleDetails(item);
                      vehicleSidebar.current = item;
                    }}
                    // onMapView={() => {
                    //   setOpen(false);
                    //   setVehicleDetails();
                    //   setViewStyle("map");
                    // }}
                    onMapView={() => {
                      navigate("/vehicle/vehicle-Tracking", {
                        state: { item },
                      });
                    }}
                  />
                ))
              : null}
          </div>
        ) : viewStyle === "map" ? (
          <VehcileMap vehicles={vehicles} />
        ) : (
          <VehicleTable
            data={vehicles}
            onClickRow={(item) => {
              setOpen(true);
              setVehicleDetails(item);
            }}
          />
        )}

        {!vehicles?.length && tab === "All" && (
          <div className={styles.noData}>
            <p>There are no vehicles added, Lets start with first</p>
            <span>Learn How to add vehicles</span>
          </div>
        )}

        <ScrollLock isLocked={open} />
      </Block>

      {open && (
        <div
          onClick={() => {
            setOpen(false);
            // setVehicleDetails();
          }}
          className="overlay"
        />
      )}

      <VehicleSidebarDetails
        isOpen={open}
        onClose={() => {
          setOpen(false);
          // setVehicleDetails();
        }}
        item={vehicleDetails}
        fileRef={hiddenFileInputRef}
        onClickImg={() => hiddenFileInputRef.current.click()}
        onChangeImg={handleChangeImage}
      />
      {trialRemaining < 1 &&
      subscriptionDetails?.subscription_plan_id?.id ==
        SubscriptionId.subscriptionId.TRIAL ? (
        <div className={styles.trial_end}>
          <TrialEnd />
        </div>
      ) : null}
    </>
  );
}

export default Vehicle;
