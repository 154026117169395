import React, { useState, useEffect } from "react";
import styles from "./AddDriverSidebar.module.css";
import { RxCross2 } from "react-icons/rx";
import InputForm from "../InputForm/InputForm";
import Button from "../Button/Button";
import { BsEye, BsEyeSlash } from "react-icons/bs";

function AddDriverSidebar({
  item,
  isOpen,
  onClose,
  onAddClick,
  driver,
  onChange,
  errors,
  mode,
}) {
  const [viewPassword, setViewPassword] = useState(false);
  console.log(mode, "mode");
  return (
    <div
      className={`${styles.sidebarContainer} ${
        isOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <RxCross2
        size={22}
        className={styles.cross}
        onClick={() => {
          onClose();
        }}
      />
      {/* <Modal.Body className={styles.modalBody}> */}
      <p className={styles.mainHead}>{mode ? "Edit Driver" : "Add Driver"}</p>

      <InputForm
        width="80%"
        label="First Name *"
        name="first_name"
        value={driver?.first_name}
        onChange={onChange}
        inValidMessage={errors?.first_name}
      />
      <InputForm
        width="80%"
        label="Last Name *"
        name="last_name"
        value={driver?.last_name}
        onChange={onChange}
        inValidMessage={errors?.last_name}
      />
      <InputForm
        width="80%"
        label="Email *"
        name="email"
        value={driver?.email}
        onChange={onChange}
        inValidMessage={errors?.email}
        disabled={mode ? true : false}
      />
      <InputForm
        width="80%"
        label="Password *"
        name="password"
        // type={viewPassword ? "text" : "password"}
        lastIcon={viewPassword ? <BsEye /> : <BsEyeSlash />}
        onClickLastIcon={() => setViewPassword(!viewPassword)}
        value={driver?.password}
        onChange={onChange}
        inValidMessage={errors?.password}
      />
      <div className={styles.btn}>
        <Button
          width="30%"
          btnText={mode ? "Save" : "Add"}
          onClick={onAddClick}
        />
      </div>

      {/* </Modal.Body> */}
    </div>
  );
}

export default AddDriverSidebar;
