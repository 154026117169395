import { RolesHelper } from "../constants";
import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const getProducts = async () => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(API.products, obj);
    const json = await response.json();

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const addProducts = async ({
  title,
  sku_id,
  price,
  dimensions,
  quantity,
  // image,
  weight,
}) => {
  try {
    const { token } = await getToken();
    console.log(title, sku_id, price, dimensions, quantity, weight);
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        title,
        sku_id,
        dimensions,
        price,
        quantity,
        // image,
        weight,
      }),
    };

    const response = await fetch(API.products, obj);
    console.log(response, "responseProduct");
    if (response.ok) return "Product added successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};

// export const getAllProducts = async () => {
//   try {
//     const { token, tenant_id } = await getToken();

//     const obj = {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     const response = await fetch(
//       `${API.users}?filter[tenant_id]=${tenant_id}&filter[role][_in]=${RolesHelper.roles.driverRole}`,
//       obj
//     );
//     const json = await response.json();

//     if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

//     return json?.data;
//   } catch (e) {
//     throw e;
//   }
// };
export const editProducts = async ({
  title,
  sku_id,
  price,
  dimensions,
  quantity,
  // image,
  weight,
  id,
}) => {
  try {
    const { token } = await getToken();
    console.log(
      title,
      sku_id,
      price,
      dimensions,
      quantity,
      // image,
      weight,
      id,
      "reqBody"
    );
    // Create an object to hold the properties with values
    const requestBody = {};

    // Include properties in the request body only if they are provided
    if (title) requestBody.title = title;
    if (sku_id) requestBody.sku_id = sku_id;
    if (price) requestBody.price = price;
    if (dimensions) requestBody.dimensions = dimensions;
    if (quantity) requestBody.quantity = quantity;
    // if (image) requestBody.image = image;
    if (weight) requestBody.weight = weight;

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(`${API.products}/${id}`, obj);

    if (response.ok) return "Product edited successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
export const deleteProducts = async ({ productID }) => {
  try {
    const { token } = await getToken();
    // console.log(productID, "productID");
    const obj = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API.products}/${productID}`, obj);

    if (response.ok) return "Product deleted successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
