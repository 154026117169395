import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RolesHelper } from "../constants";

const PublicRoutes = ({ user }) => {
  const location = useLocation();
  const role = RolesHelper.getCurrentUserRole(user);

  const from =
    location.state?.from?.pathname || role === RolesHelper.roles.vehicleOps
      ? "vehicle"
      : role === RolesHelper.roles.deliveryAndVehicleOps
      ? "vehicle"
      : role === RolesHelper.roles.deliveryOps
      ? "delivery-jobs"
      : "/";

  return user ? <Navigate to={from} /> : <Outlet />;
};

export default PublicRoutes;
