import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { useLoading } from "../../context/LoadingContext";
import styles from "./LoadingSpinner.module.css";

function LoadingSpinner({ componentLoading }) {
  const { loading } = useLoading();

  return (
    (loading || componentLoading) && (
      <div className={styles.container}>
        <PuffLoader color="#000000" className={styles.loader} />
      </div>
    )
  );
}

export default LoadingSpinner;
