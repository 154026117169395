import React, { useState } from "react";
import styles from "./ForgotPassword.module.css";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Button, InputForm } from "../../components";
import { Logo } from "../../assets/svg";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/delivery-guy.json";
import { AuthService } from "../../services";

// Context
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [loading, setLoading] = useState();

  const navigate = useNavigate();

  const [show, setShow] = useState({
    isVisible: false,
    message: "",
    toastType: "",
  });
  const [userData, setUserData] = useState({
    email: "",
  });
  const [validated, setValidated] = useState(false);

  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onForgotPassword = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setLoading(true);
      AuthService.resetPasswordRequest(userData.email)
        .then((json) => {
          if (json?.errors?.length > 0) {
            setShow({
              isVisible: true,
              toastType: "danger",
              message: json?.errors[0]?.message,
            });
          } else {
            setShow({
              isVisible: true,
              toastType: "success",
              message: "Your reset password request has been send successfully",
            });
          }
        })
        .catch((err) => console.log({ err }))
        .finally(() => setLoading(false));
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice",
      trasnparent: true,
    },
  };

  return (
    <div className={`container-fluid ${styles.container}`}>
      <div className="row">
        <div className={`col-sm-6 ${styles.leftContainer}`}>
          <div className={styles.headingContainer}>
            <Logo className={styles.logo} />
            <div className={styles.back}>Forgot your password?</div>
            <span className={styles.heading}>Send reset email</span>
          </div>

          <Form noValidate validated={validated} onSubmit={onForgotPassword}>
            <InputForm
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Email *"
              type="email"
              name="email"
              value={userData.email}
              onChange={handleChange(setUserData)}
              lastIcon="@"
            />

            <div className={styles.btnWrapper}>
              <Button loading={loading} type="submit" btnText="Send" />
            </div>
          </Form>

          <div className={styles.forgot} onClick={() => navigate("/signin")}>
            Already have an account?
            <span style={{ textDecoration: "underline" }}>Login</span>
          </div>
        </div>
        <div className={`col-sm-6 ${styles.rightContainer}`}>
          <div className={styles.leftHeadingContainer}>
            <div className={styles.back}>Lets start real-time tracking!</div>
          </div>

          <div
            style={{
              height: isMobile ? "50vh" : "60vh",
              width: "90%",
            }}
          >
            <Lottie
              options={defaultOptions}
              height={isMobile ? "50vh" : "60vh"}
              width={"100%"}
            />
          </div>
        </div>
      </div>

      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg={show?.toastType}
          onClose={() => setShow({ isVisible: false })}
          show={show.isVisible}
          delay={3000}
          autohide
        >
          <Toast.Body>{show?.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default ForgotPassword;
