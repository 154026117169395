import React, { useState, useEffect } from "react";
import styles from "./BatchTableNew.module.css";
import { Table } from "react-bootstrap";
import Checkbox from "../Checkbox/Checkbox";
import GreenTick from "../../assets/img/greenTick.png";
import RedCross from "../../assets/img/RedCross.png";
import Button from "../Button/Button";
import { DateTimeHelper } from "../../helper"; // Import the DateTimeHelper
import { formatTime, formatTimeNew } from "../../helper/dateTime";
import validator from "validator";
import { countryCodes, isValidPhoneNumber } from "../../helper/task-hlper";
import ImportModal from "../ImportModal/ImportModal";

function validateRow(row) {
  const errors = [];
  const recipient_phone_number = row.customer_Phoneex_.toString();

  if (row.pickup_Address) {
    if (!row.pickup_DateMMDDYYYY) {
      errors.push(`Pickup Date is required.`);
    }

    // Field datatype validation for pickup_datetime
    if (!DateTimeHelper.isExcelDateFormatValid(row.pickup_DateMMDDYYYY)) {
      errors.push(`Pickup Date should be in format DD:MM:YYYY.`);
    }

    if (
      DateTimeHelper.excelDateToJSDate(row.pickup_DateMMDDYYYY) <=
      new Date().toISOString()
    ) {
      errors.push(`Pickup Date should be greater than today's date.`);
    }
  } else {
    // If pickup_location is not provided, pickup_datetime is not required
    if (row.pickup_DateMMDDYYYY) {
      errors.push(`Pickup Date should not be provided without Pickup Address.`);
    }
  }

  if (!row.delivery_AddressRequired) {
    errors.push(`Delivery Address is required.`);
  }

  if (!row.delivery_DateDDMMYYYY) {
    errors.push(`Delivery Date is required.`);
  }

  // Field datatype validation for dropoff_location
  if (typeof row.delivery_AddressRequired !== "string") {
    errors.push(`Invalid value provided for dropoff_location.`);
  }

  // Field datatype validation for dropoff_datetime
  if (!DateTimeHelper.isExcelDateFormatValid(row.delivery_DateDDMMYYYY)) {
    errors.push(`dropoff_datetime should be in format DD:MM:YYYY.`);
  }

  if (
    row.pickup_DateMMDDYYYY &&
    DateTimeHelper.excelDateToJSDate(row.delivery_DateDDMMYYYY) <
      DateTimeHelper.excelDateToJSDate(row.pickup_DateMMDDYYYY)
  ) {
    errors.push(`Delivery Date must be greater than Pickup Date.`);
  }
  // Convert times to Date objects for comparison

  // const pickupTime = formatTimeNew(row?.pickup_TimeHHmm);
  // const deliveryTime = formatTimeNew(row?.delivery_TimeHHmm);

  if (row.pickup_DateMMDDYYYY === row.delivery_DateDDMMYYYY) {
    // If pickup date is equal to delivery date, compare times
    if (row?.pickup_TimeHHmm > row?.delivery_TimeHHmm) {
      errors.push(`Delivery Time must be greater than Pickup Time.`);
    }
  }
  // Validation for phone number format
  if (row.customer_Phoneex_ && !isValidPhoneNumber(recipient_phone_number)) {
    errors.push(`Invalid Customer Number found.`);
  }

  // Validation for email format
  if (row.customer_Email && !/^\S+@\S+\.\S+$/.test(row.customer_Email)) {
    errors.push(`Invalid Customer Email found.`);
  }

  return errors.length ? errors : null; // Return an array of errors or null
}

function hasErrors(item) {
  const errors = validateRow(item);
  return Array.isArray(errors) && errors.length > 0;
}

function BatchTableNew({ data, onUpload, disable, setDisable }) {
  const [modalErrors, setModalErrors] = useState([]);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    console.log(data, "tableData");

    // Check if any row has errors and update the disable state
    //   const hasError = data?.some((item) => hasErrors(item));
    //   setDisable(hasError);
    // }, [data]);

    // Check if any row has errors and update the disable state
    const hasError = data?.some((item) => hasErrors(item));
    disable !== hasError && setDisable(hasError);
  }, [data, disable]);

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th className={styles.statusth}>Status</th>
            <th className={styles.th}></th>
            <th className={styles.th}></th>
            <th className={styles.th}>Pickup Address</th>
            <th className={styles.th}>Pickup Date/Time</th>
            <th className={styles.th}>Delivery Address</th>
            <th className={styles.th}>Delivery Date/Time</th>
            <th className={styles.th}>Customer Name</th>
            <th className={styles.th}>Customer Phone No</th>
            {/* <th className={styles.th}></th> */}
            <th className={styles.th}>Delivery Window</th>
            <th className={styles.th}>Vehicle Type</th>
            <th className={styles.th}>Delivery Type</th>
            <th className={styles.th}>Sender Phone No</th>
            <th className={styles.th}>Sender Name</th>
            <th className={styles.th}></th>
            {/* {disable && <th className={styles.th}>Errors</th>} */}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index}>
              <td
                // style={{ background: "transparent" }}
                className={styles.statusTd}
              >
                {hasErrors(item) ? (
                  <img
                    src={RedCross}
                    alt="RedCross"
                    className={styles.greenTick}
                  />
                ) : (
                  <img
                    src={GreenTick}
                    alt="GreenTick"
                    className={styles.greenTick}
                  />
                )}
              </td>
              <td></td>
              <td> </td>
              <td className={styles.td}>
                {item.pickup_Address && item.pickup_Address.length > 10
                  ? `${item.pickup_Address.slice(0, 15)}...`
                  : item.pickup_Address || "N/A"}
              </td>
              <td className={styles.td}>
                {item?.pickup_DateMMDDYYYY || "N/A"} ,
                {formatTimeNew(item?.pickup_TimeHHmm) || "N/A"}
              </td>
              <td className={styles.td}>
                {item.delivery_AddressRequired &&
                item.delivery_AddressRequired.length > 10
                  ? `${item.delivery_AddressRequired.slice(0, 15)}...`
                  : item.delivery_AddressRequired || "N/A"}
              </td>
              <td className={styles.td}>
                {item?.delivery_DateDDMMYYYY || "N/A"} ,
                {formatTimeNew(item?.delivery_TimeHHmm) || "N/A"}
              </td>
              <td className={styles.td}>{item?.customer_Name || "N/A"}</td>
              <td className={styles.td}>+{item?.customer_Phoneex_ || "N/A"}</td>
              <td className={styles.td}>{item?.delivery_window || "N/A"}</td>
              <td className={styles.td}>
                {item?.delivery_Vehicle_Type || "N/A"}
              </td>
              <td className={styles.td}>{item?.delivery_Type || "N/A"}</td>
              <td className={styles.td}>+{item?.sender_Phoneex_ || "N/A"}</td>
              <td className={styles.td}>{item?.sender_Name || "N/A"}</td>
              {hasErrors(item) ? (
                <td
                  className={styles.td}
                  onClick={() => {
                    const errors = validateRow(item);
                    setModalErrors(errors);
                    setModal(true);
                  }}
                >
                  <span className={styles.error}>Show Errors</span>
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <Button
          btnText="Upload"
          margin="20px 0px"
          disabled={disable}
          backgroundColor={disable ? "#b5b5b5" : "#000"}
          onClick={onUpload}
        /> */}
        <ImportModal
          show={modal}
          onHide={() => setModal(false)}
          errors={modalErrors}
        />
      </div>
    </>
  );
}

export default BatchTableNew;
