import React from "react";
import styles from "./Checkbox.module.css";

function Checkbox({ onChange, checked, label, name, value }) {
  return (
    <div className={styles.row}>
      <input
        checked={checked}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        className={styles.checkbox}
      />
      {label && <label className={styles.label}>{label}</label>}
    </div>
  );
}

export default Checkbox;
