import React from "react";
// import styles from "./CustomerSupport.module.css";
import { Block, Header } from "../../components";
import { Help } from "../../assets/svg";

function CustomerSupport() {
  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header icon={Help} title="Help" search={false} />
    </Block>
  );
}

export default CustomerSupport;
