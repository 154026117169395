import React, { useState } from "react";
import styles from "./InputForm.module.css";
import { Form, InputGroup } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";

function InputForm({
  label,
  type,
  name,
  value,
  onChange,
  icon,
  width,
  lastIcon,
  required,
  onPlaceSelect,
  disabled,
  placeholder,
  onClickLastIcon,
  inValidMessage,
  min,
  max,
  controlId,
}) {
  const [focus, setFocus] = useState();

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAP_KEY,
    onPlaceSelected: (place) => onPlaceSelect(place),
    options: {
      types: ["geocode", "establishment"],
    },
  });

  return (
    <Form.Group
      className="mb-3"
      style={{ width }}
      controlId={controlId ? controlId : "exampleForm.ControlInput1"}
    >
      <Form.Label className={styles.label}>{label}</Form.Label>

      <InputGroup>
        {icon && (
          <InputGroup.Text className={focus ? styles.iconFocus : styles.icon}>
            {icon}
          </InputGroup.Text>
        )}

        {type === "multiline" ? (
          <Form.Control
            required={required}
            as="textarea"
            rows={5}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={`${styles.textarea} ${
              lastIcon ? styles.inputActive : styles.inputNotactive
            }`}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            isInvalid={inValidMessage ? true : false}
          />
        ) : type === "autocomplete" ? (
          <Form.Control
            required={required}
            className={`${styles.input} ${
              lastIcon ? styles.inputActive : styles.inputNotactive
            }`}
            // type="email"
            disabled={disabled}
            ref={ref}
            defaultValue={value}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            isInvalid={inValidMessage ? true : false}
          />
        ) : (
          <Form.Control
            required={required}
            className={`${styles.input} ${
              lastIcon ? styles.inputActive : styles.inputNotactive
            }`}
            name={name}
            min={min}
            max={max}
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            style={{
              borderRight: lastIcon && "none",
              borderLeft: icon && "none",
            }}
            isInvalid={inValidMessage ? true : false}
          />
        )}

        {lastIcon && (
          <InputGroup.Text
            className={focus ? styles.iconFocus : styles.icon}
            onClick={onClickLastIcon}
          >
            {lastIcon}
          </InputGroup.Text>
        )}

        {inValidMessage ? (
          <Form.Control.Feedback type="invalid">
            {inValidMessage}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Fied is required
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
}

export default InputForm;
