export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const getYouTubeVideoIdFromUrl = (url) => {
  // Our regex pattern to look for a youTube ID
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //Match the url with the regex
  const match = url?.match(regExp);
  //Return the result
  return match && match?.[2]?.length === 11 ? match?.[2] : undefined;
};

export const millisecondsToStr = (milliseconds) => {
  // TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  function numberEnding(number) {
    return number > 1 ? "s" : "";
  }

  var temp = Math.floor(milliseconds / 1000);
  var years = Math.floor(temp / 31536000);
  if (years) {
    return years + " year" + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  var days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + " day" + numberEnding(days);
  }
  var hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + " hour" + numberEnding(hours);
  }
  var minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + " minute" + numberEnding(minutes);
  }
  var seconds = temp % 60;
  if (seconds) {
    return seconds + " second" + numberEnding(seconds);
  }
  return "less than a second"; //'just now' //or other string you like;
};

const MIN = 60;
const HOUR = MIN * 60;
const DAY = HOUR * 24;
const YEAR = DAY * 365;

export const formatTime = (time) => {
  if (!time) return;

  let minutes = new Date(time).getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let hours = new Date(time).getHours();
  const period = hours > 11 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? "0" + hours : hours;

  const formatted = `${hours}:${minutes} ${period}`;
  return formatted;
};

export const formatDate = (date) => {
  if (!date) return;

  return new Date(date).toString().slice(4, 15);
};

export const getStartEndDate = (date) => {
  const modifiedDate = new Date(date);
  modifiedDate.setSeconds(0);
  modifiedDate.setMinutes(0);
  modifiedDate.setHours(0);
  const start = modifiedDate.getTime();
  modifiedDate.setHours(24);
  const end = modifiedDate.getTime();

  return { start, end };
};

export const getTimeDifference = (timestamp = Date.now()) => {
  const current = Date.now();
  const pastTime = timestamp || Date.now();
  const difference = (current - pastTime) / 1000;

  let differenceInWord;

  if (difference < 60) differenceInWord = Math.floor(difference) + " sec ago";
  else if (difference / MIN < 60)
    differenceInWord = Math.floor(difference / MIN) + " min ago";
  else if (difference / HOUR < 24)
    differenceInWord = Math.floor(difference / HOUR) + " hours ago";
  else if (difference / DAY < 2)
    differenceInWord = Math.floor(difference / DAY) + " day ago";
  else if (difference / DAY < 365)
    differenceInWord = Math.floor(difference / DAY) + " days ago";
  else if (difference / YEAR < 2)
    differenceInWord = Math.floor(difference / YEAR) + " yr ago";
  else differenceInWord = Math.floor(difference / YEAR) + " yrs ago";

  return differenceInWord;
};

export const timeFormatter = (date) => {
  if (!date) return;

  const time = new Date(date);

  const hr = time.getHours() % 12 || 12;
  const min = time.getMinutes();
  const period = time.getHours() > 11 ? "PM" : "AM";
  return `${hr}:${min < 10 ? 0 : ""}${min} ${period}`;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const dateFormatter = (timestamp = Date.now()) => {
  if (!timestamp) return;

  const date = new Date(timestamp);

  // get utc time zone to prevent - date appears one day before of selected date
  const d = date.getUTCDate();

  getOrdinal(d);

  return `${months[date.getMonth()]} ${d}, ${date.getFullYear()}`;
};

export const getOrdinal = (d) => {
  if (d > 3 && d < 21) return "th";

  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const getDayDifference = (previous, current) => {
  if (!current) return;
  if (!previous) return dateFormatter(current);
  const prevDate = new Date(previous).toISOString().split("T")[0];
  const currDate = new Date(current).toISOString().split("T")[0];

  if (prevDate === currDate) return;

  const now = Date.now();

  const difference = (now - current) / 1000;

  let differenceInWord;

  if (difference / HOUR < 24) differenceInWord = "Today";
  else if (difference / DAY < 2) differenceInWord = "Yesterday";
  else differenceInWord = dateFormatter(current);
  return differenceInWord;
};

export const todayDateFormat = () => {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
};
