import React from "react";
import styles from "./Button.module.css";
import { BeatLoader } from "react-spinners";

function Button({
  btnText,
  color,
  backgroundColor,
  margin,
  onClick,
  fontSize,
  noBorder,
  width,
  disabled,
  type,
  loading,
  padding,
  height,
}) {
  return (
    <button
      type={type}
      style={{
        color: color || "#fff",
        backgroundColor: backgroundColor || "#000",
        margin,
        border: noBorder ? "none" : `1px solid ${color}`,
        fontSize,
        width,
        cursor: disabled ? null : "pointer",
        padding,
        height,
      }}
      disabled={disabled || loading}
      className={styles.btn}
      onClick={onClick}
    >
      {loading ? <BeatLoader color="#fff" size="18px" /> : btnText}
    </button>
  );
}

export default Button;
