import React from "react";
import "./TabsWihoutBadge.css";
import { Tab, Tabs } from "react-bootstrap";

const CustomTabs = ({ tabs, activeKey, onSelect, className }) => {
  const handleTabSelect = (key) => {
    if (onSelect) {
      onSelect(key);
    }
  };

  return (
    <Tabs
      className={className}
      activeKey={activeKey}
      onSelect={handleTabSelect}
    >
      {tabs?.map((item, index) => (
        <Tab
          key={index}
          eventKey={item}
          title={
            <TabTitleWithBadge
              title={item}
              onClick={() => handleTabSelect(item)}
            />
          }
        />
      ))}
    </Tabs>
  );
};

const TabTitleWithBadge = ({ title, onClick }) => (
  <div onClick={onClick}>{title}</div>
);

export default CustomTabs;
