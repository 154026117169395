import jwtDecode from "jwt-decode";

export const roles = {
  appUserRole: "0f01da78-2d8b-414c-a44e-1109308dec58", //user role
  vehicleOps: "7f673549-5ddb-4d0d-aaa0-30ff7897229c", //vehicle ops
  deliveryOps: "02f4ef6d-922f-42d2-8cee-0ba5c12ab37e", //delivery job ops
  tenantAdmin: "8391921b-3d35-44e1-8cc2-f224c67a7fc3", //admin ops
  deliveryAndVehicleOps: "3c6002f9-8cee-47ec-938c-71d05fe76a0e", //delivery and vehicle ops
  driverRole: "715a36bd-7440-4ac6-81c8-03b269d13394", //add driver roles
};

export const getUserRole = (user, roles) => {
  if (!user) return;

  const { role } = jwtDecode(user?.data?.access_token);
  return roles?.includes(role);
};
export const getCurrentUserRole = (user) => {
  if (!user) return;

  const { role } = jwtDecode(user?.data?.access_token);
  return role;
};
