import React from "react";
import styles from "./DropdownSetting.module.css";
import { Form } from "react-bootstrap";
import { IoAddCircleSharp } from "react-icons/io5";

function DropdownSetting({
  label,
  value,
  onChange,
  name,
  options,
  width,
  required,
  inValidMessage,
  add,
  onAdd,
}) {
  return (
    <Form.Group
      className="mb-3"
      style={{ width }}
      controlId="exampleForm.ControlInput1"
    >
      <Form.Label className={styles.label}>
        {label} {add ? <IoAddCircleSharp size={18} onClick={onAdd} /> : null}
      </Form.Label>
      <Form.Select
        required={required}
        name={name}
        value={value}
        className={[styles.input, value === label && styles.placeholder]}
        onChange={onChange}
        isInvalid={inValidMessage ? true : false}
      >
        <option value={label}>{label}</option>
        {options?.map((item, index) => (
          <option key={index} value={item?.value}>
            {item?.name}
          </option>
        ))}
      </Form.Select>
      {inValidMessage ? (
        <Form.Control.Feedback type="invalid">
          {inValidMessage}
        </Form.Control.Feedback>
      ) : (
        <Form.Control.Feedback type="invalid">
          Fied is required
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default DropdownSetting;
