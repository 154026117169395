import React from "react";
import { Table } from "react-bootstrap";
import { DateTimeHelper } from "../../helper";

function VehicleTable({ data, onClickRow }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Status</th>
          <th>Battery</th>
          <th>Tracking</th>
          <th>Last Seen</th>
          <th>Device</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index} onClick={() => onClickRow(item)}>
            <td>{item?.id}</td>
            <td>{item?.name}</td>
            <td>
              {DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                item?.last_ping
              ) < 1
                ? "Online"
                : "Offline"}
            </td>
            <td>{item?.battery}</td>
            <td>{item?.locationEnabled ? "On" : "Off"}</td>
            <td>
              {" "}
              {item?.last_ping
                ? DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                    item?.last_ping
                  ) < 1
                  ? "a while ago"
                  : DateTimeHelper.getTimeDifference(item?.last_ping)
                : DateTimeHelper.getTimeDifference(item?.date_created)}
            </td>
            <td>{item?.model}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default VehicleTable;
