import React from "react";
import styles from "./AddSettingJobField.module.css";
import { Modal } from "react-bootstrap";
import InputForm from "../InputForm/InputForm";
import Button from "../Button/Button";

function AddSettingJobField({
  show,
  onHide,
  heading,
  name,
  value,
  onChange,
  onAddClick,
  disabled,
  loading,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.modal}
    >
      <Modal.Body className={styles.modalBody}>
        <p>{heading}</p>
        <InputForm width="90%" name={name} value={value} onChange={onChange} />

        <Button
          loading={loading}
          width="30%"
          btnText="Add"
          onClick={onAddClick}
          disabled={disabled}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddSettingJobField;
