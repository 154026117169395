import React, { useEffect, useRef, useState } from "react";
import styles from "./AddNewJob.module.css";
import {
  AddProductSidebar,
  AutoComplete,
  AutoCompleteCustomer,
  Block,
  DropdownForm,
  FormPhoneInput,
  Header,
  ImagePOD,
  InputForm,
  ProductCard,
  ScrollLock,
} from "../../components";
import { Tasks } from "../../assets/svg";
import {
  ContactsService,
  ProductService,
  SettingsService,
  TasksService,
  VehiclesService,
} from "../../services";
import { useNavigate } from "react-router-dom";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { BiMailSend } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import Switch from "react-switch";
import { CountryHelpder, DateTimeHelper } from "../../helper";
import { LoadingContext, SocketContext } from "../../context";
import { ErrorList } from "../../constants";
import { uploadProductImages } from "../../services/delivery-tasks-service";

function AddNewJob() {
  const navigate = useNavigate();

  // context
  const { setLoading } = LoadingContext.useLoading();
  const { socket, connectSocket, disconnectSocket } = SocketContext.useSocket();

  // Create a reference to the hidden file input element
  const hiddenFileInput1 = useRef(null);
  const deliveryFileRef1 = useRef(null);
  const deliveryFileRef2 = useRef(null);
  const deliveryFileRef3 = useRef(null);

  const [vehicles, setVehicles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [newTask, setNewTask] = useState({
    pickup_address: "",
    pickup_address_lat: "",
    pickup_address_lng: "",
    dropoff_address: "",
    dropoff_address_lat: "",
    dropoff_address_lng: "",
    pickup_date: "",
    status: "Pending",
    payment_status: "",
    delivery_date: "",
    delivery_type: "",
    vehicle_id: null,
    recipient_name: "",
    recipient_phone_no: "",
    recipient_email: "",
    new_recipient: false,
    sender_name: "",
    sender_phone_no: "",
    sender_email: "",
    new_sender: false,
    save_to_contacts_recipient: false,
    save_to_contacts_sender: false,
    delivery_window: "",
    customer_id: "",
    order_id: "",
    delivery_vehicle_type: "",
    package_id: "",
    delivery_cost: "",
    delivery_instructions: "",
    podImg1: null,
    deliveryImg1: null,
    deliveryImg2: null,
    deliveryImg3: null,
    products: [],
  });
  const [country, setCountry] = useState();
  const [options, setOptions] = useState({
    selected_delivery_type: "Default Delivery Type" || "",
    delivery_type: [],
    payment_status: [],
    delivery_window: [],
  });
  const [errors, setErrors] = useState({});
  const [moreField, setMoreFields] = useState(false);
  const [open, setOpen] = useState(false);

  // --- Add products States -------

  const [products, setProducts] = useState([]);
  const [addProduct, setAddProduct] = useState({
    title: "",
    sku_id: "",
    price: "",
    dimensions: "",
    quantity: "",
    weight: "",
    productImage: null,
  });
  const [editProduct, setEditProduct] = useState({
    title: "",
    sku_id: "",
    price: "",
    dimensions: "",
    quantity: "",
    weight: "",
    productImage: null,
  });
  const [editingProductIndex, setEditingProductIndex] = useState(null);
  const [errorsProduct, setErrorsProduct] = useState({});
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    toastMessage: null,
    toastType: null,
  });
  // const [editProductDetails, setEditProductDetails] = useState();
  const [editMode, setEditMode] = useState(false);
  // const [deleteModal, setDeleteModal] = useState({
  //   show: false,
  //   id: null,
  // });
  const ProductSidebar = useRef();
  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    console.log(event.target.value, "value");

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };

  const handleSwitchChange = (name, value) => {
    setNewTask((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddressChange = (name, value) => {
    setNewTask((prev) => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleChangeImage = (name, event) => {
    setNewTask((prev) => ({ ...prev, [name]: event.target.files[0] }));
  };
  const handleChangeProductImage = (name, event) => {
    setAddProduct((prev) => ({
      ...prev,
      [name]: event.target.files[0],
    }));
  };

  const getTenantVehiclesAndContacts = async () => {
    const [country, vehicles, contacts, settings] = await Promise.all([
      CountryHelpder.fetchCountry(),
      VehiclesService.getVehicles(),
      ContactsService.getContacts(null, 1),
      SettingsService.getTenantAppSetting(),
    ]);
    const deliveryType = settings.filter(
      (entry) => entry.key === "default_delivery_type"
    );
    const vehicleType = settings.filter(
      (entry) => entry.key === "vehicle_default_type"
    );
    console.log({ deliveryType });
    setCountry(country);
    setVehicles(vehicles?.data);
    setContacts(contacts?.data);
    if (deliveryType?.length) {
      let deliveryOptionsJson = JSON.parse(deliveryType?.[0]?.value);

      const defaultDeliveryType = deliveryOptionsJson.find(
        (option) => option.is_default === true
      );
      console.log(defaultDeliveryType?.option);
      setOptions({
        delivery_type: JSON.parse(deliveryType?.[0]?.value) || [],
        selected_delivery_type: defaultDeliveryType,
        // delivery_window: settings?.[0]?.delivery_window || [],
        // payment_status: settings?.[0]?.payment_status || [],
      });

      // setNewTask({
      //   ...newTask,
      //   delivery_type: defaultDeliveryType,
      //   delivery_window: "",
      //   payment_status: "",
      // });
    }
    if (vehicleType?.length) {
      setNewTask({
        ...newTask,
        delivery_vehicle_type: vehicleType?.[0]?.value,
      });
    }
  };

  useEffect(() => {
    setLoading(true);

    getTenantVehiclesAndContacts()
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  // connect websocket
  useEffect(() => {
    if (!socket) connectSocket();

    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // send a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        // Send a message to the server
        VehiclesService.subscribeVehicleSocket(socket);

        const timer = setInterval(() => {
          VehiclesService.getVehiclesSocket(socket);
        }, 15000);

        return () => clearInterval(timer);
      }
    }, 3000);

    return () => {
      socket && VehiclesService.unSubscribeVehicleSocket(socket);
    };
  }, [socket]);

  // received a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.onmessage = (e) => {
          const parsed = JSON.parse(e.data);
          // console.log(parsed);

          if (parsed?.type === "RESPONSE") {
            setVehicles(parsed?.data);
          } else if (parsed?.type === "SUBSCRIPTION") {
            VehiclesService.getVehiclesSocket(socket);
          }

          if (parsed?.data?.length) {
            setVehicles(parsed?.data);
          }
        };
      }
    }, 3000);
  }, [socket]);

  const validateForm = () => {
    const newErrors = {};

    if (!newTask.pickup_address) {
      newErrors.pickup_address = "Pickup address is required";
    } else if (!newTask.dropoff_address) {
      newErrors.dropoff_address = "Dropoff address is required";
    } else if (!newTask.pickup_date) {
      newErrors.pickup_date = "Pickup date is required";
    } else if (!newTask.delivery_date) {
      newErrors.delivery_date = "Delivery date is required";
    } else if (!newTask.recipient_phone_no) {
      newErrors.recipient_phone_no = "Recipient phone no is required";
    }

    return newErrors;
  };
  const uploadImagesForProducts = async () => {
    try {
      // Extract product images from the productsArray
      const logos = products.map((product) => product.productImage);
      const names = products.map((product) => product.title);

      // Call the uploadProductImages function to upload the images
      const uploadedFilenames = await uploadProductImages(names, logos);

      console.log("Uploaded filenames:", uploadedFilenames);

      // Now you can associate these uploaded filenames with the respective products in your backend or wherever you need.
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };
  const addTask = (event) => {
    event.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // Merge the current products array into the newTask state
      const updatedTask = {
        ...newTask,
        products: [...newTask.products, ...products],
      };
      TasksService.addTask(updatedTask)
        .then(async (res) => {
          await uploadImagesForProducts();
          await saveToContacts();
          res?.data && navigate("/delivery-jobs");
        })
        .catch((err) => console.log(err));
    }
  };

  const saveToContacts = async () => {
    if (
      newTask.save_to_contacts_sender &&
      newTask.save_to_contacts_recipient &&
      newTask.new_recipient &&
      newTask.new_sender
    ) {
      await ContactsService.addContact({
        full_name: newTask?.sender_name,
        email: newTask?.sender_email,
        mobile_no: newTask?.sender_phone_no,
        home_address: newTask?.pickup_address,
        home_address_lat: newTask?.pickup_address_lat,
        home_address_lng: newTask?.pickup_address_lng,
        type: "Sender",
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));

      await ContactsService.addContact({
        full_name: newTask?.recipient_name,
        email: newTask?.recipient_email,
        mobile_no: newTask?.recipient_phone_no,
        home_address: newTask?.dropoff_address,
        home_address_lat: newTask?.dropoff_address_lat,
        home_address_lng: newTask?.dropoff_address_lng,
        type: "Recipient",
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));
    } else if (newTask.save_to_contacts_sender && newTask.new_sender) {
      await ContactsService.addContact({
        full_name: newTask?.sender_name,
        email: newTask?.sender_email,
        mobile_no: newTask?.sender_phone_no,
        home_address: newTask?.pickup_address,
        home_address_lat: newTask?.pickup_address_lat,
        home_address_lng: newTask?.pickup_address_lng,
        type: "Sender",
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));
    } else if (newTask.save_to_contacts_recipient && newTask.new_recipient) {
      await ContactsService.addContact({
        full_name: newTask?.recipient_name,
        email: newTask?.recipient_email,
        mobile_no: newTask?.recipient_phone_no,
        home_address: newTask?.dropoff_address,
        home_address_lat: newTask?.dropoff_address_lat,
        home_address_lng: newTask?.dropoff_address_lng,
        type: "Recipient",
      })
        .then((res) => console.log(res?.data))
        .catch((err) => console.log(err));
    }
  };

  //  ------ Add Products ---------------

  // useEffect(() => {
  //   setLoading(true);

  //   ProductService.getProducts()
  //     .then((data) => {
  //       setProducts(data);
  //       console.log(data, "getProducts");
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setLoading(false));
  // }, [setLoading]);

  // const validateAddDriver = () => {
  //   const { first_name, last_name, email, password } = addDriver;

  //   const newErrors = {};

  //   if (!email) {
  //     newErrors.email = "Email is required";
  //   } else if (!first_name) {
  //     newErrors.first_name = "First Name is required";
  //   } else if (!last_name) {
  //     newErrors.last_name = "Last Name is required";
  //   } else if (!password) {
  //     newErrors.password = "Password is required";
  //   }

  //   return newErrors;
  // };

  // add Product
  const onAddProducts = async () => {
    // event.preventDefault();

    setLoading(true);
    ProductService.addProducts(products)
      .then((res) => {
        // console.log(res, "addProductsss");
        setToastMessage({
          toastType: "success",
          toastMessage: res,
        });
        // setShow(true);
        // setOpen(false);

        // setAddProduct({
        //   title: "",
        //   sku_id: "",
        //   price: "",
        //   dimensions: "",
        //   quantity: "",
        //   // image,
        //   weight: "",
        // });

        // ProductService.getProducts()
        //   .then((data) => {
        //     console.log(data, "getProducts");
        //     setProducts(data);
        //   })
        //   .catch((err) => console.log(err));
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      })
      .finally(() => setLoading(false));
  };
  const onAddProductUI = () => {
    setProducts((prevProducts) => [...prevProducts, { ...addProduct }]);
    setAddProduct({
      title: "",
      sku_id: "",
      price: "",
      productImage: null,
      dimensions: "",
      quantity: "",
      weight: "",
    });
    setOpen(false);
  };
  const onEditProductUI = () => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, index) =>
        index === editingProductIndex ? { ...editProduct } : product
      );
      return updatedProducts;
    });

    setOpen(false);
    setEditProduct({
      title: "",
      sku_id: "",
      price: "",
      dimensions: "",
      quantity: "",
      weight: "",
      productImage: null,
    });
  };

  const removeProduct = (index) => {
    setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
  };

  useEffect(() => {
    console.log(products, "addProductsUI");
  }, [products]);
  // const onEditProducts = async (event) => {
  //   event.preventDefault();

  //   setLoading(true);
  //   ProductService.editProducts(editProduct)
  //     .then((res) => {
  //       setToastMessage({
  //         toastType: "success",
  //         toastMessage: res,
  //       });
  //       setShow(true);
  //       setOpen(false);
  //       setEditProduct({
  //         title: "",
  //         sku_id: "",
  //         price: "",
  //         dimensions: "",
  //         quantity: "",
  //         // image,
  //         weight: "",
  //         id: null,
  //       });

  //       ProductService.getProducts()
  //         .then((data) => {
  //           console.log(data, "getProducts");
  //           setProducts(data);
  //         })
  //         .catch((err) => console.log(err));
  //     })
  //     .catch((err) => {
  //       setToastMessage({
  //         toastType: "danger",
  //         toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
  //       });
  //       setShow(true);
  //     })
  //     .finally(() => setLoading(false));
  // };

  const handleChangeProducts = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    if (!!errorsProduct[event.target.name]) {
      setErrorsProduct({
        ...errorsProduct,
        [event.target.name]: null,
      });
    }
  };

  return (
    <>
      <Block style={{ padding: "0px 30px" }} withForm={true} onSubmit={addTask}>
        <Header icon={Tasks} title="Add Delivery Job" search={false} />

        <div className={`row ${styles.form}`}>
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-8">
                <InputForm
                  required={true}
                  type="autocomplete"
                  label="Pick up Address *"
                  name="pickup_address"
                  value={newTask.pickup_address}
                  onPlaceSelect={(place) => {
                    handleAddressChange(
                      "pickup_address",
                      place?.formatted_address
                    );
                    handleAddressChange(
                      "pickup_address_lat",
                      place?.geometry?.location.lat()
                    );
                    handleAddressChange(
                      "pickup_address_lng",
                      place?.geometry?.location.lng()
                    );
                  }}
                  lastIcon={<ImLocation2 size={20} />}
                  lastIconBackgroundColor="#000"
                  inValidMessage={errors.pickup_address}
                />

                <InputForm
                  required={true}
                  type="autocomplete"
                  label="Delivery Address *"
                  name="dropoff_address"
                  value={newTask.dropoff_address}
                  onPlaceSelect={(place) => {
                    handleAddressChange(
                      "dropoff_address",
                      place?.formatted_address
                    );
                    handleAddressChange(
                      "dropoff_address_lat",
                      place?.geometry?.location.lat()
                    );
                    handleAddressChange(
                      "dropoff_address_lng",
                      place?.geometry?.location.lng()
                    );
                  }}
                  lastIcon={<ImLocation2 size={20} />}
                  lastIconBackgroundColor={"#F7F7F7"}
                  inValidMessage={errors.dropoff_address}
                />
                {/* 
              <InputForm
                required={true}
                label="Status *"
                name="status"
                disabled={true}
                value={newTask.status}
                onChange={handleChange(setNewTask)}
              />
              <DropdownForm
                label="Select Payment Status"
                name="payment_status"
                value={newTask.payment_status}
                options={options?.payment_status?.map(
                  (item) => item?.tenant_payment_status
                )}
                onChange={handleChange(setNewTask)}
              /> */}
                <AutoCompleteCustomer
                  options={contacts}
                  label="Customer Name "
                  lastIcon={<FaUser size={20} />}
                  iconColor={"#888888"}
                  onSelect={(e) => {
                    setNewTask({
                      ...newTask,
                      recipient_name: e?.full_name,
                      recipient_email: e?.email,
                      recipient_phone_no: e?.mobile_no,
                      new_recipient: e?.new,
                      save_to_contacts_recipient: e?.new ? true : false,
                    });
                  }}
                  inValidMessage={errors.recipient_name}
                />
                <InputForm
                  // lastIcon={<BiMailSend size={20} />}
                  label="Customer Email Address"
                  name="recipient_email"
                  value={newTask.recipient_email}
                  onChange={handleChange(setNewTask)}
                />
                <InputForm
                  label="Order ID"
                  name="order_id"
                  value={newTask.order_id}
                  onChange={handleChange(setNewTask)}
                />
              </div>

              <div className="col-sm-4">
                <InputForm
                  // required={true}
                  label="Pick up Date & Time *"
                  type="datetime-local"
                  name="pickup_date"
                  min={DateTimeHelper.minimumDate()}
                  value={newTask.pickup_date}
                  onChange={handleChange(setNewTask)}
                  // inValidMessage={errors.pickup_date}
                />
                <InputForm
                  required={true}
                  label="Delivery Date & Time *"
                  type="datetime-local"
                  name="delivery_date"
                  min={newTask?.pickup_date || DateTimeHelper.minimumDate()}
                  value={newTask.delivery_date}
                  onChange={handleChange(setNewTask)}
                  inValidMessage={errors.delivery_date}
                />
                <FormPhoneInput
                  validated={true}
                  label="Customer Phone Number *"
                  name="recipient_phone_no"
                  country={country}
                  value={newTask.recipient_phone_no}
                  onChange={(e) =>
                    setNewTask({ ...newTask, recipient_phone_no: e })
                  }
                  inValidMessage={errors.recipient_phone_no}
                />
                {/* <div className="row"> */}
                {/* <div className="col-sm-6"> */}
                <label className={styles.switch}>
                  <Switch
                    checked={newTask.save_to_contacts_recipient}
                    onChange={(e) =>
                      handleSwitchChange("save_to_contacts_recipient", e)
                    }
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#000"
                    onHandleColor="#fff"
                    // disabled={!newTask.new_recipient}
                  />
                  <span>Save to contacts</span>
                </label>

                <AutoComplete
                  label="Assign Vehicle"
                  options={vehicles}
                  name="vehicle_id"
                  value={newTask.vehicle_id}
                  onSelect={(item) =>
                    setNewTask({
                      ...newTask,
                      vehicle_id: item?.[0]?.id,
                      status: item?.[0]?.id ? "Assigned" : "Pending",
                      delivery_vehicle_type: item?.[0]?.type,
                    })
                  }
                />
              </div>
            </div>

            <div className={`d-flex ${styles.productCardView}`}>
              <ProductCard
                data={products}
                onRemove={removeProduct}
                onClickCard={(index) => {
                  setOpen(true);
                  setEditMode(true);
                  setEditingProductIndex(index);
                  const productToEdit = products[index];
                  // setEditProduct({ ...productToEdit });
                  setEditProduct({
                    title: productToEdit.title,
                    sku_id: productToEdit.sku_id,
                    price: productToEdit.price,
                    dimensions: productToEdit.dimensions,
                    quantity: productToEdit.quantity,
                    weight: productToEdit.weight,
                    productImage: productToEdit.productImage,
                  });
                  console.log(productToEdit, "productToEdit");
                }}
              />
            </div>

            <Button
              variant="dark"
              onClick={() => {
                setEditMode(false);
                setAddProduct({ ...addProduct });
                setOpen(true);
              }}
              // type="submit"
              className={styles.addProduct}
              // style={{ marginLeft: 10, marginRight: 10 }}
            >
              + Add Product
            </Button>
            <div className="col-sm-12">
              <InputForm
                label="Special Instructions"
                name="delivery_instructions"
                type="multiline"
                value={newTask.delivery_instructions}
                onChange={handleChange(setNewTask)}
              />
            </div>
            <span
              onClick={() => setMoreFields(!moreField)}
              className={styles.showMore}
            >
              {!moreField ? "Show more fields" : ""}
            </span>
            {moreField && (
              <div className={`row `}>
                <div className={`col-sm-6 `}>
                  <DropdownForm
                    options={options?.delivery_type?.map(
                      (item) => item?.option
                    )}
                    // disabled={true}
                    label="Delivery Type"
                    name="delivery_type"
                    value={options?.selected_delivery_type?.option}
                    onChange={handleChange(setNewTask)}
                  />
                  <AutoCompleteCustomer
                    options={contacts}
                    lastIcon={<FaUser size={20} />}
                    iconColor={"#888888"}
                    label="Sender Full Name "
                    onSelect={(e) => {
                      setNewTask({
                        ...newTask,
                        sender_name: e?.full_name,
                        sender_email: e?.email,
                        sender_phone_no: e?.mobile_no,
                        new_sender: e?.new,
                        save_to_contacts_sender: e?.new ? true : false,
                      });
                    }}
                    inValidMessage={errors.sender_name}
                  />
                  <InputForm
                    lastIcon={<BiMailSend size={20} />}
                    label="Sender Email Address"
                    type="email"
                    name="sender_email"
                    value={newTask.sender_email}
                    onChange={handleChange(setNewTask)}
                  />
                  <InputForm
                    label="Customer ID"
                    name="customer_id"
                    value={newTask.customer_id}
                    onChange={handleChange(setNewTask)}
                  />
                </div>
                <div className="col-sm-6">
                  <InputForm
                    disabled={true}
                    label="Delivery Vehicle Type"
                    name="delivery_vehicle_type"
                    value={newTask.delivery_vehicle_type}
                    onChange={handleChange(setNewTask)}
                  />
                  <FormPhoneInput
                    required={true}
                    label="Sender Phone Number "
                    name="sender_phone_no"
                    country={country}
                    value={newTask.sender_phone_no}
                    onChange={(e) =>
                      setNewTask({ ...newTask, sender_phone_no: e })
                    }
                    inValidMessage={errors.sender_phone_no}
                  />
                  <label className={styles.switch}>
                    <Switch
                      onChange={(e) =>
                        handleSwitchChange("save_to_contacts_sender", e)
                      }
                      checked={newTask.save_to_contacts_sender}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#000"
                      onHandleColor="#fff"
                      // disabled={!newTask.new_sender}
                    />
                    <span>Save to contacts</span>
                  </label>
                  <InputForm
                    label="Package ID"
                    name="package_id"
                    value={newTask.package_id}
                    onChange={handleChange(setNewTask)}
                  />
                </div>
              </div>
            )}
            {/* <div className="row">
            <div className="col-sm-6">
              <ImagePOD
                label="Attach proof of delivery"
                hiddenFileInputRef={hiddenFileInput1}
                onClick={() => hiddenFileInput1.current.click()}
                onChange={(e) => handleChangeImage("podImg1", e)}
                img={newTask.podImg1}
              />
              <DropdownForm
                label="Select Delivery Type"
                options={options?.delivery_type?.map(
                  (item) => item?.tenant_delivery_type
                )}
                name="delivery_type"
                value={newTask.delivery_type}
                onChange={handleChange(setNewTask)}
              />
            </div>
            <div className="col-sm-6">
              <div className={styles.row}>
                <ImagePOD
                  width="32%"
                  label="Attach delivery photos"
                  hiddenFileInputRef={deliveryFileRef1}
                  onClick={() => deliveryFileRef1.current.click()}
                  onChange={(e) => handleChangeImage("deliveryImg1", e)}
                  img={newTask.deliveryImg1}
                />
                <ImagePOD
                  width="32%"
                  label="Attach delivery photos"
                  hiddenFileInputRef={deliveryFileRef2}
                  onClick={() => deliveryFileRef2.current.click()}
                  onChange={(e) => handleChangeImage("deliveryImg2", e)}
                  img={newTask.deliveryImg2}
                />
                <ImagePOD
                  width="32%"
                  label="Attach delivery photos"
                  hiddenFileInputRef={deliveryFileRef3}
                  onClick={() => deliveryFileRef3.current.click()}
                  onChange={(e) => handleChangeImage("deliveryImg3", e)}
                  img={newTask.deliveryImg3}
                />
              </div>

              <InputForm
                label="Delivery Cost"
                name="delivery_cost"
                value={newTask.delivery_cost}
                onChange={handleChange(setNewTask)}
              />
            </div>
          </div> */}
          </div>
        </div>
        <div className={` ${styles.btnContainer}`}>
          <div className="d-flex">
            <Button
              variant="dark"
              type="submit"
              className={styles.submit}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              Submit
            </Button>

            <Button
              onClick={() => window.location.reload()}
              className={`${styles.submit} `}
              style={{ backgroundColor: "#E8E8E8", color: "#000" }}
              variant="dark"
            >
              Reset
            </Button>
          </div>
        </div>

        <ScrollLock isLocked={open} />
      </Block>
      {open && (
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="overlay"
        />
      )}
      <AddProductSidebar
        hiddenFileInputRef={hiddenFileInput1}
        onClickToAddImage={() => hiddenFileInput1.current.click()}
        onChangeImage={(e) => handleChangeProductImage("productImage", e)}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        item={products}
        onAddClick={editMode ? onEditProductUI : onAddProductUI}
        // onAddClick={onAddProductUI}
        errors={errorsProduct}
        onChange={
          editMode
            ? handleChangeProducts(setEditProduct)
            : handleChangeProducts(setAddProduct)
        }
        // onChange={handleChangeProducts(setAddProduct)}
        product={editMode ? editProduct : addProduct}
        // product={addProduct}
        mode={editMode}
        img={addProduct.productImage}
      />
      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg={toastMessage?.toastType || "success"}
          onClose={() => {
            setShow(false);
            setToastMessage({ toastMessage: null });
          }}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Body className={"text-white"}>
            {toastMessage?.toastMessage || "Copied to clipboard"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default AddNewJob;
