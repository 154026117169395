import React, { useEffect, useState } from "react";
import styles from "./TasksSidebarDetails.module.css";
import { RxCross2 } from "react-icons/rx";
import { HiPhotograph } from "react-icons/hi";
import { API } from "../../utils/api";
import TaskDetailsMap from "../TaskDetailsMap/TaskDetailsMap";
import DeliveryStatus from "../DeliveryStatus/DeliveryStatus";
import { DateTimeHelper, TaskHelper } from "../../helper";
import { TasksService } from "../../services";
import { LoadingContext } from "../../context";

function TasksSidebarDetails({ item, isOpen, onClose }) {
  const [deliveryHistory, setDeliveryHistory] = useState([]);
  console.log(item, "gettingDeliveryItems");
  // const [loading, setLoading] = useState(false);
  // const [uniqueStatuses, setUniqueStatuses] = useState(new Set());
  const { setLoading } = LoadingContext.useLoading();
  const centerLat =
    (Number(item?.pickup_address_lat) + Number(item?.dropoff_address_lat)) / 2;
  const centerLng =
    (Number(item?.pickup_address_lng) + Number(item?.dropoff_address_lng)) / 2;
  const getDeliveryHistory = () => {
    setLoading(true);

    if (item && item.id) {
      TasksService.getDeliveryHistory(item.id)
        .then((res) => {
          let response = res?.data?.delivery_history;
          console.log(response, "getDeliveryHistory");
          setDeliveryHistory(response);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getDeliveryHistory();
  }, [item?.id]);
  // Trigger the API call when the item ID changes

  return (
    <div
      className={`${styles.sidebarContainer} ${
        isOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <RxCross2 size={22} className={styles.cross} onClick={onClose} />
      <div className={styles.nameRow}>
        <label>Job ID:</label>
        <span>{item?.id}</span>
      </div>
      <div className={styles.statusRow}>
        <label>Status:</label>
        <span>
          <DeliveryStatus status={item?.status}></DeliveryStatus>
        </span>
      </div>
      <div className={styles.locationArea}>
        <TaskDetailsMap
          lat={centerLat}
          lng={centerLng}
          latCar1={Number(item?.pickup_address_lat)}
          lngCar1={Number(item?.pickup_address_lng)}
          latCar2={Number(item?.dropoff_address_lat)}
          lngCar2={Number(item?.dropoff_address_lng)}
        />
      </div>
      <div className={styles.field}>
        <label>Pickup Address</label>
        <span>{item?.pickup_address}</span>
      </div>
      <div className={styles.field}>
        <label>Dropoff Address</label>
        <span>{item?.dropoff_address}</span>
      </div>
      <div className={styles.field}>
        <label>Pickup Time</label>
        <span>{`${DateTimeHelper.formatDate(
          item?.pickup_date
        )} - ${DateTimeHelper.formatTime(item?.pickup_date)}`}</span>
      </div>
      <div className={styles.field}>
        <label>Dropoff Time</label>
        <span>{`${DateTimeHelper.formatDate(
          item?.delivery_date
        )} - ${DateTimeHelper.formatTime(item?.delivery_date)}`}</span>
      </div>
      <div className={styles.field}>
        <label>Payment Status</label>
        <span>{item?.payment_status}</span>
      </div>
      <div className={styles.field}>
        <label>Recipient Name</label>
        <span>{item?.recipient_name}</span>
      </div>
      <div className={styles.field}>
        <label>Vehcile</label>
        <span>{item?.vehicle_id}</span>
      </div>
      <div className={styles.field}>
        <label>Recipient Phone No</label>
        <span>{item?.recipient_phone_no}</span>
      </div>
      <div className={styles.field}>
        <label>Recipient Email</label>
        <span>{item?.recipient_email}</span>
      </div>
      <div className={styles.field}>
        <label>Sender Name</label>
        <span>{item?.sender_name}</span>
      </div>
      <div className={styles.field}>
        <label>Sender Phone No</label>
        <span>{item?.sender_phone_no}</span>
      </div>
      <div className={styles.field}>
        <label>Sender Email</label>
        <span>{item?.sender_email}</span>
      </div>
      <div className={styles.field}>
        <label>Delivery Window</label>
        <span>{item?.delivery_window}</span>
      </div>
      <div className={styles.field}>
        <label>Delivery Vehicle Type</label>
        <span>
          {item?.delivery_vehicle_type &&
            item?.delivery_vehicle_type?.charAt(0)?.toUpperCase() +
              item?.delivery_vehicle_type?.slice(1)}
        </span>
      </div>
      <div className={styles.field}>
        <label>Delivery Cost</label>
        <span>{item?.delivery_cost}</span>
      </div>
      <div className={styles.field}>
        <label>Delivery Instructions</label>
        <span>{item?.delivery_instructions}</span>
      </div>
      <div className={styles.field}>
        <label>POD</label>

        <>
          <div className={styles.imgCard}>
            {!item?.signature ? (
              <HiPhotograph size={40} />
            ) : (
              <img src={`${API.assets}/${item?.signature}`} alt="pod" />
            )}
          </div>
          <div className={styles.imgCard}>
            {!item?.podImg1 ? (
              <HiPhotograph size={40} />
            ) : (
              <img src={`${API.assets}/${item?.podImg1}`} alt="pod" />
            )}
          </div>
          <div className={styles.imgCard}>
            {!item?.podImg2 ? (
              <HiPhotograph size={40} />
            ) : (
              <img src={`${API.assets}/${item?.podImg2}`} alt="pod" />
            )}
          </div>
          <div className={styles.imgCard}>
            {!item?.podImg3 ? (
              <HiPhotograph size={40} />
            ) : (
              <img src={`${API.assets}/${item?.podImg3}`} alt="pod" />
            )}
          </div>
        </>
      </div>
      <div className={styles.deliveryArea}>
        <label>Delivery Status</label>

        {/* <div className={styles.deliveryField}>
          <label>Picked up:</label>
          <span>
            {item?.driver_pickup_date &&
              `${DateTimeHelper.formatDate(
                item?.driver_pickup_date
              )} - ${DateTimeHelper.formatTime(item?.driver_pickup_date)}`}
          </span>
        </div>

        <div className={styles.deliveryField}>
          <label>Arrived:</label>
          <span>
            {item?.driver_dropoff_time &&
              `${DateTimeHelper.formatDate(
                item?.driver_dropoff_time
              )} - ${DateTimeHelper.formatTime(item?.driver_dropoff_time)}`}
          </span>
        </div>

        <div className={styles.deliveryField}>
          <label>ePOD</label>
          <span>
            {item?.e_pod_time &&
              `${DateTimeHelper.formatDate(
                item?.e_pod_time
              )} - ${DateTimeHelper.formatTime(item?.e_pod_time)}`}
          </span>
        </div>

        <div className={styles.deliveryField}>
          <label>Delivered</label>
          <span>
            {item?.delivered_time &&
              `${DateTimeHelper.formatDate(
                item?.delivered_time
              )} - ${DateTimeHelper.formatTime(item?.delivered_time)}`}
          </span>
        </div> */}

        <div>
          {deliveryHistory
            .filter(
              (historyItem, index, self) =>
                index ===
                self.findIndex(
                  (t) =>
                    t.status === historyItem.status &&
                    t.date_created === historyItem.date_created
                )
            )
            .sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
            .map((uniqueHistoryItem) => (
              <div className={styles.deliveryField} key={uniqueHistoryItem.id}>
                {uniqueHistoryItem.status &&
                  uniqueHistoryItem.status !== "undefined" && (
                    <>
                      <label>{uniqueHistoryItem.status}</label>
                      <span>
                        {uniqueHistoryItem.date_created &&
                          `${DateTimeHelper.formatDate(
                            uniqueHistoryItem.date_created
                          )} - ${DateTimeHelper.formatTime(
                            uniqueHistoryItem.date_created
                          )}`}
                      </span>
                    </>
                  )}
              </div>
            ))}
        </div>

        {item?.status === "Cancelled" ? (
          <>
            <div className={styles.deliveryField}>
              <label>Cancelled</label>
              <span>
                {item?.delivered_time &&
                  `${DateTimeHelper.formatDate(
                    item?.delivered_time
                  )} - ${DateTimeHelper.formatTime(item?.delivered_time)}`}
              </span>
            </div>

            <div className={styles.deliveryField}>
              <label>Cancelled Reason</label>
              <span>
                {item?.reason_of_cancelled &&
                  TaskHelper.cancelledReasons(item?.reason_of_cancelled)}
              </span>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TasksSidebarDetails;
