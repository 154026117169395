import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import styles from "./FormPhoneInput.module.css";

function FormPhoneInput({
  label,
  type,
  name,
  value,
  onChange,
  required,
  inValidMessage,
  country,
}) {
  const isInvalid = !!inValidMessage; // Determine if there's an error message

  return (
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label className={styles.label}>{label}</Form.Label>

      <InputGroup>
        <PhoneInput
          containerClass={`${styles.input} ${isInvalid ? "is-invalid" : ""} ${
            isInvalid ? styles.is_invalid : null
          }`}
          inputClass={`${styles.inputClass} ${isInvalid ? "is-invalid" : ""}`}
          buttonClass={styles.buttonClass}
          international
          country={country?.toLowerCase() || "us"}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          inputProps={{ required }}
          isInvalid={!!inValidMessage}
        />
        {!!inValidMessage ? (
          <Form.Control.Feedback type="invalid">
            {inValidMessage}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Fied is required
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  );
}

export default FormPhoneInput;
