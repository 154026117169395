import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { appRoutes } from "../../routes";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import MenuItem from "../MenuItem/MenuItem";
import { MonkeyFace, Logo } from "../../assets/svg";
import {
  IoLogOutSharp,
  IoArrowForwardCircle,
  IoArrowBackCircleSharp,
} from "react-icons/io5";
import { AuthService } from "../../services";
import { AuthContext, SidebarContext, SocketContext } from "../../context";
import { RolesHelper } from "../../constants";

function Sidebar() {
  const location = useLocation();
  const { toggleSidebar } = SidebarContext.useSidebar();
  const { logout, user } = AuthContext.useAuth();
  const { disconnectSocket } = SocketContext.useSocket();

  const [isOpen, setIsOpen] = useState(true);

  const onLogout = async () => {
    const userString = await localStorage.getItem("user");
    const user = JSON.parse(userString);

    AuthService.logout(user?.data?.refresh_token)
      .then(() => {
        disconnectSocket();
        window.location.reload();
      })
      .catch((err) => console.log(err));

    logout();
  };

  return (
    <div
      className={`${styles.sidebarContainer} ${
        isOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
      // onClick={() => setIsOpen(true)}
      // onMouseLeave={() => setIsOpen(false)}
    >
      <div className={styles.logoContainer}>
        {isOpen ? (
          <CSSTransition
            in={isOpen}
            timeout={1000}
            classNames={"fade"}
            unmountOnExit
          >
            <Logo className={styles.logo} />
          </CSSTransition>
        ) : (
          <MonkeyFace className={styles.monkeyLogo} />
        )}
      </div>
      {appRoutes?.routes?.map((item, index) => {
        const role = RolesHelper.getUserRole(user, item?.roles);

        if (!item.menu || !role) return null;
        const active =
          location?.pathname?.split("/", 2)[1] === item?.path.split("/", 2)[1];

        return (
          <MenuItem
            key={index}
            isOpen={isOpen}
            active={active}
            path={item?.path}
            icon={item?.icon}
            label={item?.name}
          />
        );
      })}

      {!isOpen ? (
        <div className={styles.logoutClose} onClick={onLogout}>
          <IoLogOutSharp size={27} onClick={onLogout} />
        </div>
      ) : (
        <div className={styles.logoout} onClick={onLogout}>
          <IoLogOutSharp size={27} />
          <span>Logout</span>
        </div>
      )}

      {!isOpen ? (
        <div className={styles.forwardIcon}>
          <IoArrowForwardCircle
            size={25}
            color="#000"
            onClick={() => {
              setIsOpen(true);
              toggleSidebar();
            }}
          />
        </div>
      ) : (
        <div className={styles.backIcon}>
          <IoArrowBackCircleSharp
            size={25}
            onClick={() => {
              setIsOpen(false);
              toggleSidebar();
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Sidebar;
