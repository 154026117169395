import React, { useEffect, useRef, useState } from "react";
import {
  Block,
  Header,
  ImportModal,
  NoData,
  TasksSidebarDetails,
  TasksTable,
  Tabs,
  Pagination,
  LoadingSpinner,
  ConfirmationAlert,
  ScrollLock,
  TrialEnd,
} from "../../components";
import { Deliveries } from "../../assets/svg";
import { AuthService, SubscriptionService, TasksService } from "../../services";
import { LoadingContext, SocketContext } from "../../context";
import { DateTimeHelper } from "../../helper";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Geocode from "react-geocode";
import styles from "./DeliveryJobs.module.css";
import { getSubscriptionByTenantId } from "../../services/subscription-service";
import { SubscriptionId } from "../../constants";

Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);
Geocode.setLanguage("en");

function DeliveryJobs() {
  const navigate = useNavigate();

  // contexts
  const { setLoading } = LoadingContext.useLoading();
  const { socket, connectSocket, disconnectSocket } = SocketContext.useSocket();

  // ref
  const deliverySidebar = useRef();
  const deliveryJobsRef = useRef();

  // states
  const [tasks, setTasks] = useState([]);
  const [count, setCount] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [totalPages, setTotalPages] = useState(null);

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState();
  const [errorIndex, setErrorIndex] = useState();
  const [viewStyle, setViewStyle] = useState("list");
  const [open, setOpen] = useState(false);
  const [deiveryTaskDetails, setDeliveryTaskDetails] = useState();
  const [batchData, setBatchData] = useState();
  const [search, setSearch] = useState();
  const [tab, setTab] = useState("All");
  const [page, setPage] = useState(1);
  const [tabLoading, setTabLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
  });
  const [trialRemaining, setTrialRemaining] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const fetchSubscription = async () => {
    try {
      const { subscription } = await getSubscriptionByTenantId();
      subscription && setSubscriptionDetails(subscription);
      console.log(subscription, "subscriptionDetails");
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };
  useEffect(() => {
    fetchSubscription();
    SubscriptionService.trialRemainingDays()
      .then((res) => {
        setTrialRemaining(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setTabLoading(true);

    TasksService.getTasks(search, tab, page)
      .then((data) => {
        setTasks(data?.data);
        setTotalPages(data?.total_pages);
        deliveryJobsRef.current = data?.data;
      })
      .catch((err) => console.log(err))
      .finally(() => setTabLoading(false));
  }, [search, tab, page]);

  useEffect(() => {
    setLoading(true);

    getAll().finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewStyle]);

  // connect websocket
  useEffect(() => {
    if (!socket) connectSocket();

    return () => {
      disconnectSocket();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // send a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        // Send a message to the server
        TasksService.subscribeTaskSocket(socket);
      }
    }, 1000);

    return () => {
      socket && TasksService.unSubscribeTaskSocket(socket);
    };
  }, [socket]);

  // received a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.onmessage = (e) => {
          const parsed = JSON.parse(e.data);

          if (parsed?.type === "SUBSCRIPTION") {
            // update sidebar details
            parsed?.keys?.[0] === deliverySidebar?.current?.id &&
              setDeliveryTaskDetails(parsed?.payload?.[0]);

            // update tabs
            getCount();

            // update vehicle which has updated
            const matched = deliveryJobsRef?.current?.map((item) => {
              if (item.id === parsed?.keys[0]) {
                item = parsed?.payload[0];

                const tabData = count?.filter(
                  (tab) => tab.status === parsed?.payload[0]?.status
                );

                tabData?.length === 0 && setTab("All");
              }

              return item;
            });

            setTasks(matched);
          }
        };
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const getAll = async () => {
    const [data, count] = await Promise.all([
      TasksService.getTasks(null, null, page),

      TasksService.getDeliveryTabs(),
    ]);

    deliveryJobsRef.current = data?.data;
    setTasks(data?.data);
    setTotalCount(data?.total_count);
    setTotalPages(data?.total_pages);
    setCount(count);
  };

  const getCount = () => {
    TasksService.getDeliveryTabs()
      .then((res) => setCount(res))
      .catch((err) => console.log(err));
  };

  const getBatchTask = () => {
    TasksService.getBatchTask()
      .then((data) => setBatchData(data))
      .catch((err) => console.log(err));
  };

  const addImportTask = async (importedCsv, file_name) => {
    let validation = null;
    let validationIndex = null;
    let pickupLatLng, dropoffLatLng;
    const updatedCsv = [];
    const { tenant_id } = await AuthService.getToken();

    for (const ele of importedCsv) {
      const index = importedCsv.indexOf(ele);
      // Field empty validation
      if (!ele.pickup_location) {
        validation = "pickup_location is required";
        validationIndex = index;
        validationIndex = index;
        break;
      }
      if (!ele.dropoff_location) {
        validation = "dropoff_location is required";
        validationIndex = index;
        break;
      }
      if (!ele.pickup_datetime) {
        validation = "pickup_datetime is required";
        validationIndex = index;
        break;
      }
      if (!ele.dropoff_datetime) {
        validation = "dropoff_datetime is required";
        validationIndex = index;
        break;
      }

      // Field datatype validation
      if (typeof ele.pickup_location !== "string") {
        validation = "Invalid value provided for pickup_location";
        validationIndex = index;
        break;
      }
      if (typeof ele.dropoff_location !== "string") {
        validation = "Invalid value provided for dropoff_location";
        validationIndex = index;
        break;
      }
      if (!DateTimeHelper.isExcelDateFormatValid(ele.pickup_datetime)) {
        validation = "pickup_datetime should be in format DD:MM:YYYY";
        validationIndex = index;
        break;
      }
      if (!DateTimeHelper.isExcelDateFormatValid(ele.dropoff_datetime)) {
        validation = "dropoff_datetime should be in format DD:MM:YYYY";
        validationIndex = index;
        break;
      }
      if (
        DateTimeHelper.excelDateToJSDate(ele.pickup_datetime) <
        new Date().toISOString().split("T")[0]
      ) {
        validation = "pickup_datetime should be greater than today's date";
        validationIndex = index;
        break;
      }
      if (
        DateTimeHelper.excelDateToJSDate(ele.dropoff_datetime) <
        new Date().toISOString().split("T")[0]
      ) {
        validation = "dropoff_datetime should be greater than today's date";
        validationIndex = index;
        break;
      }

      if (!ele.pickup_latitude || !ele.pickup_longitude) {
        pickupLatLng = await getLatLngFromAddress(ele.pickup_location);
      }

      if (!ele.dropoff_latitude || !ele.dropoff_longitude) {
        dropoffLatLng = await getLatLngFromAddress(ele.dropoff_location);
      }

      updatedCsv.push({
        pickup_address: ele.pickup_location,
        pickup_address_lat: ele.pickup_latitude || pickupLatLng?.lat,
        pickup_address_lng: ele.pickup_longitude || pickupLatLng?.lng,
        dropoff_address: ele.dropoff_location,
        dropoff_address_lat: ele.dropoff_latitude || dropoffLatLng?.lat,
        dropoff_address_lng: ele.dropoff_longitude || dropoffLatLng?.lng,
        pickup_date: DateTimeHelper.excelDateToJSDate(ele.pickup_datetime),
        delivery_date: DateTimeHelper.excelDateToJSDate(ele.dropoff_datetime),
        recipient_name: ele.recipient_name,
        recipient_phone_no: ele.recipient_phone_number,
        recipient_email: ele.recipient_email,
        recipient_save_to_contact: ele.recipient_save_to_contact,
        contact: ele.contact,
        sender_name: ele.sender_name,
        sender_phone_no: ele.sender_phone_number,
        sender_email: ele.sender_email,
        sender_save_to_contact: ele.sender_save_to_contact,
        payment_status: ele.payment_status,
        delivery_type: ele.delivery_type,
        customer_id: ele.customer_id,
        tenant_id,
        status: "Pending",
      });
    }

    if (!validation) {
      TasksService.addImportTasks(updatedCsv, file_name)
        .then((res) => {
          if (res?.data) {
            getAll();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setError();
          setErrorIndex();
          // getBatchTask();
        });
    } else {
      setError(validation);
      setErrorIndex(validationIndex);
    }
  };

  const getLatLngFromAddress = async (address) => {
    return await Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response?.results?.[0]?.geometry?.location;
        return { lat, lng };
      },
      (error) => {
        console.log(error);
        return error;
      }
    );
  };

  const onCheck = (check, item) => {
    const items = tasks;
    const itemArray = items?.map((arrItem) => {
      if (arrItem.id === item.id) {
        arrItem.check = check;
      }
      return arrItem;
    });

    // eslint-disable-next-line array-callback-return
    const checked = itemArray?.filter((item) => {
      if (item.check) return item;
    });

    setSelectedTasks(checked);
  };

  const onDeleteTaks = () => {
    setLoading(true);

    TasksService.deleteTask(deleteModal?.id)
      .then(() => getAll())
      .catch((err) => console.log(err))
      .finally(() => {
        setDeleteModal({ show: false, id: null });
        setLoading(false);
      });
  };

  return (
    <>
      <Block style={{ padding: `0px ${isMobile ? "0px" : "25px"}` }}>
        <Header
          icon={Deliveries}
          title="Deliveries"
          searchMargin="0px 0px 0px 50px"
          btnText="+ Add"
          onAddClick={(event) => {
            event.preventDefault();
            navigate("add-new-job");
          }}
          searchValue={search}
          onChangeSearch={(e) => setSearch(e?.target?.value)}
          exportBtn={true}
          exportData={selectedTasks}
          exportAllData={tasks}
          exportFile="Delivery Tasks"
          importBtn={true}
          onImport={() => navigate("batch-upload-delivery-tasks")}
          actionBtn={false}
          viewStyles={false}
          viewStylesColor={viewStyle}
          onChangView={(e) => setViewStyle(e)}
        />

        <ImportModal
          show={modal}
          batchData={batchData}
          setImportedCSV={(csv, file_name, allHeaders, missingHeaders) => {
            if (allHeaders) {
              addImportTask(csv, file_name);

              setTimeout(() => {
                error === "" && window.location.reload();
              }, 300);
            } else {
              setError(`${missingHeaders} is missing`);

              setTimeout(() => {
                setError();
              }, 5000);
            }
          }}
          error={error}
          errorIndex={errorIndex}
          onHide={() => setModal(false)}
        />

        <Tabs
          allCount={totalCount}
          activeKey={tab}
          tabs={count}
          onSelect={(e) => setTab(e)}
        />

        {tabLoading ? (
          <LoadingSpinner componentLoading={tabLoading} />
        ) : (
          viewStyle === "list" && (
            <>
              <TasksTable
                data={tasks}
                check={tasks?.check}
                onCheck={onCheck}
                onClickRow={(item) => {
                  setOpen(true);
                  setDeliveryTaskDetails(item);
                  deliverySidebar.current = item;
                }}
                onDispatch={(item) =>
                  navigate("assign-driver", { state: { jobDetails: item } })
                }
                onClickDelete={(item) =>
                  setDeleteModal({ show: true, id: item?.id })
                }
                onClickEdit={(item) =>
                  navigate("edit-job", { state: { jobDetails: item } })
                }
              />
              {console.log(page, totalPages, "pageCulation")}
              {tasks?.length > 10 || page > 1 ? (
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onPrev={() => page > 1 && setPage(page - 1)}
                  onNext={() => page < totalPages && setPage(page + 1)}
                />
              ) : null}
            </>
          )
        )}

        <ConfirmationAlert
          message="Are you sure you want to delete?"
          show={deleteModal?.show}
          onDone={onDeleteTaks}
          onClose={() => setDeleteModal({ show: false, id: null })}
        />

        {!tasks?.length && page === 1 && <NoData />}

        <ScrollLock isLocked={open} />
      </Block>

      {open && <div className="overlay" />}

      <TasksSidebarDetails
        isOpen={open}
        onClose={() => setOpen(false)}
        item={deiveryTaskDetails}
      />
      {trialRemaining < 1 &&
      subscriptionDetails?.subscription_plan_id?.name === "Trial" ? (
        <div className={styles.trial_end}>
          <TrialEnd />
        </div>
      ) : null}
    </>
  );
}

export default DeliveryJobs;
