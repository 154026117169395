import React, { useEffect, useState } from "react";
import styles from "./AssignOrder.module.css";
import {
  AssignOrderMap,
  Block,
  Button,
  DriverCard,
  Header,
} from "../../components";
import HeaderIcon from "../../assets/img/truck.png";
import search from "../../assets/svg/search.svg";
import { isMobile } from "react-device-detect";
import { TasksService, VehiclesService } from "../../services";
import { LoadingContext, SocketContext } from "../../context";
import { useLocation, useNavigate } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { BsCircle } from "react-icons/bs";

function AssignOrder() {
  const navigate = useNavigate();

  // contexts
  const { setLoading } = LoadingContext.useLoading();
  const { socket, connectSocket, disconnectSocket } = SocketContext.useSocket();

  // hooks
  const location = useLocation(); // Access the location object
  const { jobDetails } = location.state; // Access the passed data from the "state" property

  // states
  const [selected, setSelected] = useState({
    vehicle_id: null,
    vehicle_type: "",
  });
  const [vehicles, setVehicles] = useState();
  const [searchVehicle, setSearchVehicle] = useState();

  // connect websocket
  useEffect(() => {
    if (!socket) connectSocket();

    return () => {
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // send a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        // Send a message to the server
        VehiclesService.subscribeVehicleSocket(socket);

        const timer = setInterval(() => {
          VehiclesService.getVehiclesSocket(socket);
        }, 15000);

        return () => clearInterval(timer);
      }
    }, 3000);

    return () => {
      socket && VehiclesService.unSubscribeVehicleSocket(socket);
    };
  }, [socket]);

  // received a message from the server
  useEffect(() => {
    setTimeout(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.onmessage = (e) => {
          const parsed = JSON.parse(e.data);
          // console.log(parsed);

          if (parsed?.type === "RESPONSE" && !search) {
            setVehicles(parsed?.data);
          } else if (parsed?.type === "SUBSCRIPTION") {
            setVehicles((prevData) => {
              return prevData.map((obj) => {
                if (obj.id === parsed?.keys?.[0]) {
                  return { ...obj, ...parsed?.payload?.[0] };
                }
                return obj;
              });
            });
          }
        };
      }
    }, 3000);
  }, [socket]);

  useEffect(() => {
    setLoading(true);

    VehiclesService.getVehicles()
      .then((vehicles) => setVehicles(vehicles?.data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    VehiclesService.getVehicles(searchVehicle)
      .then((vehicles) => setVehicles(vehicles?.data))
      .catch((err) => console.log(err));
  }, [searchVehicle]);

  const onDispatch = () => {
    TasksService.dispatchTask({
      id: jobDetails?.id,
      vehicle_id: selected?.vehicle_id,
      delivery_vehicle_type: selected?.vehicle_type,
    })
      .then(() => navigate(-1))
      .catch((err) => console.log(err));
  };

  const centerLat =
    (parseFloat(jobDetails?.pickup_address_lat) +
      parseFloat(jobDetails?.dropoff_address_lat)) /
    2;
  const centerLng =
    (parseFloat(jobDetails?.pickup_address_lng) +
      parseFloat(jobDetails?.dropoff_address_lng)) /
    2;

  return (
    <Block style={{ padding: `0px ${isMobile ? "0px" : "25px"}` }}>
      <Header icon={HeaderIcon} title="Assign Job" search={false} />

      <AssignOrderMap
        lat={centerLat}
        lng={centerLng}
        latCar1={jobDetails?.pickup_address_lat}
        lngCar1={jobDetails?.pickup_address_lng}
        latCar2={jobDetails?.dropoff_address_lat}
        lngCar2={jobDetails?.dropoff_address_lng}
      >
        <div className="d-flex flex-row justify-content-between">
          <div className={`col-sm-3 ${styles.container}`}>
            <div className={styles.inputContainer}>
              <img src={search} alt="search" className={styles.search} />
              <input
                placeholder="Search Vehicle"
                value={searchVehicle}
                onChange={(e) => setSearchVehicle(e.target.value)}
              />
            </div>

            {selected ? (
              <div className={`d-flex flex-row-reverse ${styles.selectedRow}`}>
                <Button
                  btnText="Assign"
                  fontSize="13px"
                  onClick={onDispatch}
                  margin="10px 0px"
                />
              </div>
            ) : null}

            {/* selected drivers */}
            {vehicles
              ?.filter((item) => item.id === selected?.vehicle_id)
              ?.map((item, index) => (
                <DriverCard
                  key={index}
                  item={item}
                  selected={item?.id === selected?.vehicle_id}
                  onSelect={() =>
                    item?.id === selected?.vehicle_id
                      ? setSelected({ vehicle_id: null, vehicle_type: null })
                      : setSelected({
                          vehicle_id: item?.id,
                          vehicle_type: item.type,
                        })
                  }
                />
              ))}

            {/* not selected drivers */}
            <div className={styles.rowSeparator} />
            {vehicles
              ?.filter((item) => item.id !== selected?.vehicle_id)
              ?.map((item, index) => (
                <DriverCard
                  key={index}
                  item={item}
                  borderBottom={true}
                  selected={item?.id === selected?.vehicle_id}
                  onSelect={() =>
                    item?.id === selected?.vehicle_id
                      ? setSelected({ vehicle_id: null, vehicle_type: null })
                      : setSelected({
                          vehicle_id: item?.id,
                          vehicle_type: item.type,
                        })
                  }
                />
              ))}
          </div>

          <div className={`col-sm-3 ${styles.addressContainer}`}>
            <div className={styles.justRow}>
              <BsCircle
                height="12px"
                width="12px"
                color="#B9B9B9"
                className={styles.mapPin}
              />
              <span className={styles.address}>
                {jobDetails?.pickup_address}
              </span>
            </div>

            <div className={styles.dottedLine} />

            <div className={styles.justRow}>
              <BiMap
                height={16}
                width={12}
                color="#000000"
                className={styles.mapPin}
              />
              <span className={styles.address}>
                {jobDetails?.dropoff_address}
              </span>
            </div>
          </div>
        </div>
      </AssignOrderMap>
    </Block>
  );
}

export default AssignOrder;
