import React from "react";
import styles from "./SettingsUserTable.module.css";
import { Table } from "react-bootstrap";
import Checkbox from "../Checkbox/Checkbox";
import { log } from "util";

function SettingsUserTable({
  data,
  settings,
  onClickRemove,
  check,
  onCheck,
  checked,
}) {
  console.log(data, "dataUserTable");
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className={styles.th}>Email Address</th>
          <th className={styles.th}>Status</th>
          <th className={styles.th}></th>
          <th className={styles.th}></th>
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 &&
          data?.map((item, index) => (
            <tr key={index}>
              <td className={styles.td}>{item?.email}</td>

              <td className={styles.td}>
                {item?.status?.charAt(0)?.toUpperCase() +
                  item?.status?.slice(1)}
              </td>
              {/* {item?.role?.name === "Administrator" ||
              settings?.email === item?.email ? null : ( */}
              <td>
                <span
                  className={styles.delete}
                  onClick={() => onClickRemove(item)}
                >
                  Remove
                </span>
              </td>
              <td className={styles.td}></td>
              {/* )} */}
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default SettingsUserTable;
