import React from "react";
import Block from "../Block/Block";
import styles from "./TrialEnd.module.css";
import Button from "../Button/Button";

const TrialEnd = () => {
  return (
    <div className={styles.overlayTrial}>
      <Block style={{ padding: "18%" }}>
        <div className={styles.noData}>
          <p style={{ marginBottom: "20px" }}>Your Trial has ended</p>
          <span>To continue using TrackBuddy</span>
        </div>
        <div className={styles.headerBtn}>
          <Button
            color={"#FFF"}
            backgroundColor={"#000"}
            fontSize="20px"
            btnText={"Subscribe"}
            // onClick={onAddClick}
            padding="0px 25px"
            height="53px"
            width="165px"
          />
        </div>
      </Block>
    </div>
  );
};

export default TrialEnd;
