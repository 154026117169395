export const notification = [
  {
    id: 0,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 1,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 2,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 3,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 4,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 5,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 6,
    notification: "Delivery task 8777 Delivered successfully",
  },
  {
    id: 7,
    notification: "Delivery task 8777 Delivered successfully",
  },
];
