import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const getSubscriptionByTenantId = async () => {
  const { tenant_id } = await getToken();
  console.log(tenant_id, "tenant_id");
  const obj = {
    method: "GET",
  };

  try {
    const response = await fetch(
      `${API.tenants}/${tenant_id}?fields=*,subscriptions.*,subscriptions.subscription_plan_id.*.*`,
      obj
    );
    const json = await response.json();
    // console.log(json?.data?.subscriptions[0], "getSubscriptionByTenantId");
    if (!response.ok) throw new Error("Something went wrong");
    return { subscription: json?.data?.subscriptions[0] };
  } catch (err) {
    throw err;
  }
};
export const trialRemainingDays = async () => {
  try {
    const { subscription } = await getSubscriptionByTenantId();

    if (!subscription || !subscription.expiry_date) {
      console.error("Invalid subscription data");
      return null; // or some default value indicating an error
    }

    const currentDate = new Date(); // Current date
    const endDate = new Date(subscription.expiry_date);

    if (isNaN(endDate)) {
      console.error("Invalid date format");
      return null; // or some default value indicating an error
    }

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDate - currentDate;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays.toFixed(0); // or remove toFixed for non-rounded value
  } catch (error) {
    console.error("Error fetching trial Days:", error);
    return null; // or some default value indicating an error
  }
};
