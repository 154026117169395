export const getMinutesDifferenceFromCurrentTimestamp = (providedTimestamp) => {
  // Get the current timestamp in the user's local timezonex
  const currentTimestamp = new Date().getTime();

  // Convert the provided GMT timestamp to the user's local timezone
  const providedDate = new Date(providedTimestamp);

  // Get the timezone offset in minutes
  const timezoneOffsetInMinutes = providedDate.getTimezoneOffset();

  // Adjust the provided date with the timezone offset
  providedDate.setMinutes(providedDate.getMinutes() - timezoneOffsetInMinutes);

  // Calculate the time difference in milliseconds
  const timeDifferenceInMilliseconds = currentTimestamp - providedDate;

  // Calculate the time difference in minutes
  const timeDifferenceInMinutes = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60)
  );

  return timeDifferenceInMinutes;
};

export const getTimeDifference = (timestamp) => {
  // Convert provided GMT timestamp to user's local timezone
  const providedDate = new Date(timestamp);
  const timezoneOffsetInMinutes = providedDate.getTimezoneOffset();
  providedDate.setMinutes(providedDate.getMinutes() - timezoneOffsetInMinutes);

  // Get current timestamp in user's local timezone
  const currentTimestamp = new Date();

  // Calculate time difference in milliseconds
  const timeDifference = currentTimestamp - providedDate;

  // Calculate time difference in seconds, minutes, hours, and days
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);

  // Determine the appropriate time unit to display
  if (monthsDifference >= 1) {
    return monthsDifference + " month(s) ago";
  } else if (daysDifference >= 1) {
    return daysDifference + " day(s) ago";
  } else if (hoursDifference >= 24) {
    return "1 day ago";
  } else if (hoursDifference >= 1) {
    return hoursDifference + " hour(s) ago";
  } else if (minutesDifference >= 1) {
    return minutesDifference + " minute(s) ago";
  } else {
    return secondsDifference + " second(s) ago";
  }
};

export const getISOTimeDifference = () => {
  // Get the current time in GMT
  const currentGMTTime = new Date().toUTCString();

  // Convert the current GMT time to a Date object
  const currentGMTDate = new Date(currentGMTTime);

  // Minus 60 seconds from the current GMT time
  const pastTimeGMT = new Date(currentGMTDate.getTime() - 60000);

  // Format the times in ISO 8601 format
  const pastISO = pastTimeGMT.toISOString();

  return pastISO;
};

export function formatUTCDate(utcDateTimeString) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // This will format the time in AM/PM format
  };

  const utcDate = new Date(utcDateTimeString);
  return utcDate.toLocaleString(undefined, options);
}

// const utcDateTimeString = '2024-01-02T13:04:21.420Z';
// const formattedDateTime = formatUTCDate(utcDateTimeString);
// console.log(formattedDateTime);

export const excelDateToJSDate = (shortDate) => {
  const [day, month, year] = shortDate.split("/");
  const jsDate = new Date(`${year}-${month}-${day}`);
  return jsDate.toISOString();
};

export const isExcelDateFormatValid = (inputDate) => {
  const parts = inputDate.split("/");
  if (parts.length !== 3) {
    return false; // Invalid format: Must have 3 parts separated by slashes
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false; // Invalid numbers
  }

  // Check for valid month range (1-12) and day range based on month
  if (
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > new Date(year, month, 0).getDate()
  ) {
    return false;
  }

  // Check if the date can be successfully constructed using the parts
  const constructedDate = new Date(year, month - 1, day);
  if (isNaN(constructedDate.getTime())) {
    return false; // Invalid date
  }

  return true;
};

export const formatTime = (time) => {
  if (!time) {
    return;
  }

  const date = new Date(time);
  const userOffset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localTime = new Date(date.getTime() - userOffset); // Convert to local time

  let minutes = localTime.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let hours = localTime.getHours();
  const period = hours > 11 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? "0" + hours : hours;

  const formatted = `${hours}:${minutes} ${period}`;
  return formatted;
};

export const formatTimeNew = (timeStr) => {
  if (!timeStr) return "N/A";

  const parts = timeStr.split(":");
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parts[2] ? parseInt(parts[2], 10) : undefined;

  if (hours > 12) {
    return `${hours - 12}:${minutes}${
      seconds !== undefined ? `:${seconds}` : ""
    } PM`;
  } else if (hours === 0) {
    return `12:${minutes}${seconds !== undefined ? `:${seconds}` : ""} AM`;
  } else if (hours === 12) {
    return `${hours}:${minutes}${
      seconds !== undefined ? `:${seconds}` : ""
    } PM`;
  } else {
    return `${hours}:${minutes}${
      seconds !== undefined ? `:${seconds}` : ""
    } AM`;
  }
};

export const formatDate = (date) => {
  if (!date) {
    return;
  }

  return new Date(date).toString().slice(4, 15);
};

export const minimumDate = () => {
  // Get the current date and time
  const now = new Date();

  // Format the current date as "YYYY-MM-DD"
  const currentDate = `${now.getFullYear()}-${String(
    now.getMonth() + 1
  ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;

  // Format the current time as "HH:mm"
  const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(
    now.getMinutes()
  ).padStart(2, "0")}`;

  // Combine the formatted date and time to set the minimum
  return `${currentDate}T${currentTime}`;
};
