import React, { useEffect, useState } from "react";
import styles from "./AddContact.module.css";
import {
  Block,
  DropdownForm,
  Header,
  InputForm,
  FormPhoneInput,
} from "../../components";
import { Button } from "react-bootstrap";
import { AccountIcon } from "../../assets/svg";
import { ContactsService } from "../../services";
import { useNavigate } from "react-router-dom";
import { BiMailSend, BiCurrentLocation } from "react-icons/bi";
import { CountryHelpder } from "../../helper";
import { LoadingContext } from "../../context";

function AddContact() {
  const navigate = useNavigate();
  // context
  const { setLoading } = LoadingContext.useLoading();

  const [contacts, setContacts] = useState({
    full_name: "",
    email: "",
    home_address: "",
    home_address_lat: "",
    home_address_lng: "",
    office_address: "",
    office_address_lat: "",
    office_address_lng: "",
    phone_no: "",
    mobile_no: "",
    type: "Select Type",
    company: "",
    country: null,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLoading(true);

    CountryHelpder.fetchCountry()
      .then((res) => setContacts({ ...contacts, country: res }))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };

  const handleAddressChange = (name, value) => {
    setContacts((prev) => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const validateForm = () => {
    const { full_name, home_address, mobile_no, type } = contacts;

    const newErrors = {};

    if (!full_name) {
      newErrors.full_name = "Full name is required";
    } else if (!home_address) {
      newErrors.home_address = "Home address is required";
    } else if (!mobile_no) {
      newErrors.mobile_no = "Mobile no is required";
    } else if (type === "Select Type") {
      newErrors.type = "Type is required";
    }

    return newErrors;
  };

  const onAddContact = (event) => {
    event.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      ContactsService.addContact(contacts)
        .then((res) => {
          res?.data && navigate("/contacts");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Block
      style={{ padding: "0px 25px 25px 25px" }}
      withForm={true}
      onSubmit={onAddContact}
    >
      <Header icon={AccountIcon} title="Add Contact" search={false} />
      <div className="row">
        <div className="col-sm-6">
          <InputForm
            required={true}
            label="Full Name *"
            name="full_name"
            value={contacts.full_name}
            onChange={handleChange(setContacts)}
            inValidMessage={errors.full_name}
          />
        </div>
        <div className="col-sm-6">
          <InputForm
            icon={<BiMailSend size={20} />}
            label="Email"
            name="email"
            type="email"
            value={contacts.email}
            onChange={handleChange(setContacts)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <InputForm
            required={true}
            type="autocomplete"
            label="Home Address *"
            name="home_address"
            onPlaceSelect={(place) => {
              handleAddressChange("home_address", place?.formatted_address);
              handleAddressChange(
                "home_address_lat",
                place?.geometry?.location.lat()
              );
              handleAddressChange(
                "home_address_lng",
                place?.geometry?.location.lng()
              );
            }}
            lastIcon={<BiCurrentLocation size={20} />}
            inValidMessage={errors.home_address}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <InputForm
            type="autocomplete"
            label="Office Address"
            name="office_address"
            onPlaceSelect={(place) => {
              handleAddressChange("office_address", place?.formatted_address);
              handleAddressChange(
                "office_address_lat",
                place?.geometry?.location.lat()
              );
              handleAddressChange(
                "office_address_lng",
                place?.geometry?.location.lng()
              );
            }}
            lastIcon={<BiCurrentLocation size={20} />}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <FormPhoneInput
            label="Phone Number"
            name="phone_no"
            country={contacts.country}
            value={contacts.phone_no}
            onChange={(e) => setContacts({ ...contacts, phone_no: e })}
          />
        </div>
        <div className="col-sm-6">
          <FormPhoneInput
            required={true}
            label="Mobile Number *"
            name="mobile_no"
            country={contacts.country}
            value={contacts.mobile_no}
            onChange={(e) => setContacts({ ...contacts, mobile_no: e })}
            inValidMessage={errors.mobile_no}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <DropdownForm
            required={true}
            label="Select Type *"
            name="type"
            options={["Recipient", "Sender", "Other"]}
            value={contacts.type}
            onChange={handleChange(setContacts)}
            inValidMessage={errors.type}
          />
        </div>
        <div className="col-sm-6">
          <InputForm
            label="Company"
            name="company"
            value={contacts.company}
            onChange={handleChange(setContacts)}
          />
        </div>
      </div>

      <Button variant="dark" type="submit" className={styles.submit}>
        Submit
      </Button>
    </Block>
  );
}

export default AddContact;
