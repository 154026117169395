import React from "react";
import "./Tabs.css";
import { Tab, Tabs } from "react-bootstrap";

const CustomTabs = ({ tabs, activeKey, onSelect, className, allCount }) => {
  const mappedArray = tabs?.map((obj) => {
    const [key, value] = Object.keys(obj);
    return { tabKey: obj[key], tabValue: obj[value] };
  });
  console.log({ mappedArray });
  const handleTabSelect = (key) => {
    if (onSelect) {
      onSelect(key);
    }
  };

  return (
    <Tabs
      className={className}
      activeKey={activeKey}
      onSelect={handleTabSelect}
    >
      <Tab
        eventKey="All"
        title={
          <TabTitleWithBadge
            active={activeKey === "All"}
            title="All"
            badgeText={allCount > 0 ? allCount : 0}
            onClick={() => handleTabSelect("All")}
          />
        }
      />
      {mappedArray?.map((item, index) => (
        <Tab
          key={index}
          eventKey={item?.tabKey}
          title={
            <TabTitleWithBadge
              active={activeKey === item?.tabKey}
              title={item?.tabKey}
              badgeText={item?.tabValue || 0}
              onClick={() => handleTabSelect(item?.tabKey)}
            />
          }
        />
      ))}
    </Tabs>
  );
};

const TabTitleWithBadge = ({ title, badgeText, active, onClick }) => (
  <div className="tab-title-badge" onClick={onClick}>
    {title}
    <span className={active ? "activeBadge" : "inactiveBadge"}>
      {badgeText}
    </span>
  </div>
);

export default CustomTabs;
