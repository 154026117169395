import React, { useState } from "react";
import styles from "./AddNewDriver.module.css";
import HeaderIcon from "../../assets/svg/drivers.svg";
import {
  Block,
  Button,
  DriverActivation,
  DriverLicenseInfo,
  DriverPersonalInfo,
  DriverRealNameInfo,
  DriverVehicleInfo,
  DriverVendorInfo,
  Header,
  ProgressCounter,
} from "../../components";
import { useNavigate } from "react-router-dom";

function AddNewDriver() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  // const [driver, setDriver] = useState({
  //   firstname: "",
  //   lastname: "",
  // });

  // const handleChange = (event) => {
  //   setDriver((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  // };

  const RenderCard = () => {
    if (step === 1)
      return (
        <DriverPersonalInfo
        // firstname="firstname"
        // firstnameValue={driver.firstname}
        // onChangeFirstName={handleChange}
        />
      );
    else if (step === 2) return <DriverRealNameInfo />;
    else if (step === 3) return <DriverLicenseInfo />;
    else if (step === 4) return <DriverVehicleInfo />;
    else if (step === 5) return <DriverVendorInfo />;
    else if (step === 6) return <DriverActivation />;
  };

  return (
    <Block style={{ padding: "0px 25px 25px 25px" }}>
      <Header
        icon={HeaderIcon}
        title="Add New Driver"
        breadcrumb="Home > Drivers"
        search={false}
      />

      <div className={`row ${styles.block}`}>
        <div className={`col-sm-3 ${styles.progressCounter}`}>
          <ProgressCounter step={step} currentStep={1} label="Profile Info" />
          <ProgressCounter
            step={step}
            currentStep={2}
            label="Real name information"
          />
          <ProgressCounter
            step={step}
            currentStep={3}
            label="Driving License"
          />
          <ProgressCounter step={step} currentStep={4} label="Vehicle info" />
          <ProgressCounter step={step} currentStep={5} label="Vendor" />
          <ProgressCounter
            last={true}
            step={step}
            currentStep={6}
            label="Activation"
          />
        </div>

        <div className="col-sm-7">
          <div className={styles.card}>
            <RenderCard />
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between">
            <Button
              btnText={step === 1 ? "Cancel" : "Back"}
              color="#000"
              backgroundColor="#fff"
              onClick={() =>
                step === 1 ? navigate("/drivers") : setStep(step - 1)
              }
            />
            <Button
              btnText={step === 6 ? "Finish" : "Next"}
              onClick={() =>
                step === 6 ? navigate("/drivers") : setStep(step + 1)
              }
            />
          </div>
        </div>

        <div className="col-sm-2"></div>
      </div>
    </Block>
  );
}

export default AddNewDriver;
