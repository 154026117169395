import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.css";
import {
  Block,
  DashboardCard,
  Header,
  NotificationCard,
} from "../../components";
import { Home } from "../../assets/svg";
import { NotificationDummy } from "../../constants";
import { isMobile } from "react-device-detect";
import {
  DashboardService,
  VehiclesService,
  ContactsService,
} from "../../services";
import { LoadingContext } from "../../context";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Line Chart",
    },
  },
};

// const labels = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];
const deliveryLabels = [
  "Pending Delivery",
  "Successful Deliveries",
  "Out For Delivery",
  "Cancelled",
];
const vehiclesLabels = [
  "Registered Vehicles",
  "Online Vehicles",
  "Offline Vehicles",
];
const contactsLabels = ["Contacts"];
// export const deliveryData = {
//   labels: deliveryLabels,
//   datasets: [
//     {
//       label: "Delivery Tasks",
//       data: deliveryLabels.map(() =>
//       faker.datatype.number({ min: -1000, max: 1000 })
//     ),
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     // {
//     //   label: "Vehicles",
//     //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//     //   borderColor: "rgb(53, 162, 235)",
//     //   backgroundColor: "rgba(53, 162, 235, 0.5)",
//     // },
//     // {
//     //   label: "Contacts",
//     //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//     //   borderColor: "rgb(75, 192, 192)", // Change the color here
//     //   backgroundColor: "rgba(75, 192, 192, 0.5)",
//     // },
//   ],
// };

export const vehicleData = {
  labels: vehiclesLabels,
  datasets: [
    {
      label: "Vehicles",
      data: vehiclesLabels.map(() =>
        faker.datatype.number({ min: -1000, max: 1000 })
      ),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export const contactsData = {
  labels: contactsLabels,
  datasets: [
    {
      label: "Contacts",
      data: contactsLabels.map(() =>
        faker.datatype.number({ min: -1000, max: 1000 })
      ),
      borderColor: "rgb(75, 192, 192)", // Change the color here
      backgroundColor: "rgba(75, 192, 192, 0.5)",
    },
  ],
};
function Dashboard() {
  // contexts
  const { setLoading } = LoadingContext.useLoading();

  // states
  const [notification, setNotification] = useState(false);
  const [deliveryTasks, setDeliveryTasks] = useState({});
  const [vehcileTasks, setVehicleTasks] = useState([]);
  const [allVehiclesTasks, setAllVehicleTasks] = useState();
  const [contacts, setContacts] = useState();

  useEffect(() => {
    setLoading(true);

    getAll()
      .then((res) => {
        setDeliveryTasks(res?.tasks);
        setVehicleTasks(res?.vehicles);
        setAllVehicleTasks(res?.allVehicles);
        setContacts(res?.contacts);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const getAll = async () => {
    const [tasks, vehicles, allVehicles, contacts] = await Promise.all([
      DashboardService.getDashboardTasksData(),
      VehiclesService.getVehilceTabs(),
      DashboardService.getDashboardVehiclesData(),
      ContactsService.getContacts(null, 0),
    ]);
    const vehicleByStatus = await vehicles?.map((item) => {
      console.log({ item });
      if (item.status === "Online") {
        return { online: item.count };
      } else if (item.status === "Offline") {
        return { offline: item.count };
      }
    });

    return {
      tasks: tasks?.data,
      vehicles: vehicleByStatus,
      allVehicles: allVehicles?.data,
      contacts: contacts?.total_count,
    };
  };
  // const statusData = deliveryData;
  // const extractCounts = (statusData) => {
  //   const counts = Object.values(statusData).map((status) => status.length);
  //   return counts;
  // };

  // const statusLabels = Object.keys(deliveryTasks);
  // const statusCounts = extractCounts(deliveryTasks);

  const deliveryData = {
    labels: deliveryLabels,
    datasets: [
      {
        label: "Delivery Tasks",
        data: Object.values(deliveryTasks).map((status) => status.length),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const allVehiclesDetails = [
    { registeredVehicles: allVehiclesTasks },
    ...vehcileTasks,
  ];

  const numericDetails = allVehiclesDetails.map((detail) => {
    const key = Object.keys(detail)[0]; // Get the key (e.g., 'online' or 'offline')
    const value = parseInt(detail[key], 10); // Convert the value to an integer

    return { [key]: value };
  });
  const vehicleData = {
    labels: vehiclesLabels,
    datasets: [
      {
        label: "Vehicles",
        data: numericDetails.map((detail) => {
          const key = Object.keys(detail)[0];
          return detail[key] || 0; // Default to 0 if value is not present
        }),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  console.log({ numericDetails });

  return (
    <Block style={{ padding: `0px ${isMobile ? "0px" : "25px"}` }}>
      <Header
        icon={Home}
        title="Dashboard"
        search={false}
        // onNotificationClick={() => setNotification(!notification)}
      />
      {/* <div>
        <Line options={options} data={data} />
      </div> */}
      <div
        className={`row ${styles.rowContainer}`}
        onClick={() => notification === true && setNotification(false)}
      >
        <div className="col-sm-12">
          {/* <div className={styles.subscribeAlert}>
            You are subscribed to basic account. <span>Upgrade now</span>
          </div>  */}

          {/* <div className={styles.heading}>Delivery Tasks</div> */}

          {/* <div className={styles.dashboardCardRow}>
            <DashboardCard
              count={deliveryTasks?.pending?.length || 0}
              text="Pending Delivery"
            />
            <DashboardCard
              count={deliveryTasks?.delivered?.length || 0}
              text="Successful Deliveries"
            />
            <DashboardCard
              count={deliveryTasks?.inProgress?.length || 0}
              text="Out For Delivery"
            />
            <DashboardCard
              count={deliveryTasks?.cancelled?.length || 0}
              text="Cancelled"
            />
          </div> */}
          <div>
            <span className={styles.heading}>Delivery Tasks</span>
            <Line options={options} data={deliveryData} />
          </div>

          {/* <div className={styles.heading}>Vehicles</div> */}

          {/* <div className={styles.dashboardCardRow}>
            <DashboardCard
              count={allVehiclesTasks || 0}
              text="Registered Vehicles"
            />
            <DashboardCard
              count={vehcileTasks?.[0]?.online || 0}
              text="Online Vehicles"
            />
            <DashboardCard
              count={vehcileTasks?.[1]?.offline || 0}
              text="Offline Vehicles"
            />
          </div> */}
          <div style={{ marginTop: 65, marginBottom: 65 }}>
            <span className={styles.heading}>Vehicles</span>
            <Line options={options} data={vehicleData} />
          </div>

          <div className={styles.heading}>Contacts</div>
          <div className={styles.dashboardCardRow}>
            <DashboardCard count={contacts || 0} text="contacts" />
          </div>
          {/* <Line options={options} data={contactsData} /> */}
        </div>
      </div>

      <NotificationCard
        show={notification}
        notifications={NotificationDummy.notification}
      />
    </Block>
  );
}

export default Dashboard;
