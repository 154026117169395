import React, { useEffect, useState, createContext, useContext } from "react";
import { websocketBaseUrl } from "../utils/api";
import { AuthService } from "../services";

const Context = createContext();
export const useSocket = () => useContext(Context);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const [appStatus, setAppStatus] = useState(false);

  const connectSocket = async () => {
    const { token } = await AuthService.getToken();

    // Create a new WebSocket connection when the component mounts
    const newSocket = new WebSocket(
      `${websocketBaseUrl}?access_token=${token}`
    );
    console.log({ newSocket });
    setSocket(newSocket);
    setAppStatus(true);

    newSocket.onopen = () => {
      console.log("WebSocket connection established");
    };
  };

  const disconnectSocket = () => {
    if (socket) {
      setAppStatus(false);
      socket.close();
    }
  };

  useEffect(() => {
    console.log({ appStatus });
    if (socket) {
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        // Handle the error, e.g., display an error message
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");

        if (appStatus) {
          connectSocket();
        } else {
          disconnectSocket();
        }
      };

      return () => {
        socket.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <Context.Provider value={{ socket, connectSocket, disconnectSocket }}>
      {children}
    </Context.Provider>
  );
};
