import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const getDashboardTasksData = async () => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.delivery_tasks}?filter[status][_in]=Pending,Delivered,In Progress,Cancelled&filter[tenant_id]=${tenant_id}`,
      obj
    );
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    const pending = [];
    const delivered = [];
    const inProgress = [];
    const cancelled = [];

    await json?.data?.map((item) => {
      if (item?.status === "Pending") {
        return pending.push(item);
      }
      if (item?.status === "Delivered") {
        return delivered.push(item);
      }
      if (item?.status === "In Progress") {
        return inProgress.push(item);
      }
      if (item?.status === "Cancelled") {
        return cancelled.push(item);
      }
    });

    return { data: { pending, delivered, inProgress, cancelled } };
  } catch (e) {
    throw e;
  }
};

export const getDashboardVehiclesData = async () => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.vehicle}?filter[tenant_id]=${tenant_id}&meta=total_count`,
      obj
    );
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return { data: json?.data?.length };
  } catch (e) {
    throw e;
  }
};
