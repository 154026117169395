import React from "react";
import styles from "./Orders.module.css";
import { Block, Header, OrderTable } from "../../components";
import { Tasks } from "../../assets/svg";
import { Tab, Nav } from "react-bootstrap";
import { OrdersDummy } from "../../constants";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

function Orders() {
  const navigate = useNavigate();

  const [select, setSelect] = useState("all");

  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header icon={Tasks} title="Tasks" searchMargin="0px 0px 0px 100px" />

      <Tab.Container activeKey={select} onSelect={(key) => setSelect(key)}>
        <div className={styles.rowWrapper}>
          <Nav.Link
            eventKey="all"
            className={`${styles.tabwrapper} 
                ${
                  select === "all" ? styles.activeStyle : styles.inActiveStyle
                }`}
          >
            <span>All</span>
            <span
              className={
                select === "all" ? styles.activecounter : styles.inactiveCounter
              }
            >
              167
            </span>
          </Nav.Link>
          <Nav.Link
            eventKey="pending"
            className={`${styles.tabwrapper} 
                ${
                  select === "pending"
                    ? styles.activeStyle
                    : styles.inActiveStyle
                }`}
          >
            <span>Pending</span>
            <span
              className={
                select === "pending"
                  ? styles.activecounter
                  : styles.inactiveCounter
              }
            >
              55
            </span>
          </Nav.Link>
          <Nav.Link
            eventKey="accepted"
            className={`${styles.tabwrapper} 
                ${
                  select === "accepted"
                    ? styles.activeStyle
                    : styles.inActiveStyle
                }`}
          >
            <span>Accepted</span>
            <span
              className={
                select === "accepted"
                  ? styles.activecounter
                  : styles.inactiveCounter
              }
            >
              32
            </span>
          </Nav.Link>
          <Nav.Link
            eventKey="rejected"
            className={`${styles.tabwrapper} 
                ${
                  select === "rejected"
                    ? styles.activeStyle
                    : styles.inActiveStyle
                }`}
          >
            <span>Rejected</span>
            <span
              className={
                select === "rejected"
                  ? styles.activecounter
                  : styles.inactiveCounter
              }
            >
              27
            </span>
          </Nav.Link>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="all">
            <OrderTable
              data={OrdersDummy?.orders}
              onClickRow={(data) =>
                navigate({
                  pathname: "order-details",
                  search: createSearchParams(data).toString(),
                })
              }
            />
          </Tab.Pane>
          <Tab.Pane eventKey="pending">
            <OrderTable
              data={OrdersDummy?.orders}
              onClickRow={() => navigate("order-details")}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="accepted">
            <OrderTable />
          </Tab.Pane>
          <Tab.Pane eventKey="rejected">
            <OrderTable />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Block>
  );
}

export default Orders;
