import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const subscribeTaskSocket = async (socket) => {
  const { tenant_id } = await getToken();

  const obj = {
    type: "subscribe",
    collection: "delivery_tasks",
    query: {
      filter: {
        tenant_id: {
          _eq: tenant_id,
        },
      },
      limit: 1,
    },
    uid: "dt1",
  };

  socket.send(JSON.stringify(obj));
};

export const unSubscribeTaskSocket = async (socket) => {
  const obj = {
    type: "unsubscribe",
    uid: "dt1",
  };

  socket.send(JSON.stringify(obj));
};

export const getDeliveryJobsSocket = async (socket) => {
  const { tenant_id } = await getToken();

  const obj = {
    type: "GET",
    collection: "delivery_tasks",
    query: {
      filter: {
        tenant_id: {
          _eq: tenant_id,
        },
      },
    },
  };

  socket.send(JSON.stringify(obj));
};

export const getTasks = async (search, status, page) => {
  const { tenant_id, token } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API.delivery_tasks}?filter[tenant_id]=${tenant_id}&meta=filter_count&page=${page}`;
    const queries = [];

    if (search) queries.push(`search=${search}`);
    if (status && status !== "All")
      queries.push(`filter[status][_eq]=${status}`);

    const query = queries?.join("&");

    url += `&${query}&${API.sortnLimit}`;

    const response = await fetch(url, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return {
      data: json?.data,
      total_count: json?.meta?.filter_count,
      total_pages: Math.ceil(json?.meta?.filter_count / 10), // Calculate total number of pages
    };
  } catch (e) {
    throw e;
  }
};

export const getTasksForTracking = async (jobId) => {
  // const { tenant_id, token } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API.delivery_tasks}?filter[id]=${jobId}`;
    const response = await fetch(url, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return {
      data: json?.data,
    };
  } catch (e) {
    throw e;
  }
};

export const getDeliveryHistory = async (jobId) => {
  // const { token } = await getToken();
  const obj = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(
      `${API.delivery_tasks}/${jobId}?fields=*,delivery_history.*`,
      obj
    );
    const json = await response.json();
    // console.log(response, "response getDeliveryHistory", jobId);
    if (!response?.ok) throw new Error("Something went wrong");

    return json;
  } catch (err) {
    return err;
  }
};

export const getDeliveryTabs = async () => {
  const { tenant_id, token } = await getToken();

  const obj = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(`${API.delivery_status}/${tenant_id}`, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return json;
  } catch (err) {
    return err;
  }
};

export const getVehiclesById = async (tasks) => {
  const { token } = await getToken();

  const obj = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const result = await Promise.all(
    tasks?.map(async (task) => {
      try {
        const response = await fetch(`${API.vehicle}/${task?.vehicle_id}`, obj);
        const json = await response.json();

        return { ...task, vehicle: json.data?.name };
      } catch (err) {
        return err;
      }
    })
  );

  return result;
};

export const uploadImg = async (name, logo) => {
  const { token } = await getToken();

  try {
    const formData = new FormData();
    formData.append("title", name);
    formData.append("file", logo);

    const obj = {
      method: "POST",
      body: formData,
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(API.files, obj);
    const json = await response.json();

    return json?.data?.filename_disk?.split(".")?.[0];
  } catch (e) {
    throw e;
  }
};
export const uploadProductImages = async (names, logos) => {
  const { token } = await getToken();

  try {
    const formData = new FormData();

    logos.forEach((logo, index) => {
      formData.append(`productImage${index + 1}`, logo); // Appending file with the productImageN naming convention
      formData.append(`title${index + 1}`, names[index]); // You can also append titles if needed
    });

    const obj = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(API.files, obj);
    const json = await response.json();

    // Returning an array of uploaded filenames
    return json?.data?.map((item) => item?.filename_disk?.split(".")?.[0]);
  } catch (e) {
    throw e;
  }
};

export const addTask = async (task) => {
  try {
    const { tenant_id, token } = await getToken();
    // console.log(tenant_id, token, "add Task");
    // const podImg1 = task?.podImg1 ? await uploadImg(task?.podImg1) : null;
    // const podImg2 = task?.podImg2 ? await uploadImg(task?.podImg2) : null;
    // const newTask = { ...task, podImg1, podImg2, tenant_id };
    const newTask = { ...task, tenant_id };
    console.log({ newTask });
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newTask),
    };

    const response = await fetch(API.delivery_tasks, obj);
    console.log(response, "responseAddTask");
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json;
  } catch (e) {
    throw e;
  }
};
export const addBatchDeliveryTask = async (task) => {
  try {
    const { token } = await getToken();
    // console.log(tenant_id, token, "add Task");

    // const newTask = { ...task, tenant_id };
    console.log(task, "task");
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(task),
    };

    const response = await fetch(API.delivery_tasks, obj);
    console.log(response, "responseAddBatchDeliveryTask");
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json;
  } catch (e) {
    throw e;
  }
};
export const updateTask = async ({ id, task }) => {
  try {
    const { tenant_id, token } = await getToken();
    const podImg1 = task?.podImg1 ? await uploadImg(task?.podImg1) : null;
    const podImg2 = task?.podImg2 ? await uploadImg(task?.podImg2) : null;

    const newTask = { ...task, podImg1, podImg2, tenant_id };

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newTask),
    };

    const response = await fetch(`${API.delivery_tasks}/${id}`, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json;
  } catch (e) {
    throw e;
  }
};

export const addImportTasks = async (task, filename) => {
  try {
    const { tenant_id, token } = await getToken();
    const batchUpload = await addBatchTask({ filename, tenant_id });

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(task),
    };

    const response = await fetch(API.delivery_tasks, obj);
    const json = await response.json();

    if (!response?.ok) {
      updateBatchTask({
        id: batchUpload?.id,
        status: "Failed",
        failed_rows: task?.length,
        valid_rows: 0,
        tenant_id,
      });
    } else {
      updateBatchTask({
        id: batchUpload?.id,
        status: "Delivered",
        valid_rows: task?.length,
        failed_rows: 0,
        tenant_id,
      });
    }

    return json;
  } catch (e) {
    throw e;
  }
};

export const addBatchTask = async ({ filename }) => {
  try {
    const { token, tenant_id } = await getToken();
    const file_name = await uploadFile(filename);

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ file_name, status: "Processing", tenant_id }),
    };

    const response = await fetch(API.batch_upload, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const updateBatchTask = async ({
  id,
  status,
  failed_rows,
  valid_rows,
}) => {
  const { token, tenant_id } = await getToken();

  try {
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ status, failed_rows, valid_rows, tenant_id }),
    };

    const response = await fetch(`${API.batch_upload}/${id}`, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const getBatchTask = async () => {
  try {
    const { tenant_id, token } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.batch_upload}?filter[tenant_id]=${tenant_id}&fields=*.*`,
      obj
    );
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const uploadFile = async (name, logo) => {
  const { token } = await getToken();

  try {
    const formData = new FormData();
    formData.append("title", name);
    formData.append("file", logo);

    const obj = {
      method: "POST",
      body: formData,
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(API.files, obj);
    const json = await response.json();

    return json?.data?.filename_disk?.split(".")?.[0];
  } catch (e) {
    throw e;
  }
};

export const downloadFile = (file, filename) => {
  fetch(`${API.assets}/${file}`)
    .then((response) => response.blob())
    .then((blob) => {
      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = filename; // Replace with your desired file name and extension
      downloadLink.click();

      // Clean up the download link
      URL.revokeObjectURL(downloadLink.href);
      downloadLink.remove();
    })
    .catch((error) => console.error("Error downloading file:", error));
};

export const downloadTemplate = (templatePath, filename) => {
  // const templatePath = '/static/csv/template.csv'; // Replace with the static path of your CSV template

  fetch(templatePath)
    .then((response) => response.blob())
    .then((blob) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.csv`; // Set the desired filename for the downloaded file
      downloadLink.click();
    })
    .catch((error) => console.error("Error downloading CSV template:", error));
};

export const dispatchTask = async ({
  id,
  vehicle_id,
  delivery_vehicle_type,
}) => {
  const { token } = await getToken();

  const obj = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: "Assigned",
      vehicle_id,
      delivery_vehicle_type,
    }),
  };

  const response = await fetch(`${API.delivery_tasks}/${id}`, obj);
  const json = await response.json();

  return json;
};

export const deleteTask = async (id) => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API.delivery_tasks}/${id}`, obj);

    if (!response?.ok) throw new Error("Something went wrong");

    return true;
  } catch (err) {
    throw err;
  }
};
