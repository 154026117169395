import React, { useState, useCallback, useEffect } from "react";
import styles from "./VehicleTracking.module.css";
import { Block, Header, VehcileMapCard } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo, VanIcon } from "../../assets/svg";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
// import VehcileMapCard from "../VehcileMapCard/VehcileMapCard";
import { MapStyles } from "../../constants";
import MapCar from "../../assets/img/mapCar.png";

// ... (import statements)

const VehicleTracking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Access the vehicle data from the state
  const vehicleData = state && state.item;
  console.log(vehicleData, "vehicleData");

  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedElementLocation, setSelectedElementLocation] = useState(null);
  const [activeMarker, setActiveMarker] = useState();
  const [currentPosition, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ["geometry", "places"],
  });
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  // function to find center of the selected vehicle
  useEffect(() => {
    const location = vehicleData?.location
      ? JSON.parse(vehicleData?.location)
      : {};

    const parsedLatitude = Number(location?.coords?.latitude);
    const parsedLongitude = Number(location?.coords?.longitude);

    const isValidLocation = !isNaN(parsedLatitude) && !isNaN(parsedLongitude);

    const fallbackCenter = {
      lat: 24.9346328,
      lng: 67.0847751,
    };

    const mapCenter = isValidLocation
      ? { lat: parsedLatitude, lng: parsedLongitude }
      : fallbackCenter;

    setCurrentLocation(mapCenter);
  }, [vehicleData]);

  // Function to calculate bounds and set the auto-zoom
  const onLoad = useCallback(
    (map) => {
      map.setOptions({
        styles: MapStyles.styles,
      });

      const bounds = new window.google.maps.LatLngBounds();

      const location = vehicleData?.location
        ? JSON.parse(vehicleData?.location)
        : {};

      if (
        !isNaN(location?.coords?.latitude) &&
        !isNaN(location?.coords?.longitude)
      ) {
        bounds.extend({
          lat: Number(location?.coords?.latitude),
          lng: Number(location?.coords?.longitude),
        });
      }

      map.fitBounds(bounds); // Resize based on markers, native
    },
    [vehicleData]
  );

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  // Function to load the location of the selected vehicle
  const mapLocationLoad = (item) => {
    const location = item?.location && JSON.parse(item?.location);

    Geocode.fromLatLng(
      `${location?.coords?.latitude}`,
      `${location?.coords?.longitude}`
    ).then(
      (response) => {
        const currentAddress = response.results[0].formatted_address;
        setSelectedElementLocation(currentAddress);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header
        icon={VanIcon}
        title="Vehicle Tracking"
        search={false}
        btnText4={"Back"}
        onAddClick4={() => navigate("/vehicle")}
      />

      {isLoaded ? (
        <div style={{ width: "100%", height: "100vh" }}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            disableDefaultUI={true}
            options={mapOptions}
            onLoad={onLoad}
            zoom={14}
            center={currentPosition}
          >
            {/* Render a single marker for the selected vehicle */}
            <MarkerF
              icon={{
                url: MapCar,
                anchor: new window.google.maps.Point(50, 100),
                scaledSize: new window.google.maps.Size(100, 100),
              }}
              position={{
                lat: currentPosition.lat,
                lng: currentPosition.lng,
              }}
              onClick={(marker) => {
                mapLocationLoad(vehicleData);
                setSelectedElement(vehicleData);
                setActiveMarker(marker);
              }}
            >
              {selectedElement?.id === vehicleData?.id ? (
                <InfoWindow
                  position={activeMarker?.latLng}
                  className={styles.infoWindow}
                  onCloseClick={() => {
                    setSelectedElement(null);
                    setActiveMarker(null);
                  }}
                  zIndex={100}
                >
                  <VehcileMapCard
                    map={true}
                    item={selectedElement}
                    address={selectedElementLocation}
                    // onClick={onClick}
                  />
                </InfoWindow>
              ) : null}
            </MarkerF>
          </GoogleMap>
        </div>
      ) : (
        <div>Loading Map...</div>
      )}
    </Block>
  );
};

export default VehicleTracking;
