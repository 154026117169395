import React from "react";
import "./DriverTable.module.css";
import { Table } from "react-bootstrap";

function DriverTable({ data, onClickRow }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Date of Birth</th>
          <th>Real Name ID</th>
          <th>Real Name ID Expiry</th>
          <th>Driving License No</th>
          <th>Driving License Expiry</th>
          <th>Mobile No</th>
          <th>Address</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index} onClick={onClickRow}>
            <td>{item?.id}</td>
            <td>{item?.name}</td>
            <td>{item?.date_of_birth}</td>
            <td>{item?.real_name_id}</td>
            <td>{item?.real_name_id_expiry}</td>
            <td>{item?.driving_license_no}</td>
            <td>{item?.driving_license_expiry}</td>
            <td>{item?.mobile_no}</td>
            <td>{item?.address}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DriverTable;
