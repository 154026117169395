import React from "react";
import styles from "./BatchTable.module.css";
import { Table } from "react-bootstrap";

function BatchTable({ data, onDownload }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className={styles.th}>Batch ID</th>
          <th className={styles.th}>Status</th>
          <th className={styles.th}>Valid Rows</th>
          <th className={styles.th}>Failed Rows</th>
          <th className={styles.th}>Original File Name</th>
          <th className={styles.th}></th>
        </tr>
      </thead>
      <tbody>
        {data?.length &&
          data?.map((item, index) => (
            <tr key={index}>
              <td className={styles.td}>{item.id}</td>
              <td className={styles.td}>{item?.status}</td>
              <td className={styles.td}>{item?.valid_rows}</td>
              <td className={styles.td}>{item?.failed_rows}</td>
              <td className={styles.link}>
                {item?.file_name?.filename_download}
              </td>
              <td
                className={styles.link}
                onClick={() =>
                  onDownload(
                    item?.file_name?.id,
                    item?.file_name?.filename_download
                  )
                }
              >
                {item?.file_name ? "Download Report" : ""}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default BatchTable;
