import React from "react";
import styles from "./SidebarMobile.module.css";
// import component 👇
import Drawer from "react-modern-drawer";
//import styles 👇
import "react-modern-drawer/dist/index.css";
import { appRoutes } from "../../routes";
import { useLocation } from "react-router-dom";
import MenuItem from "../MenuItem/MenuItem";
import Logo from "../../assets/svg/logo.svg";
import { IoLogOutSharp } from "react-icons/io5";
import { AuthService } from "../../services";
import { AuthContext, SocketContext } from "../../context";
import { RolesHelper } from "../../constants";

function SidebarMobile({ open, onClose }) {
  const location = useLocation();

  const { logout, user } = AuthContext.useAuth();
  const { disconnectSocket } = SocketContext.useSocket();

  const onLogout = async () => {
    const userString = await localStorage.getItem("user");
    const user = JSON.parse(userString);

    AuthService.logout(user?.data?.refresh_token)
      .then(() => {
        disconnectSocket();
        logout();
        window.location.reload();
      })
      .catch((err) => console.log(err));

    logout();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      direction="right"
      className={styles.sidebar}
    >
      <div className={styles.logoContainer}>
        <img src={Logo} alt="trackbuddy" className={styles.logo} />
      </div>

      {appRoutes?.routes?.map((item, index) => {
        const role = RolesHelper.getUserRole(user, item?.roles);

        if (!item.menu || !role) return null;

        const active =
          location?.pathname?.split("/", 2)[1] === item?.path.split("/", 2)[1];

        return (
          <MenuItem
            key={index}
            active={active}
            path={item?.path}
            icon={item?.icon}
            label={item?.name}
            isOpen={true}
          />
        );
      })}

      <div className={styles.logoout} onClick={onLogout}>
        <IoLogOutSharp size={30} />
        <span>Logout</span>
      </div>
    </Drawer>
  );
}

export default SidebarMobile;
