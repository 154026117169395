import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  Polyline,
} from "@react-google-maps/api";
import { MapStyles } from "../../constants";
import PinMarker from "../../assets/img/pin-marker.png";
import CircleOutline from "../../assets/img/circle-outline.png";

function AssignOrderMap({
  children,
  lat,
  lng,
  latCar1,
  lngCar1,
  latCar2,
  lngCar2,
}) {
  const [currentPosition, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });

  const [directions, setDirections] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ["geometry", "places"],
  });

  useEffect(() => {
    if (isLoaded) {
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(
            parseFloat(latCar1),
            parseFloat(lngCar1)
          ),
          destination: new window.google.maps.LatLng(
            parseFloat(latCar2),
            parseFloat(lngCar2)
          ),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.log(`Error fetching directions: ${status}`);
          }
        }
      );
    }
  }, [isLoaded, latCar1, lngCar1, latCar2, lngCar2]);

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const onLoad = useCallback((map) => {
    const locations = [
      {
        lat: latCar1,
        lng: lngCar1,
      },
      {
        lat: latCar2,
        lng: lngCar2,
      },
    ];

    map.setOptions({
      styles: MapStyles.styles,
    });

    //Type definitions for non-npm package Google Maps JavaScript API 3.50
    var bounds = new window.google.maps.LatLngBounds();

    locations.forEach((location) => {
      bounds.extend({ lat: Number(location.lat), lng: Number(location.lng) });
    });

    map.fitBounds(bounds); // <-------- resize based on markers, native
  }, []);

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return isLoaded ? (
    <div style={{ width: "100%", height: "100vh" }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        disableDefaultUI={true}
        options={mapOptions}
        onLoad={onLoad}
        zoom={14}
        center={{
          lat: lat || currentPosition?.lat,
          lng: lng || currentPosition?.lng,
        }}
      >
        {/* marker for pickup address */}
        <MarkerF
          position={{
            lat: parseFloat(latCar1),
            lng: parseFloat(lngCar1),
          }}
          icon={{
            url: PinMarker,
            anchor: new window.google.maps.Point(20, 50),
            scaledSize: new window.google.maps.Size(40, 50),
          }}
        />

        {/* marker for dropoff address */}
        <MarkerF
          position={{
            lat: parseFloat(latCar2),
            lng: parseFloat(lngCar2),
          }}
          icon={{
            url: CircleOutline,
            anchor: new window.google.maps.Point(12, 25),
            scaledSize: new window.google.maps.Size(25, 25),
          }}
        />

        {/* direction in map between two points */}
        {directions && (
          <Polyline
            path={directions?.routes[0]?.overview_path?.map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }))}
            options={{
              strokeColor: "#000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillOpacity: 0.35,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
              radius: 30000,
              zIndex: 100,
            }}
          />
        )}

        {/* more children */}
        {children}
      </GoogleMap>
    </div>
  ) : (
    <div>Loading Map...</div>
  );
}

export default AssignOrderMap;
