import React from "react";
import styles from "./DropdownForm.module.css";
import { Form } from "react-bootstrap";
import { IoAddCircleSharp } from "react-icons/io5";
import InputForm from "../InputForm/InputForm";

function DropdownForm({
  label,
  value,
  onChange,
  name,
  options,
  width,
  required,
  inValidMessage,
  add,
  onAdd,
  disabled,
  placeholder,
}) {
  return (
    <Form.Group
      className="mb-3"
      style={{ width }}
      controlId="exampleForm.ControlInput1"
    >
      <Form.Label className={styles.label}>
        {label} {add ? <IoAddCircleSharp size={18} onClick={onAdd} /> : null}
      </Form.Label>
      {options?.length ? (
        <Form.Select
          required={required}
          name={name}
          value={value}
          className={[styles.input, value === label && styles.placeholder]}
          onChange={onChange}
          isInvalid={inValidMessage ? true : false}
          disabled={disabled}
        >
          {options?.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </Form.Select>
      ) : (
        <div style={{ marginTop: -18 }}>
          <InputForm width="100%" placeholder={placeholder} />
        </div>
      )}
      {inValidMessage ? (
        <Form.Control.Feedback type="invalid">
          {inValidMessage}
        </Form.Control.Feedback>
      ) : (
        <Form.Control.Feedback type="invalid">
          Field is required
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default DropdownForm;
