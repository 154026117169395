import React from "react";
import styles from "./NotificationCard.module.css";

function NotificationCard({ notifications, show }) {
  return show ? (
    <div className={styles.container}>
      {notifications?.map((item, index) => (
        <div className={styles.notificationCard} key={index}>
          <div className={styles.circle} />
          <div className={styles.notification}>{item.notification}</div>
        </div>
      ))}
    </div>
  ) : null;
}

export default NotificationCard;
