import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./DriversVehicle.module.css";
import {
  AddDriverModal,
  AddDriverSidebar,
  Block,
  ConfirmationAlert,
  ContactsTable,
  DriversTableNew,
  Header,
  LoadingSpinner,
  Pagination,
  ScrollLock,
  TrialEnd,
} from "../../components";
import { Driver } from "../../assets/svg";
import {
  ContactsService,
  DriversService,
  SubscriptionService,
} from "../../services";
import { LoadingContext } from "../../context";
import { ErrorList, SubscriptionId } from "../../constants";
import { Toast, ToastContainer } from "react-bootstrap";
import { getSubscriptionByTenantId } from "../../services/subscription-service";
import { getAllDrivers } from "../../services/drivers-service";
import { log } from "util";

function DriversVehicle() {
  // hooks
  // const navigate = useNavigate();
  // context
  const { setLoading, loading } = LoadingContext.useLoading();
  // states
  const [open, setOpen] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const [addDriver, setAddDriver] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [editDriver, setEditDriver] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    id: null,
  });
  const [tabLoading, setTabLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    toastMessage: null,
    toastType: null,
  });
  const [editDriverDetails, setEditDriverDetails] = useState();
  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
    status: null,
  });
  const driverSidebar = useRef();

  const [totalCount, setTotalCount] = useState();
  const [trialRemaining, setTrialRemaining] = useState(null);

  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const fetchSubscription = async () => {
    try {
      const { subscription } = await getSubscriptionByTenantId();
      subscription && setSubscriptionDetails(subscription);
      console.log(subscription, "subscriptionDetails");
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTabLoading(true);
        // setLoading(true);
        // Wait for these async operations to complete before moving to the next step
        await fetchSubscription();
        await canAddDriver();
        await SubscriptionService.trialRemainingDays().then((res) => {
          setTrialRemaining(res);
        });

        // Now fetch the drivers

        const data = await DriversService.getAllDrivers(search, page);
        setDrivers(data?.data);
        setTotalCount(data?.total_count);
        driverSidebar.current = data?.data;
        console.log(data, "getDrivers");
      } catch (err) {
        console.log(err);
      } finally {
        // setLoading(false);
        setTabLoading(false);
      }
    };

    fetchData();
  }, [search, page, setTabLoading]);

  const canAddDriver = () => {
    const noOfDriversAllowed = Number(
      subscriptionDetails?.subscription_plan_id?.details.find(
        (detail) => detail.name === "NO_OF_DRIVERS"
      )?.value || 0
    );

    console.log(noOfDriversAllowed, "noOfDriversAllowed");

    return totalCount < noOfDriversAllowed; // Return true if the current number is less than the allowed number
  };

  const validateAddDriver = () => {
    const { first_name, last_name, email, password } = addDriver;

    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!first_name) {
      newErrors.first_name = "First Name is required";
    } else if (!last_name) {
      newErrors.last_name = "Last Name is required";
    } else if (!password) {
      newErrors.password = "Password is required";
    }

    return newErrors;
  };

  // add Driver
  const onAddNewDriver = async (event) => {
    event.preventDefault();
    const formErrors = validateAddDriver();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setLoading(true);
      DriversService.addDrivers(addDriver)
        .then((res) => {
          setToastMessage({
            toastType: "success",
            toastMessage: res,
          });
          setShow(true);
          setOpen(false);

          setAddDriver({
            email: "",
            first_name: "",
            last_name: "",
            password: "",
          });

          DriversService.getAllDrivers(search, page)
            .then((data) => {
              canAddDriver();
              console.log(data, "getAllDrivers");
              setDrivers(data?.data);
              setTotalCount(data?.total_count);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          setToastMessage({
            toastType: "danger",
            toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
          });
          setShow(true);
        })
        .finally(() => setLoading(false));
    }
  };

  const onEditDriver = async (event) => {
    event.preventDefault();

    setLoading(true);
    DriversService.editDrivers(editDriver)
      .then((res) => {
        setToastMessage({
          toastType: "success",
          toastMessage: res,
        });
        setShow(true);
        setOpen(false);
        setEditDriver({
          email: "",
          first_name: "",
          last_name: "",
          password: "",
          id: null,
        });

        DriversService.getAllDrivers(search, page)
          .then((data) => {
            canAddDriver();
            console.log(data, "getAllDrivers");
            setDrivers(data?.data);
            setTotalCount(data?.total_count);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      })
      .finally(() => setLoading(false));
  };
  const onDeleteDriver = async () => {
    setLoading(true);
    DriversService.deleteDrivers({ driverID: deleteModal?.id })
      .then((res) => {
        setToastMessage({
          toastType: "success",
          toastMessage: res,
        });
        setShow(true);

        DriversService.getAllDrivers(search, page)
          .then((data) => {
            canAddDriver();
            console.log(data, "getAllDrivers");
            setDrivers(data?.data);
            setTotalCount(data?.total_count);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      })
      .finally(() => {
        setLoading(false);

        setDeleteModal({ show: false, id: null, status: null });
      });
  };
  const onDeactivateDriver = async () => {
    setLoading(true);
    DriversService.deactivateDrivers({
      driverID: deleteModal?.id,
      status: deleteModal?.status,
    })
      .then((res) => {
        setToastMessage({
          toastType: "success",
          toastMessage: res,
        });
        setShow(true);

        DriversService.getAllDrivers(search, page)
          .then((data) => {
            canAddDriver();
            console.log(data, "getAllDrivers");
            setDrivers(data?.data);
            setTotalCount(data?.total_count);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      })
      .finally(() => {
        setLoading(false);
        setDeleteModal({ show: false, id: null, status: null });
      });
  };
  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };
  return (
    <>
      <Block
        style={{ padding: "0px 25px" }}
        onClick={() => open && setOpen(false)}
      >
        <Header
          icon={Driver}
          title="Drivers"
          searchMargin="0px 50px 0px 50px"
          btnText="+ Add"
          disableBtn1={!canAddDriver() && true}
          onAddClick={(event) => {
            event.preventDefault();

            if (canAddDriver()) {
              setEditMode(false);
              setAddDriver({ ...addDriver });
              setOpen(true);
            }
          }}
          importBtn={false}
          actionBtn={false}
          viewStyles={false}
          searchValue={search}
          onChangeSearch={(e) => {
            setSearch(e?.target?.value);
          }}
          trial={false}
        />
        {console.log(search, "searchBar")}
        {tabLoading ? (
          // Show loading spinner or message
          <></>
        ) : (
          !canAddDriver() && (
            <div className={styles.noData}>
              <p>
                You have reached the maximum allowed number of drivers for your
                subscription.
              </p>
              <span>Get Started Learn more about trackbuddy subscriptions</span>
            </div>
          )
        )}
        {tabLoading ? <LoadingSpinner componentLoading={tabLoading} /> : null}
        {!drivers?.length ? (
          <div className={styles.noData}>
            <p>No driver Available </p>
            <span>Get Started Learn more about trackbuddy drivers</span>
          </div>
        ) : (
          <DriversTableNew
            data={drivers}
            onClickEdit={(item) => {
              setEditMode(true);
              setEditDriver({
                first_name: item.first_name,
                last_name: item.last_name,
                email: item.email,
                password: "", // Set a default value or leave it empty, based on your requirements
                id: item.id,
              });
              setOpen(true);
              setEditDriverDetails(item);
              driverSidebar.current = item;
            }}
            onClickDelete={(item) =>
              setDeleteModal({ show: true, id: item?.id, status: item?.status })
            }
          />
        )}
        {/* {console.log(drivers, "DD")} */}
        {drivers?.data?.length || page > 1 ? (
          <Pagination
            page={page}
            totalPages={drivers?.total_pages}
            onPrev={() => page > 1 && setPage(page - 1)}
            onNext={() => page < drivers?.total_pages && setPage(page + 1)}
          />
        ) : null}
        <ConfirmationAlert
          message="Are you sure want to change driver status?"
          show={deleteModal?.show}
          onDone={onDeactivateDriver}
          onClose={() =>
            setDeleteModal({ show: false, id: null, status: null })
          }
        />
        <ToastContainer position="bottom-start" className="p-3">
          <Toast
            bg={toastMessage?.toastType || "success"}
            onClose={() => {
              setShow(false);
              setToastMessage({ toastMessage: null });
            }}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Body className={"text-white"}>
              {toastMessage?.toastMessage || "Copied to clipboard"}
            </Toast.Body>
          </Toast>
        </ToastContainer>

        <ScrollLock isLocked={open} />
      </Block>

      {open && (
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="overlay"
        />
      )}

      {trialRemaining < 1 &&
      subscriptionDetails?.subscription_plan_id?.id ==
        SubscriptionId.subscriptionId.TRIAL ? (
        <div className={styles.trial_end}>
          <TrialEnd />
        </div>
      ) : null}

      <AddDriverSidebar
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setEditMode(false);
          setEditDriverDetails();
        }}
        item={editDriverDetails}
        onAddClick={editMode ? onEditDriver : onAddNewDriver}
        errors={errors}
        onChange={
          editMode ? handleChange(setEditDriver) : handleChange(setAddDriver)
        }
        driver={editMode ? editDriver : addDriver}
        mode={editMode}
      />
    </>
  );
}

export default DriversVehicle;
