import React, { useState, createContext, useContext, useEffect } from "react";
import { useLoading } from "./LoadingContext";

const Context = createContext();
export const useAuth = () => useContext(Context);

export const AuthProvider = ({ children }) => {
  const { setLoading } = useLoading();
  const [user, setUser] = useState();

  useEffect(() => {
    setLoading(true);

    const userString = localStorage.getItem("user");
    if (userString === "undefined" || userString === null) {
      setUser(null);
      setLoading(false);
    } else {
      const user = JSON.parse(userString);
      setUser(user);
      setLoading(false);
    }
  }, []);

  const login = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };
  const contextValue = {
    user,
    login,
    logout,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
