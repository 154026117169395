import React from "react";
import styles from "./FormInput.module.css";

function FormInput({ placeholder, value, onChange, name, type, width }) {
  return (
    <div className={styles.inputContainer}>
      {value && <span className={styles.placeholder}>{placeholder}</span>}
      <input
        style={{ width }}
        className={styles.input}
        placeholder={placeholder || "placeholder"}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default FormInput;
