import React from 'react';
import { Badge } from 'react-bootstrap'; 

function DeliveryStatus({ status })  {
    let badgeBg = "";
    if (status === "Delivered") {
        badgeBg = "success";
    }
    else if (status === "Cancelled") {
        badgeBg = "danger";
    }
    else if (status === "Pending") {
        badgeBg = "warning";
    }
    else if (status === "Assigned") {
        badgeBg = "secondary";
    }
    else if (status === "In Progress") {
        badgeBg = "info";
    }
    else {
        badgeBg = "primary";
    }
    return (
        <Badge bg={badgeBg}>{status}</Badge>
     );
}
export default DeliveryStatus;