import React from "react";
import styles from "./SettingsRoleTable.module.css";
import { Table } from "react-bootstrap";
import Checkbox from "../Checkbox/Checkbox";

function SettingsRoleTable({ data, onClickRemove, check, onCheck, checked }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Select</th>
          <th>Role Name</th>
          <th>Users</th>
          <th>Created By</th>
          <th>Created On</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data?.length &&
          data?.map((item, index) => (
            <tr key={index}>
              <td>
                <Checkbox
                  checked={checked}
                  id={item.id}
                  value={check}
                  onChange={(e) => onCheck(e, item)}
                />
              </td>
              <td>{item?.email}</td>
              <td>{item?.role?.name}</td>
              <td>{item?.created_by}</td>
              <td>{item?.created_at}</td>
              {item?.role?.name !== "Administrator" ? (
                <td className={styles.id} onClick={() => onClickRemove(item)}>
                  Remove
                </td>
              ) : (
                <td />
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  );
}

export default SettingsRoleTable;
