import React from "react";
import styles from "./VehcileCard.module.css";
import { BiCurrentLocation } from "react-icons/bi";
import {
  BsBatteryFull,
  BsFillCircleFill,
  BsAndroid2,
  BsMapFill,
  BsApple,
} from "react-icons/bs";
import { FaSignal } from "react-icons/fa";
import truck from "../../assets/img/truck.png";
import van from "../../assets/img/van.png";
import suv from "../../assets/img/suv.png";
import bike from "../../assets/img/bike.png";
import { FileSizeHelper } from "../../helper";
import { API } from "../../utils/api";
import { DateTimeHelper } from "../../helper";
import { isMobile } from "react-device-detect";

const VehcileCard = ({ item, onClick, map, onMapView }) => {
  let vehicleImg;

  if (item?.type === "truck") {
    vehicleImg = truck;
  } else if (item?.type === "van") {
    vehicleImg = van;
  } else if (item?.type === "suv") {
    vehicleImg = suv;
  } else if (item?.type === "bike") {
    vehicleImg = bike;
  }

  return (
    <div className={map ? styles.mapContainer : styles.container}>
      <div
        className={styles.nameRow}
        style={{ marginBottom: "10px" }}
        onClick={onClick}
      >
        <div className={styles.camera}>
          {item?.vehicle_img ? (
            <img
              src={
                FileSizeHelper.isBlobUrl(item?.vehicle_img)
                  ? item?.vehicle_img
                  : `${API.assets}/${item?.vehicle_img}`
              }
              alt={item?.name}
              className={styles.carImg}
            />
          ) : (
            <img
              src={vehicleImg}
              alt={item?.name}
              className={styles.defaultCarImg}
            />
          )}
        </div>

        <span>{item?.name}</span>
      </div>

      <div
        className={styles.mobileDetailsRow}
        style={{ marginBottom: "10px" }}
        onClick={onClick}
      >
        <div>
          <BsFillCircleFill
            size={12}
            color={
              DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                item?.last_ping
              ) < 1
                ? "#64C937"
                : "black"
            }
          />
          <span>
            {DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
              item?.last_ping
            ) < 1
              ? "Online"
              : "Offline"}
          </span>
        </div>
        <div>
          <BsBatteryFull size={24} />
          <span>{`${item?.battery}%`}</span>
        </div>

        <div>
          <BiCurrentLocation size={24} />
          <span>{item?.locationEnabled ? "On" : "Off"}</span>
        </div>
      </div>

      <div className={styles.details} onClick={onClick}>
        <FaSignal size={16} style={{ marginRight: "4px" }} />
        <label>Network:</label>
        <span className={styles.address}>{`${item?.network}`}</span>
      </div>

      <div className={styles.details} onClick={onClick}>
        <label>Last Seen:</label>
        <span>
          {item?.last_ping
            ? DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                item?.last_ping
              ) < 1
              ? "a while ago"
              : DateTimeHelper.getTimeDifference(item?.last_ping)
            : DateTimeHelper.getTimeDifference(item?.date_created)}
        </span>
      </div>

      <div className={styles.mobileDetailsRow}>
        <div className={styles.details}>
          <label>Device:</label>
          <span className={styles.nameRow}>
            {item?.os_version === "Android" ? (
              <BsAndroid2 style={{ marginRight: "4px" }} />
            ) : (
              <BsApple style={{ marginRight: "4px" }} />
            )}
            {item?.model?.slice(0, 10)}..
          </span>
        </div>
        {!isMobile && (
          <div className={styles.details} onClick={onMapView}>
            <BsMapFill />
            <span> View on Map</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehcileCard;
