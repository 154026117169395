import React, { useState } from "react";
import styles from "./AcceptInvite.module.css";
import { Form, Toast, ToastContainer } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Button, InputForm } from "../../components";
import { Logo } from "../../assets/svg";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/delivery-guy.json";
import { AuthService } from "../../services";
import { BsEye, BsEyeSlash } from "react-icons/bs";

// Context
import { useNavigate, useSearchParams } from "react-router-dom";

function AcceptInvite() {
  const [loading, setLoading] = useState();
  const [searchParams] = useSearchParams();
  const token = searchParams?.get("token") || "";

  const navigate = useNavigate();

  const [show, setShow] = useState({
    isVisible: false,
    message: "",
    toastType: "",
  });
  const [userData, setUserData] = useState({
    password: "",
    confirm_password: "",
  });
  const [validated, setValidated] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const onForgotPassword = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else if (userData.password !== userData.confirm_password) {
      setValidated(true);
      setShow({
        isVisible: true,
        toastType: "danger",
        message: "Passwords do not match",
      });
    } else {
      setLoading(true);
      AuthService.acceptUserInvite({ password: userData?.password, token })
        .then((json) => {
          if (json?.errors?.length > 0) {
            setShow({
              isVisible: true,
              toastType: "danger",
              message: json?.errors[0]?.message,
            });
          } else {
            setShow({
              isVisible: true,
              toastType: "success",
              message: "Your password has been updated successfully",
            });

            setTimeout(() => {
              navigate("/signin");
            }, 1000);
          }
        })
        .catch((err) => console.log({ err }))
        .finally(() => setLoading(false));
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice",
      trasnparent: true,
    },
  };

  return (
    <div className={`container-fluid ${styles.container}`}>
      <div className="row">
        <div className={`col-sm-6 ${styles.leftContainer}`}>
          <div className={styles.headingContainer}>
            <Logo className={styles.logo} />
            <div className={styles.back}>Accept Invite</div>
          </div>

          <Form noValidate validated={validated} onSubmit={onForgotPassword}>
            <InputForm
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Password *"
              type={viewPassword ? "text" : "password"}
              name="password"
              value={userData.password}
              onChange={handleChange(setUserData)}
              lastIcon={viewPassword ? <BsEye /> : <BsEyeSlash />}
              onClickLastIcon={() => setViewPassword(!viewPassword)}
            />
            <InputForm
              required={true}
              width={isMobile ? "100%" : "80%"}
              label="Confirm Password *"
              type={viewConfirmPassword ? "text" : "password"}
              name="confirm_password"
              value={userData.confirm_password}
              onChange={handleChange(setUserData)}
              lastIcon={viewConfirmPassword ? <BsEye /> : <BsEyeSlash />}
              onClickLastIcon={() =>
                setViewConfirmPassword(!viewConfirmPassword)
              }
              error={show.message}
            />

            <div className={styles.btnWrapper}>
              <Button loading={loading} type="submit" btnText="Accept Invite" />
            </div>
          </Form>
        </div>
        <div className={`col-sm-6 ${styles.rightContainer}`}>
          <div className={styles.leftHeadingContainer}>
            <div className={styles.back}>Lets start real-time tracking!</div>
          </div>

          <div
            style={{
              height: isMobile ? "50vh" : "60vh",
              width: "90%",
            }}
          >
            <Lottie
              options={defaultOptions}
              height={isMobile ? "50vh" : "60vh"}
              width={"100%"}
            />
          </div>
        </div>
      </div>

      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg={show?.toastType}
          onClose={() => setShow({ isVisible: false })}
          show={show.isVisible}
          delay={3000}
          autohide
        >
          <Toast.Body>{show?.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default AcceptInvite;
