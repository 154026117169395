import styles from "./App.module.css";
import { LoadingSpinner, Sidebar } from "./components";
import { Route, Routes } from "react-router-dom";
// Routes
import { appRoutes, publicRoutes } from "./routes";
import { ProtectedRoutes, PublicRoutes } from "./utils";
import { AuthContext } from "./context";

function App() {
  const { user } = AuthContext.useAuth();

  return (
    <div className={styles.App}>
      {user && <Sidebar />}

      <LoadingSpinner />

      {user === undefined ? null : (
        <Routes>
          {/* protected routes */}
          <Route element={<ProtectedRoutes user={user} />}>
            {appRoutes?.routes?.map((router, index) => (
              <Route
                key={index}
                path={router.path}
                element={router.component}
              />
            ))}
          </Route>

          {/* not protected routes */}
          <Route element={<PublicRoutes user={user} />}>
            {publicRoutes?.routes?.map((router, index) => (
              <Route
                key={index}
                path={router.path}
                element={router.component}
              />
            ))}
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
