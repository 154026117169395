import React from "react";
import styles from "./DriverCard.module.css";
import Locate from "../../assets/svg/locate.svg";
import Tick from "../../assets/svg/tick.svg";
import truck from "../../assets/img/truck.png";
import van from "../../assets/img/van.png";
import suv from "../../assets/img/suv.png";
import bike from "../../assets/img/bike.png";
// import defaultVehicle from "../../assets/img/default-vehicle.png";
import { API } from "../../utils/api";
import { BsFillCircleFill } from "react-icons/bs";
import { DateTimeHelper } from "../../helper";

function DriverCard({ selected, onSelect, borderBottom, item }) {
  let vehicleImg;

  if (item?.type === "truck") {
    vehicleImg = truck;
  } else if (item?.type === "van") {
    vehicleImg = van;
  } else if (item?.type === "suv") {
    vehicleImg = suv;
  } else if (item?.type === "bike") {
    vehicleImg = bike;
  }
  return (
    <div
      className={`d-flex flex-row align-items-center ${styles.container}`}
      style={{ borderBottom: borderBottom ? "0.5px solid #d6d6d6" : null }}
      onClick={onSelect}
    >
      <div
        className={selected ? styles.selected : styles.unselect}
        onClick={onSelect}
      >
        {selected && <img src={Tick} alt="tick" className={styles.tick} />}
      </div>

      <div className={styles.profileCard}>
        {item?.vehicle_img ? (
          <img
            src={`${API.assets}/${item?.vehicle_img}`}
            alt={item?.name}
            className={styles.profile}
          />
        ) : (
          <img
            src={vehicleImg}
            alt={item?.name}
            className={styles.defaultCarImg}
          />
        )}
      </div>

      <div style={{ width: "60%" }}>
        <div className={styles.label}>{item?.name}</div>
        <div className={styles.row}>
          <div className={styles.label2}>
            <BsFillCircleFill
              size={8}
              color={
                DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                  item?.last_ping
                ) < 1
                  ? "#64C937"
                  : "#000000"
              }
              style={{ marginRight: 2 }}
            />
            {DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
              item?.last_ping
            ) < 1
              ? "Online"
              : "Offline"}
          </div>
          {item?.mobile_no ? (
            <div className={styles.horizontalSeparator}>|</div>
          ) : null}
          <div className={styles.label2}>{item?.mobile_no}</div>
        </div>
      </div>

      {/* <div className={styles.justRow}>
        <img src={Locate} alt="locate" className={styles.locate} />
      </div> */}
    </div>
  );
}

export default DriverCard;
