import React, { useRef, useState } from "react";
import styles from "./ImportModal.module.css";
import { Modal } from "react-bootstrap";

function ImportModal({ show, onHide, errors }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.modal}
    >
      {/* <Modal.Header className={styles.modalHeader}>Errors</Modal.Header> */}
      <Modal.Body className={styles.modalBody}>
        <div className="row">
          <ul>
            {errors &&
              errors.map((error, index) => <li key={index}>{error}</li>)}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ImportModal;
