import React from "react";
// import styles from "./Pickup.module.css";
import { Block, Header } from "../../components";
import { PickupIcon } from "../../assets/svg";

function Pickup() {
  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header icon={PickupIcon} title="Pickups" search={false} />
    </Block>
  );
}

export default Pickup;
