import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const getContacts = async (search, page) => {
  try {
    const { token, tenant_id } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API.contacts}?filter[tenant_id]=${tenant_id}&meta=filter_count&page=${page}`;
    const queries = [];

    if (search) queries.push(`search=${search}`);

    const query = queries?.join("&");

    if (query?.length) url += `&${query}&${API.sortnLimit}`;

    const response = await fetch(url, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return {
      data: json?.data,
      total_count: json?.meta?.filter_count,
      total_pages: Math.ceil(json?.meta?.filter_count / 10), // Calculate total number of pages
    };
  } catch (e) {
    throw e;
  }
};

export const getContactTabs = async () => {
  const { tenant_id, token } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.contacts_status_tab}/${tenant_id}`,
      obj
    );
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return json;
  } catch (e) {
    throw e;
  }
};

export const addContact = async (contract) => {
  try {
    const { token, tenant_id } = await getToken();

    const newContact = { ...contract, tenant_id };

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newContact),
    };

    const response = await fetch(API.contacts, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json;
  } catch (e) {
    throw e;
  }
};
export const addBatchContacts = async (batchContacts) => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(batchContacts),
    };

    const response = await fetch(API.contacts, obj);
    const json = await response.json();

    if (json?.errors?.length > 0) throw new Error(json?.errors[0]?.message);

    return json;
  } catch (e) {
    throw e;
  }
};

export const editContact = async ({ id, contact }) => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(contact),
    };

    const response = await fetch(`${API.contacts}/${id}`, obj);
    const json = await response.json();

    return json;
  } catch (e) {
    throw e;
  }
};

export const deleteContact = async (id) => {
  try {
    const { token } = await getToken();
    // console.log(driverID, "driverID");
    const obj = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API.contacts}/${id}`, obj);

    if (response.ok) return "Contact deleted successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
