import React from "react";
import styles from "./NoData.module.css";

function NoData({ message }) {
  return (
    <div className={styles.noData}>
      <p>{message || "No data available"}</p>
    </div>
  );
}

export default NoData;
