import React from "react";
import styles from "./ConfirmationAlert.module.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmationAlert({ show, message, onClose, onDone }) {
  return (
    <Modal show={show} onHide={onClose} size="sm" centered>
      <Modal.Body className={styles.modalBody}>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          No
        </Button>
        <Button variant="danger" onClick={onDone}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationAlert;
