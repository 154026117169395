import React, { useState, useEffect } from "react";
import styles from "./VehicleSidebarDetails.module.css";
import { BiCamera, BiCurrentLocation } from "react-icons/bi";
import { BsBatteryFull, BsFillCircleFill } from "react-icons/bs";
import { FiEdit, FiSave } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import Geocode from "react-geocode";
import { FileSizeHelper } from "../../helper";
import { API } from "../../utils/api";
import { DateTimeHelper } from "../../helper";
import { SettingsService, VehiclesService } from "../../services";
import truck from "../../assets/img/truck.png";
import van from "../../assets/img/van.png";
import suv from "../../assets/img/suv.png";
import bike from "../../assets/img/bike.png";
import Button from "../Button/Button";
import ConfirmationAlert from "../ConfirmationAlert/ConfirmationAlert";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_MAP_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("en");

function VehicleSidebarDetails({
  item,
  isOpen,
  onClose,
  onChangeImg,
  onClickImg,
  fileRef,
}) {
  const [address, setAddress] = useState();
  const [lastDeliveryAddress, setLastDeliveryAddress] = useState();
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState();
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState();
  const [dropoff_address, setDropoff_address] = useState();
  const location = item?.location ? JSON.parse(item?.location) : null;
  const last_delivery = item?.item?.last_delivery
    ? JSON.parse(item?.item?.last_delivery)
    : null;
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
  });
  console.log(item, "vehicleItems");
  useEffect(() => {
    if (location?.coords) {
      Geocode.fromLatLng(
        `${location?.coords?.latitude}`,
        `${location?.coords?.longitude}`
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setAddress();
    }
  }, [location?.coords]);

  useEffect(() => {
    if (last_delivery?.coords) {
      Geocode.fromLatLng(
        `${last_delivery?.coords?.latitude}`,
        `${last_delivery?.coords?.longitude}`
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setLastDeliveryAddress(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setLastDeliveryAddress();
    }
  }, [last_delivery?.coords]);

  useEffect(() => {
    const id = item?.id;
    VehiclesService.getVehicles()
      .then((data) => {
        const vehicle = data.data?.find((res) => res.id === id);
        console.log(vehicle, "vehicle");
        if (vehicle) {
          const lastDeliveries = vehicle.delivery_tasks;

          if (lastDeliveries && lastDeliveries.length > 0) {
            lastDeliveries.forEach((lastDel) => {
              setDropoff_address(lastDel?.dropoff_address);
            });
          } else {
            // No deliveries yet, handle this case
            console.log("No deliveries yet");
            // You can set a message or state to indicate no deliveries
            setDropoff_address("No deliveries yet");
          }
        }
      })
      .catch((err) => console.log(err));
  }, [item?.id]);

  const updateName = () => {
    VehiclesService.updateVehcileName({
      id: item?.id,
      name: name || item?.name,
    })
      .then((data) => setEdit(false))
      .catch((data) => console.log(data));
  };

  const updateVehicleType = (type) => {
    VehiclesService.updateVehcileType({
      id: item?.id,
      type: type || item?.type,
    })
      .then((data) => setSelected(type))
      .catch((data) => console.log(data));
  };

  const removeVehicle = async (type) => {
    setLoading(true);
    await SettingsService.removeUser(item?.driver_id)
      .then((data) => console.log(data, "user removed"))
      .catch((err) => console.log(err));
    VehiclesService.removeVehichle({
      id: item?.id,
      type: type || item?.type,
    })
      .then((data) => setSelected(type))
      .catch((data) => console.log(data))
      .finally(() => {
        setDeleteModal({ id: null, show: false });
        setLoading(false);
        onClose();
      });
  };

  return (
    <div
      className={`${styles.sidebarContainer} ${
        isOpen ? styles.sidebarOpen : styles.sidebarClosed
      }`}
    >
      <RxCross2
        size={22}
        className={styles.cross}
        onClick={() => {
          setEdit(false);
          setSelected();
          setName();
          onClose();
        }}
      />
      <ConfirmationAlert
        message="Are you sure want to remove vehicle?"
        show={deleteModal?.show}
        onDone={removeVehicle}
        onClose={() => setDeleteModal({ show: false, id: null, status: null })}
      />
      <div className={styles.nameRow}>
        <div className={styles.camera} onClick={onClickImg}>
          {item?.vehicle_img ? (
            <img
              src={
                FileSizeHelper.isBlobUrl(item?.vehicle_img)
                  ? item?.vehicle_img
                  : `${API.assets}/${item?.vehicle_img}`
              }
              alt={item?.name}
              className={styles.carImg}
            />
          ) : (
            <BiCamera size={30} color="#b5b5b5" />
          )}
          <input
            ref={fileRef}
            type="file"
            accept={"image/*"}
            onChange={(e) => onChangeImg(e, item)}
          />
        </div>
        {edit ? (
          <input
            maxLength={50}
            value={name || item?.name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <span>{name || item?.name}</span>
        )}

        {edit ? (
          <FiSave size={20} onClick={updateName} />
        ) : (
          <FiEdit size={20} onClick={() => setEdit(true)} />
        )}

        <div className={styles.headerBtn}>
          <div className={styles.separator} />
          <Button
            loading={loading}
            fontSize="13px"
            btnText={"Remove"}
            backgroundColor={"#F26565"}
            onClick={() => {
              setDeleteModal({ show: true, id: item?.id });
            }}
            padding="8px 35px"
          />
        </div>
      </div>

      <div className={styles.idRow}>
        <label>ID:</label>
        <span>{item?.id}</span>
      </div>

      <div className={styles.statusRow}>
        <BsFillCircleFill
          size={10}
          color={
            DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
              item?.last_ping
            ) < 1
              ? "#64C937"
              : "black"
          }
        />
        <span>
          {DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
            item?.last_ping
          ) < 1
            ? "Online"
            : "Offline"}
        </span>
      </div>

      <div className={styles.field}>
        <label>Vehicle Type</label>
        <div className={styles.vehicleRow}>
          <div
            onClick={() => updateVehicleType("truck")}
            className={`${styles.vehicleIcon} ${
              (selected || item?.type) === "truck" ? styles.selectedIcon : null
            }`}
          >
            <img src={truck} alt="truck" className={styles.icon} />
            <span className={styles.vehicleName}>Truck</span>
          </div>

          <div
            onClick={() => updateVehicleType("van")}
            className={`${styles.vehicleIcon} ${
              (selected || item?.type) === "van" ? styles.selectedIcon : null
            }`}
          >
            <img src={van} alt="van" className={styles.icon} />
            <span className={styles.vehicleName}>Van</span>
          </div>

          <div
            onClick={() => updateVehicleType("suv")}
            className={`${styles.vehicleIcon} ${
              (selected || item?.type) === "suv" ? styles.selectedIcon : null
            }`}
          >
            <img src={suv} alt="suv" className={styles.icon} />
            <span className={styles.vehicleName}>SUV</span>
          </div>

          <div
            onClick={() => updateVehicleType("bike")}
            className={`${styles.vehicleIcon} ${
              (selected || item?.type) === "bike" ? styles.selectedIcon : null
            }`}
          >
            <img src={bike} alt="bike" className={styles.icon} />
            <span className={styles.vehicleName}>M.Bike</span>
          </div>
        </div>
      </div>

      <div className={styles.field}>
        <label>
          <BsBatteryFull size={20} /> (Battery)
        </label>
        <span>{`${item?.battery}%`}</span>
      </div>

      <div className={styles.field}>
        <label>
          <BiCurrentLocation size={20} /> (GPS)
        </label>
        <span>{item?.locationEnabled ? "On" : "Off"}</span>
      </div>

      <div className={styles.field}>
        <label>Device</label>
        <span>{item?.os_version}</span>
      </div>

      <div className={styles.field}>
        <label>Last Seen</label>
        <span>
          {item?.last_ping
            ? DateTimeHelper.getMinutesDifferenceFromCurrentTimestamp(
                item?.last_ping
              ) < 1
              ? "a while ago"
              : DateTimeHelper.getTimeDifference(item?.last_ping)
            : DateTimeHelper.getTimeDifference(item?.date_created)}
        </span>
      </div>

      <div className={styles.field}>
        <label>Last Location</label>
        <span>{address}</span>
      </div>

      <div className={styles.field}>
        <label>Last Delivery</label>
        <span>{dropoff_address}</span>
      </div>

      <div className={styles.field}>
        <label>Associated Driver ID</label>
        <span>{item?.driver_id}</span>
      </div>

      <div className={styles.field}>
        <label>Device Brand</label>
        <span>{item?.brand}</span>
      </div>

      <div className={styles.field}>
        <label>Device Model</label>
        <span>{item?.model}</span>
      </div>

      <div className={styles.field}>
        <label>Latitude</label>
        <span>{location?.coords?.latitude}</span>
      </div>

      <div className={styles.field}>
        <label>Longitude</label>
        <span>{location?.coords?.longitude}</span>
      </div>

      <div className={styles.field}>
        <label>Network</label>
        <span>{item?.network}</span>
      </div>
    </div>
  );
}

export default VehicleSidebarDetails;
