import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  Polyline,
} from "@react-google-maps/api";
import { MapStyles } from "../../constants";
import PinMarker from "../../assets/img/pin-marker.png";
import CircleOutline from "../../assets/img/circle-outline.png";

function TaskDetailsMap({ lat, lng, latCar1, lngCar1, latCar2, lngCar2 }) {
  const [directions, setDirections] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ["geometry", "places"],
  });

  useEffect(() => {
    if (isLoaded) {
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(latCar1, lngCar1),
          destination: new window.google.maps.LatLng(latCar2, lngCar2),
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.log(`Error fetching directions: ${status}`);
          }
        }
      );
    }
  }, [isLoaded, latCar1, lngCar1, latCar2, lngCar2, lat, lng]);
  console.log({ directions });
  const onLoad = useCallback(
    (map) => {
      map.setOptions({
        styles: MapStyles.styles,
      });

      //Type definitions for non-npm package Google Maps JavaScript API 3.50
      var bounds = new window.google.maps.LatLngBounds();

      const locations = [
        {
          lat: latCar1,
          lng: lngCar1,
        },
        {
          lat: latCar2,
          lng: lngCar2,
        },
      ];

      locations.forEach((location) => {
        if (isNaN(location?.lat) || isNaN(location?.lng)) return;

        bounds.extend({ lat: location.lat, lng: location.lng });
      });

      map.fitBounds(bounds); // <-------- resize based on markers, native
    },
    [isLoaded, latCar1, lngCar1, latCar2, lngCar2, lat, lng]
  );

  const openMap = () => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&origin=${latCar1},${lngCar1}&destination=${latCar2},${lngCar2}`,
      "_blank"
    );
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "25vh",
  };

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    disableDoubleClickZoom: true,
    zoomControl: false,
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      disableDefaultUI={true}
      options={mapOptions}
      zoom={30}
      onLoad={onLoad}
      center={{
        lat,
        lng,
      }}
      // onDrag={openMap}
      onClick={openMap}
    >
      {/* marker for pickup address */}
      <MarkerF
        position={{
          lat: latCar1,
          lng: lngCar1,
        }}
        icon={{
          url: CircleOutline,
          anchor: new window.google.maps.Point(12, 25),
          scaledSize: new window.google.maps.Size(25, 25),
        }}
      />

      {/* marker for dropoff address */}
      <MarkerF
        position={{
          lat: latCar2,
          lng: lngCar2,
        }}
        icon={{
          url: PinMarker,
          anchor: new window.google.maps.Point(12, 25),
          scaledSize: new window.google.maps.Size(25, 25),
        }}
      />

      {/* direction in map between two points */}
      {directions && (
        <Polyline
          path={directions?.routes[0]?.overview_path?.map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }))}
          options={{
            strokeColor: "#000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 30000,
            zIndex: 100,
          }}
        />
      )}
    </GoogleMap>
  ) : (
    <div>Loading Map...</div>
  );
}

export default TaskDetailsMap;
