import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./Settings.module.css";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import {
  AddSettingJobField,
  AddSettingUser,
  Block,
  ConfirmationAlert,
  CountingBar,
  DropdownForm,
  Header,
  InputForm,
  LoadingSpinner,
  SettingsUserTable,
  TabsWihoutBadge,
} from "../../components";
import { SettingsIcon } from "../../assets/svg";
import {
  Alert,
  Button,
  OverlayTrigger,
  Toast,
  ToastContainer,
  Tooltip,
  ProgressBar,
} from "react-bootstrap";
import { SettingsService } from "../../services";
import truck from "../../assets/img/truck.png";
import van from "../../assets/img/van.png";
import suv from "../../assets/img/suv.png";
import bike from "../../assets/img/bike.png";
import { ErrorList } from "../../constants";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { getSubscriptionByTenantId } from "../../services/subscription-service";
import { FaInfoCircle } from "react-icons/fa";
import Switch from "react-switch";
import { log } from "util";
import { subscriptionId } from "../../constants/subscriptionPlans";

function Settings() {
  // states
  const [tabLoading, setTabLoading] = useState(false);
  const [loading, setLoading] = useState();

  const [tab, setTab] = useState("Account & Security");
  const [settings, setSettings] = useState({
    email: "",
    password: "",
    confirm_password: "",
    company_name: "",
  });
  const [vehiclesSetting, setVehiclesSetting] = useState({
    default_vehicle_name: "",
    ping_time: 30,
    vehicle_type: "",
    description: "",
  });
  const [deliveryJobSetting, setDeliveryJobSetting] = useState({
    default_delivery_type: "Select Delivery Type",
    default_delivery_window: "Select Delivery Window",
    default_payment_status: "Select Payment Status",
    delivery_type_options: [],
    delivery_window_options: [],
    payment_status_options: [],
  });
  const [deliveryTypeJobSetting, setDeliveryTypeJobSetting] = useState({
    default_delivery_type: "Select Delivery Type",
    delivery_type_options: [],
    key: "",
  });
  const [deliveryWindowJobSetting, setDeliveryWindowJobSetting] = useState({
    default_delivery_window: "Select Delivery Window",
    delivery_window_options: [],
    key: "",
  });

  const [deliveryPaymentJobSetting, setDeliveryPaymentJobSetting] = useState({
    default_payment_status: "Select Payment Status",
    payment_status_options: [],
    key: "",
  });

  const [users, setUsers] = useState();
  const [errors, setErrors] = useState({});
  const [errorUpdatePassword, setErrorUpdatePassword] = useState({});
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const [addSetting, setAddSetting] = useState({
    modal: false,
    heading: "",
    value: "",
    type: "",
    btnDisabled: false,
  });
  const [integrationSetting, setIntegrationSetting] = useState({
    isApiKey: false,
    apikey: null,
    tenant_id: null,
  });
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    toastMessage: null,
    toastType: null,
  });
  const [addUser, setAddUser] = useState({
    modal: false,
    email: "",
    role: "",
  });
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
  });
  const [customerNotification, setCustomerNotification] = useState({
    customer_email_notification: false,
    delivery_start: false,
    delivery_complete: false,
    delivery_arrived: false,
  });
  const [adminNotification, setAdminNotification] = useState({
    admin_email_notification: false,
    vehicle_added: false,
    delivery_not_successful: false,
    delivery_complete_admin: false,
  });
  const [emailCount, setEmailCount] = useState({
    customer: 0,
    admin: 0,
  });

  const [showWarning, setShowWarning] = useState(false);

  const handleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    // onSaveDeliveryPaymentStatusSetting(event.target.value);
    if (event.target.name === "default_delivery_type") {
      onSaveDeliveryTypeSetting(event.target.value);
    }
    if (event.target.name === "default_delivery_window") {
      onSaveDeliveryWindowSetting(event.target.value);
    }
    if (event.target.name === "default_payment_status") {
      onSaveDeliveryPaymentStatusSetting(event.target.value);
    }
    console.log(event.target.name, "name");
    console.log(event.target.value, "value");

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };

  const handleVehicleChange = (setter) => (event) => {
    setter((prev) => ({ ...prev, [event.target.name]: event.target.value }));

    if (!!errors[event.target.name]) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      });
    }
  };

  // useEffect to fetch details on changing tabs
  useEffect(() => {
    setShowWarning(false);

    if (tab === "Account & Security") {
      setTabLoading(true);

      SettingsService.getCurrentUser()
        .then((data) =>
          setSettings({ email: data?.email, company_name: data?.first_name })
        )
        .catch((err) => console.log(err))
        .finally(() => setTabLoading(false));
    } else if (tab === "Vehicles") {
      setTabLoading(true);
      // new
      getVehicleSettings();
    } else if (tab === "Delivery Jobs") {
      setTabLoading(true);

      SettingsService.getTenantAppSetting()
        .then((data) => {
          const deliveryType = data.filter(
            (entry) => entry.key === "default_delivery_type"
          );
          const deliveryWindow = data.filter(
            (entry) => entry.key === "default_delivery_window"
          );
          const deliveryPayment = data.filter(
            (entry) => entry.key === "default_payment_status"
          );
          console.log(deliveryType?.[0]?.key, "deliveryType");
          console.log(deliveryPayment, "paymentStatus");
          console.log(deliveryWindow, "deliveryWindow");
          // ... for delivery type
          if (deliveryType?.length) {
            let deliveryOptionsJson = JSON.parse(deliveryType?.[0]?.value);

            const defaultDeliveryType = deliveryOptionsJson.find(
              (option) => option.is_default === true
            );
            setDeliveryTypeJobSetting({
              default_delivery_type:
                defaultDeliveryType?.option || "Select Default Delivery Type",
              delivery_type_options: JSON.parse(deliveryType?.[0]?.value),
              key: deliveryType?.[0]?.key,
            });
          }
          // ... for delivery window

          if (deliveryWindow?.length) {
            let deliveryOptionsJson = JSON.parse(deliveryWindow?.[0]?.value);

            const defaultDeliveryWindow = deliveryOptionsJson.find(
              (option) => option.is_default === true
            );
            setDeliveryWindowJobSetting({
              default_delivery_window:
                defaultDeliveryWindow?.option ||
                "Select Default Delivery Window",
              delivery_window_options: JSON.parse(deliveryWindow?.[0]?.value),
              key: deliveryWindow?.[0]?.key,
            });
          }
          // ... for delivery payment status

          if (deliveryPayment?.length) {
            let deliveryOptionsJson = JSON.parse(deliveryPayment?.[0]?.value);

            const defaultDeliveryPayment = deliveryOptionsJson.find(
              (option) => option.is_default === true
            );
            setDeliveryPaymentJobSetting({
              default_payment_status:
                defaultDeliveryPayment?.option ||
                "Select Default Delivery Payment Status",
              payment_status_options: JSON.parse(deliveryPayment?.[0]?.value),
              key: deliveryPayment?.[0]?.key,
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setTabLoading(false));
    } else if (tab === "Integrations") {
      setTabLoading(true);

      SettingsService.getApiKey()
        .then((data) =>
          setIntegrationSetting({
            isApiKey: data?.isApiKey,
            apikey: "******************************",
            tenant_id: data?.tenant_id,
          })
        )
        .catch((err) => console.log(err))
        .finally(() => setTabLoading(false));
    } else if (tab === "Users & Roles") {
      setTabLoading(true);

      SettingsService.getUsers()
        .then((data) => {
          console.log(data, "gettingUser");
          setUsers(data?.data);
          setTotalCount(data?.total_count);
        })
        .catch((err) => console.log(err))
        .finally(() => setTabLoading(false));
    } else if (tab === "Notifications") {
      getNotificationsSetting();
    }
  }, [tab]);

  const getVehicleSettings = () => {
    SettingsService.getTenantAppSetting()
      .then((data) => {
        const vehicleData = data.filter(
          (entry) => entry.key === "vehicle_default_type"
        );
        console.log(vehicleData, "vehicleData");
        setVehiclesSetting({
          vehicle_type: vehicleData?.[0]?.value,
          default_vehicle_name: vehicleData?.[0]?.name,
          ping_time: vehicleData?.[0]?.ping_time || 30,
          description: vehicleData?.[0]?.description || null,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setTabLoading(false));
  };

  const getNotificationsSetting = () => {
    setTabLoading(true);
    SettingsService.getTenantAppSetting()
      .then((data) => {
        const customerNotification = data.filter(
          (entry) => entry.key === "customer_email_notifications"
        );
        const adminNotification = data.filter(
          (entry) => entry.key === "admin_email_notifications"
        );
        if (customerNotification.length) {
          let parsedData = JSON.parse(customerNotification[0]?.value);
          console.log(parsedData, "customerNotification");

          setCustomerNotification({
            customer_email_notification: parsedData[0]?.enabled,
            delivery_start: parsedData[1]?.enabled,
            delivery_complete: parsedData[2]?.enabled,
            delivery_arrived: parsedData[3]?.enabled,
          });
        }
        if (adminNotification.length) {
          let parsedData = JSON.parse(adminNotification[0]?.value);
          console.log(parsedData, "adminNotification");

          setAdminNotification({
            admin_email_notification: parsedData[0]?.enabled,
            vehicle_added: parsedData[1]?.enabled,
            delivery_not_successful: parsedData[2]?.enabled,
            delivery_complete_admin: parsedData[3]?.enabled,
          });
        }
      })

      .catch((err) => console.log(err))
      .finally(() => setTabLoading(false));
  };
  // validate vehicle input fields
  const validateVehicle = () => {
    const { default_vehicle_name, ping_time, vehicle_type } = vehiclesSetting;

    const newErrors = {};

    if (!default_vehicle_name) {
      newErrors.default_vehicle_name = "Default name is required";
    } else if (!vehicle_type) {
      newErrors.default_vehicle_type = "Default vehicle type is required";
    }

    return newErrors;
  };

  // function to save vehicle setting
  // const onSaveVehicleSetting = (event) => {
  //   event.preventDefault();
  //   const formErrors = validateVehicle();

  //   if (Object.keys(formErrors).length > 0) {
  //     if (formErrors.default_vehicle_type) {
  //       setToastMessage({
  //         toastType: "danger",
  //         toastMessage: formErrors.default_vehicle_type,
  //       });
  //       setShow(true);
  //       setToastMessage({ toastType: "", toastMessage: "" });
  //     } else {
  //       setErrors(formErrors);
  //     }
  //   } else {
  //     SettingsService.saveVehicleSetting(vehiclesSetting)
  //       .then((data) => {
  //         setUsers(data);
  //         setToastMessage({
  //           toastType: "success",
  //           toastMessage: "Vehicle settings updated",
  //         });
  //         setShow(true);
  //       })
  //       .catch((err) => {
  //         setToastMessage({
  //           toastType: "danger",
  //           toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
  //         });
  //         setShow(true);
  //       });
  //   }
  // };
  const saveVehicleAppSetting = () => {
    setTabLoading(true);
    // Transform the state to match the new API keys
    const transformedSetting = {
      name: vehiclesSetting.default_vehicle_name,
      ping_time: vehiclesSetting.ping_time,
      value: vehiclesSetting.vehicle_type,
    };
    const formErrors = validateVehicle();

    if (Object.keys(formErrors).length > 0) {
      if (formErrors.default_vehicle_type) {
        setToastMessage({
          toastType: "danger",
          toastMessage: formErrors.default_vehicle_type,
        });
        setShow(true);
        setToastMessage({ toastType: "", toastMessage: "" });
      } else {
        setErrors(formErrors);
      }
    } else {
      SettingsService.saveVehicleAppSetting(transformedSetting)
        .then((data) => {
          console.log(data, "vehicleAppSettingData");
          getVehicleSettings();
          setUsers(data);
          setToastMessage({
            toastType: "success",
            toastMessage: "Vehicle settings updated",
          });
          setShow(true);
        })
        .catch((err) => {
          setToastMessage({
            toastType: "danger",
            toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
          });
          setShow(true);
          console.log(err, "errorVehicleAppSettings");
        })
        .finally(() => setTabLoading(false));
    }
  };

  // validate vehicle delivery setting input fields
  const validateDeliverySetting = () => {
    const {
      default_delivery_type,
      default_delivery_window,
      default_payment_status,
    } = deliveryJobSetting;

    const newErrors = {};

    if (
      !default_delivery_type ||
      default_delivery_type === "Select Delivery Type"
    ) {
      newErrors.default_delivery_type = "Delivery type is required";
    } else if (
      !default_delivery_window ||
      default_delivery_window === "Select Delivery Window"
    ) {
      newErrors.default_delivery_window = "Delivery window is required";
    } else if (
      !default_payment_status ||
      default_payment_status === "Select Payment Status"
    ) {
      newErrors.default_payment_status = "Payment status is required";
    }

    return newErrors;
  };

  // save vehicle delivery setting
  const onSaveDeliveryTypeSetting = (deliveryTypeValue) => {
    console.log(deliveryTypeValue, "deliveryTypeValue");
    SettingsService.saveDeliveyTypeSetting(deliveryTypeValue)
      .then((data) => {
        setToastMessage({
          toastType: "success",
          toastMessage: "Delivery Type updated",
        });
        setShow(true);
        console.log(data, "saveDeliveyTypeSetting");
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      });
  };

  const onSaveDeliveryWindowSetting = (deliveryWindowValue) => {
    SettingsService.saveDeliveyWindowSetting(deliveryWindowValue)
      .then((data) => {
        setToastMessage({
          toastType: "success",
          toastMessage: "Delivery Window updated",
        });
        setShow(true);
        console.log(data, "saveDeliveyTypeSetting");
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      });
  };
  const onSaveDeliveryPaymentStatusSetting = (deliveryPaymentValue) => {
    SettingsService.saveDeliveyPaymentSetting(deliveryPaymentValue)
      .then((data) => {
        setToastMessage({
          toastType: "success",
          toastMessage: "Delivery Payment Status Updated",
        });
        setShow(true);
        console.log(data, "saveDeliveyTypeSetting");
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      });
  };
  // add delivery job setting
  const onAddDeliveryTypeJobSetting = () => {
    setLoading(true);
    setAddSetting({ ...addSetting, btnDisabled: true });
    SettingsService.addDeliveryTypeSettingOption(addSetting)
      .then((data) => {
        console.log(JSON.parse(data?.value), "gettingData");
        setAddSetting({ ...addSetting, modal: false, btnDisabled: false });
        setDeliveryTypeJobSetting({
          delivery_type_options: JSON.parse(data?.value),
        });
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);

        setAddSetting({ ...addSetting, btnDisabled: false });
      })
      .finally(() => setLoading(false));
  };
  const onAddDeliveryWindowJobSetting = () => {
    setLoading(true);
    setAddSetting({ ...addSetting, btnDisabled: true });
    SettingsService.addDeliveryWindowSettingOption(addSetting)
      .then((data) => {
        console.log(JSON.parse(data?.value), "gettingData");
        setAddSetting({ ...addSetting, modal: false, btnDisabled: false });
        setDeliveryWindowJobSetting({
          delivery_window_options: JSON.parse(data?.value),
        });
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);

        setAddSetting({ ...addSetting, btnDisabled: false });
      })
      .finally(() => setLoading(false));
  };
  const onAddDeliveryPaymentStatusJobSetting = () => {
    setLoading(true);
    setAddSetting({ ...addSetting, btnDisabled: true });
    SettingsService.addDeliveryPaymentSettingOption(addSetting)
      .then((data) => {
        console.log(JSON.parse(data?.value), "gettingData");
        setAddSetting({ ...addSetting, modal: false, btnDisabled: false });
        setDeliveryPaymentJobSetting({
          payment_status_options: JSON.parse(data?.value),
        });
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);

        setAddSetting({ ...addSetting, btnDisabled: false });
      })
      .finally(() => setLoading(false));
  };

  const onSaveAddSettings = (settingKey) => {
    console.log(settingKey, "settingKey");
    // setTabLoading(true);
    if (settingKey === "default_delivery_type") {
      onAddDeliveryTypeJobSetting();
    } else if (settingKey === "default_delivery_window") {
      onAddDeliveryWindowJobSetting();
    } else if (settingKey === "default_payment_status") {
      onAddDeliveryPaymentStatusJobSetting();
    }
    // setTabLoading(false);
  };
  // function to create apikey
  const onGenerateApiKey = () => {
    setTabLoading(true);

    SettingsService.generateApiKey()
      .then((res) => {
        setIntegrationSetting({
          isApiKey: true,
          apikey: res?.apiKey,
          tenant_id: res?.tenant_id,
        });
        setShowWarning(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setTabLoading(false));
  };

  // delete apikey
  const onDeleteApiKey = async () => {
    setTabLoading(true);

    SettingsService.deleteApiKey()
      .then(() =>
        setIntegrationSetting({
          isApiKey: false,
          apikey: null,
          tenant_id: null,
        })
      )
      .catch((err) => console.log(err))
      .finally(() => setTabLoading(false));
  };

  // copy text functions
  const copyTextToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    setShow(true);
  };

  // validate input user fields
  const validateAddUser = () => {
    const { email, role } = addUser;

    const newErrors = {};

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!role) {
      newErrors.role = "Role is required";
    }

    return newErrors;
  };

  // invite user
  const onAddRoleUser = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formErrors = validateAddUser();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      SettingsService.inviteUser(addUser)
        .then((res) => {
          setToastMessage({
            toastType: "success",
            toastMessage: res,
          });
          setShow(true);

          setAddUser({ modal: false, email: "", first_name: "", role: "" });
          SettingsService.getUsers()
            .then((data) => {
              console.log(data, "gettingUser");
              setUsers(data?.data);
              setTotalCount(data?.total_count);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          setToastMessage({
            toastType: "danger",
            toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
          });
          setShow(true);
        })
        .finally(() => setLoading(false));
    }
  };

  // remove user
  const onRemoveUser = () => {
    SettingsService.removeUser(deleteModal?.id)
      .then((data) => {
        setUsers(data?.data);
        setTotalCount(data?.total_count);

        setToastMessage({
          toastType: "success",
          toastMessage: "User Deleted Successfully",
        });
        setShow(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setDeleteModal({ show: false, id: null }));
  };
  const validateForm = () => {
    const { password, confirm_password } = settings;

    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;

    const newErrors = {};

    if (!password) {
      newErrors.password = "Password is required";
    } else if (!confirm_password) {
      newErrors.confirm_password = "Confirm Password is required";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    } else if (confirm_password.length < 8) {
      newErrors.confirm_password =
        "Password must be at least 8 characters long";
    } else if (password !== confirm_password) {
      newErrors.confirm_password = "Passwords didn't match";
    } else if (!strongPasswordRegex.test(password)) {
      newErrors.password =
        "Password must contain at least 1 uppercase, at least 1 digit and 1 special character characters";
    }

    return newErrors;
  };

  const onUpdatePassword = () => {
    console.log(settings.password, "password enter");
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrorUpdatePassword(formErrors);
    } else {
      SettingsService.updateUserPassword(settings.password)
        .then((data) => {
          setToastMessage({
            toastType: "success",
            toastMessage: "Password has been updated",
          });
          setShow(true);
          setErrorUpdatePassword({});

          console.log(data, "update password response");
        })
        .catch((err) => {
          console.log(err, "update password error");
        });
    }
  };
  // subscription handling
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const { subscription } = await getSubscriptionByTenantId();
        subscription && setSubscriptionDetails(subscription);
        console.log(subscription, "subscriptionDetails");
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, []);
  useEffect(() => {
    canAddUser();
  }, [subscriptionDetails, totalCount]);

  const canAddUser = () => {
    const noOfUsersAllowed = Number(
      subscriptionDetails?.subscription_plan_id?.details.find(
        (detail) => detail.name === "NO_OF_APP_USERS"
      )?.value || 0
    );

    console.log(noOfUsersAllowed, "noOfUsersAllowed");

    return totalCount < noOfUsersAllowed; // Return true if the current number is less than the allowed number
  };

  // ----------Notifications Handling---------
  const handleSwitchChange = (name, value) => {
    console.log(name, value, "handleSwitchChange");

    // Use the callback form of setCustomerNotification to ensure you have the latest state
    setCustomerNotification((prev) => {
      const updatedState = { ...prev, [name]: value };

      // Hit the API with the updated options
      SettingsService.addCustomerEmailNotification(updatedState)
        .then((data) => {
          // console.log(data, "customerDD");
          setToastMessage({
            toastType: "success",
            toastMessage: "customer emails notification updated",
          });
          setShow(true);
        })
        .catch((err) => {
          setToastMessage({
            toastType: "danger",
            toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
          });
          setShow(true);
        });

      return updatedState; // Return the updated state
    });
  };

  const handleSwitchChangeAdmin = (name, value) => {
    setAdminNotification((prev) => {
      const updatedState = { ...prev, [name]: value };

      // Hit the API with the updated options
      SettingsService.addAdminEmailNotification(updatedState)
        .then((data) => {
          // console.log(data, "customerDD");
          setToastMessage({
            toastType: "success",
            toastMessage: "admin emails notification updated",
          });
          setShow(true);
        })
        .catch((err) => {
          setToastMessage({
            toastType: "danger",
            toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
          });
          setShow(true);
        });

      return updatedState; // Return the updated state
    });
  };

  const getQuotaForCustomerEmail = () => {
    setTabLoading(true);
    SettingsService.getQuotaForCustomerEmail()
      .then((data) => {
        const customerEmailQuota = data.filter(
          (entry) => entry.feature === "NO_OF_CUSTOMER_EMAIL_NOTIFICATIONS"
        );
        const AdminEmailQuota = data.filter(
          (entry) => entry.feature === "NO_OF_ADMIN_EMAIL_NOTIFICATIONS"
        );
        console.log(customerEmailQuota, "quotaForEmail");

        setEmailCount({
          customer: customerEmailQuota[0],
          admin: AdminEmailQuota[0],
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setTabLoading(false));
  };

  useEffect(() => {
    getQuotaForCustomerEmail();
  }, []);
  return (
    <Block style={{ padding: "0px 25px" }}>
      <Header
        icon={SettingsIcon}
        title="Settings"
        search={false}
        trial={false}
      />

      <TabsWihoutBadge
        tabs={[
          "Account & Security",
          "Vehicles",
          "Delivery Jobs",
          "Integrations",
          "Users & Roles",
          "Notifications",
        ]}
        onSelect={(e) => setTab(e)}
      />

      <div className={styles.tabsContainer}>
        {/* when account and securtiy tab selected */}
        {tabLoading ? (
          <LoadingSpinner componentLoading={tabLoading} />
        ) : (
          tab === "Account & Security" && (
            <>
              <div className={styles.heading}>Account & Security</div>
              <div className="row">
                <div className="">
                  <InputForm
                    disabled={true}
                    label="Email Address"
                    name="email"
                    value={settings.email}
                    onChange={handleChange(setSettings)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <InputForm
                    label="New Password"
                    name="password"
                    type={viewPassword ? "text" : "password"}
                    value={settings.password}
                    onChange={handleChange(setSettings)}
                    lastIcon={viewPassword ? <BsEye /> : <BsEyeSlash />}
                    onClickLastIcon={() => setViewPassword(!viewPassword)}
                    inValidMessage={errorUpdatePassword.password}
                  />
                </div>
                <div className="col-sm-6">
                  <InputForm
                    label="Confirm Password"
                    name="confirm_password"
                    type={viewConfirmPassword ? "text" : "password"}
                    value={settings.confirm_password}
                    onChange={handleChange(setSettings)}
                    lastIcon={viewConfirmPassword ? <BsEye /> : <BsEyeSlash />}
                    onClickLastIcon={() =>
                      setViewConfirmPassword(!viewConfirmPassword)
                    }
                    inValidMessage={errorUpdatePassword.confirm_password}
                  />
                </div>
              </div>

              <Button onClick={() => onUpdatePassword()} variant="dark">
                Save
              </Button>
            </>
          )
        )}

        {/* when vehicles tab selected */}
        {tab === "Vehicles" && (
          <>
            <div className={styles.vehicleContainer}>
              <label style={{ marginRight: 5 }}>Vehicle Default Type</label>

              {/* <FaInfoCircle size={17} id="info-icon" />
              <Tooltip placement="top" target="info-icon" >
                'maaz hssain'
              </Tooltip> */}
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => (
                    <Tooltip
                      {...props}
                      style={{
                        top: "10px",
                        left: "235px",
                        position: "absolute",
                        fontFamily: "poppins",
                      }} // Adjust the 'top' value as needed
                    >
                      {vehiclesSetting?.description}
                    </Tooltip>
                  )}
                  placement="top"
                >
                  <FaInfoCircle size={17} />
                </OverlayTrigger>
              </div>
              <div className={styles.vehicleRow}>
                <div
                  onClick={() =>
                    setVehiclesSetting({
                      ...vehiclesSetting,
                      vehicle_type: "truck",
                    })
                  }
                  className={
                    vehiclesSetting?.vehicle_type === "truck"
                      ? styles.selectedIcon
                      : styles.unselectedIcon
                  }
                >
                  <img src={truck} alt="truck" className={styles.icon} />
                  <span className={styles.vehicleName}>Truck</span>
                </div>

                <div
                  onClick={() =>
                    setVehiclesSetting({
                      ...vehiclesSetting,
                      vehicle_type: "van",
                    })
                  }
                  className={
                    vehiclesSetting?.vehicle_type === "van"
                      ? styles.selectedIcon
                      : styles.unselectedIcon
                  }
                >
                  <img src={van} alt="van" className={styles.icon} />
                  <span className={styles.vehicleName}>Van</span>
                </div>

                <div
                  onClick={() =>
                    setVehiclesSetting({
                      ...vehiclesSetting,
                      vehicle_type: "suv",
                    })
                  }
                  className={
                    vehiclesSetting?.vehicle_type === "suv"
                      ? styles.selectedIcon
                      : styles.unselectedIcon
                  }
                >
                  <img src={suv} alt="suv" className={styles.icon} />
                  <span className={styles.vehicleName}>SUV</span>
                </div>

                <div
                  onClick={() =>
                    setVehiclesSetting({
                      ...vehiclesSetting,
                      vehicle_type: "bike",
                    })
                  }
                  className={
                    vehiclesSetting?.vehicle_type === "bike"
                      ? styles.selectedIcon
                      : styles.unselectedIcon
                  }
                >
                  <img src={bike} alt="bike" className={styles.icon} />
                  <span className={styles.vehicleName}>M.Bike</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <InputForm
                  label="Default Name"
                  name="default_vehicle_name"
                  value={vehiclesSetting.default_vehicle_name}
                  onChange={handleVehicleChange(setVehiclesSetting)}
                  inValidMessage={errors.default_vehicle_name}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-6">
                <InputForm
                  label="App Ping Interval (seconds)"
                  placeholder="Min. 1 & Max 120 seconds"
                  type="number"
                  min={1}
                  max={20}
                  name="ping_time"
                  value={vehiclesSetting.ping_time}
                  onChange={handleVehicleChange(setVehiclesSetting)}
                  inValidMessage={errors.ping_time}
                />
              </div>
            </div> */}

            <Button variant="dark" onClick={saveVehicleAppSetting}>
              Save
            </Button>
          </>
        )}

        {/* when delivery jobs tab selected */}
        {tab === "Delivery Jobs" && (
          <>
            <div className="row">
              <div className="col-sm-4">
                <DropdownForm
                  add={true}
                  onAdd={() =>
                    setAddSetting({
                      modal: true,
                      heading: "Add Delivery Type",
                      type: "default_delivery_type",
                    })
                  }
                  options={
                    deliveryTypeJobSetting?.delivery_type_options?.map(
                      (item) => item?.option
                    ) || []
                  }
                  label="Default Delivery Type"
                  placeholder="Select Delivery Type"
                  name="default_delivery_type"
                  value={deliveryTypeJobSetting.default_delivery_type}
                  onChange={handleChange(setDeliveryTypeJobSetting)}
                  // inValidMessage={errors.default_delivery_type}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4">
                <DropdownForm
                  add={true}
                  onAdd={() =>
                    setAddSetting({
                      modal: true,
                      heading: "Add Delivery Window",
                      type: "default_delivery_window",
                    })
                  }
                  options={
                    deliveryWindowJobSetting?.delivery_window_options?.map(
                      (item) => item?.option
                    ) || []
                  }
                  label="Default Delivery Window"
                  placeholder="Select Delivery Window"
                  name="default_delivery_window"
                  value={deliveryWindowJobSetting.default_delivery_window}
                  onChange={handleChange(setDeliveryWindowJobSetting)}
                  // inValidMessage={errors.default_delivery_type}
                />
              </div>
            </div>
            {console.log(addSetting?.type, "addSettingType")}
            <div className="row">
              <div className="col-sm-4">
                <DropdownForm
                  add={true}
                  onAdd={() =>
                    setAddSetting({
                      modal: true,
                      heading: "Add Delivery Payment Status",
                      type: "default_payment_status",
                    })
                  }
                  options={
                    deliveryPaymentJobSetting?.payment_status_options?.map(
                      (item) => item?.option
                    ) || []
                  }
                  label="Default Delivery Payment status"
                  placeholder="Select Payment Status"
                  name="default_payment_status"
                  value={deliveryPaymentJobSetting.default_payment_status}
                  onChange={handleChange(setDeliveryPaymentJobSetting)}
                  // inValidMessage={errors.default_delivery_type}
                />
              </div>
            </div>

            <AddSettingJobField
              loading={loading}
              show={addSetting?.modal}
              onHide={() => setAddSetting({ modal: false, heading: "" })}
              heading={addSetting?.heading}
              name="value"
              value={addSetting?.value}
              onChange={handleChange(setAddSetting)}
              disabled={addSetting.btnDisabled}
              onAddClick={() => onSaveAddSettings(addSetting?.type)}
            />
          </>
        )}

        {/* when Integrations tab selected */}
        {tab === "Integrations" && (
          <>
            <div className="d-flex ">
              <Button
                variant="dark"
                style={{ marginRight: 10 }}
                onClick={onGenerateApiKey}
                disabled={integrationSetting?.isApiKey}
              >
                Generate API KEY
              </Button>
              <Button variant="dark">API Documentation</Button>
            </div>

            {integrationSetting?.isApiKey ? (
              <div className={styles.keyBlock}>
                <div className={styles.apiRow}>
                  <span className={styles.apikey}>
                    <b>API Key:</b> {integrationSetting?.apikey}
                  </span>
                  <MdOutlineContentCopy
                    className={styles.copyIcon}
                    onClick={() =>
                      copyTextToClipboard(integrationSetting?.apikey)
                    }
                  />
                  <span className={styles.delete} onClick={onDeleteApiKey}>
                    Delete
                  </span>
                </div>
                <p>
                  <b>Tenant ID:</b> {integrationSetting?.tenant_id}{" "}
                  <MdOutlineContentCopy
                    className={styles.copyIcon}
                    onClick={() =>
                      copyTextToClipboard(integrationSetting?.tenant_id)
                    }
                  />
                </p>

                {showWarning && (
                  <Alert variant="warning">
                    Warning! save api key for future it will hide once page
                    refresh
                  </Alert>
                )}
              </div>
            ) : null}
          </>
        )}

        {/* when users and roles tab selected */}
        {tab === "Users & Roles" && (
          <>
            <div className={styles.heading}>
              Users{" "}
              <IoAddCircleSharp
                size={18}
                onClick={() => {
                  if (canAddUser()) {
                    setAddUser({ ...addUser, modal: true });
                  }
                }}
              />
            </div>
            {!canAddUser() && (
              <div className={styles.noData}>
                <p>
                  You have reached the maximum allowed number of app users for
                  your subscription.{" "}
                </p>
                <span>
                  Get Started Learn more about trackbuddy subscriptions
                </span>
              </div>
            )}
            {!users?.length ? (
              <div className={styles.noData}>
                <p>No users added yet, </p>
                <span>Get Started Learn more about trackbuddy users</span>
              </div>
            ) : (
              <SettingsUserTable
                data={users}
                settings={settings}
                onClickRemove={(item) =>
                  setDeleteModal({ show: true, id: item?.id })
                }
              />
            )}

            <ConfirmationAlert
              message="Are you sure you want to delete?"
              show={deleteModal?.show}
              onDone={onRemoveUser}
              onClose={() => setDeleteModal({ show: false, id: null })}
            />

            <AddSettingUser
              user={addUser}
              onHide={() => setAddUser({ ...addUser, modal: false })}
              heading={addUser?.heading}
              onChange={handleChange(setAddUser)}
              onAddClick={onAddRoleUser}
              errors={errors}
              loading={loading}
            />
          </>
        )}
        {tab === "Notifications" && (
          <>
            <div
              className={`${
                customerNotification.customer_email_notification
                  ? styles.notificationBg
                  : styles.withoutBg
              } col-sm-12`}
            >
              <div className="row">
                <div className={` col-sm-5`}>
                  <div className={`${styles.switch} `}>
                    <Switch
                      onChange={(e) => {
                        handleSwitchChange("customer_email_notification", e);
                        console.log(e, "inputCustomer_email_notification ");
                      }}
                      checked={customerNotification.customer_email_notification}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#000"
                      onHandleColor="#fff"
                    />
                    <span>Enable Customers Emails Notifications</span>
                  </div>
                  {customerNotification.customer_email_notification && (
                    <>
                      <div className={`${styles.switch} `}>
                        <Switch
                          onChange={(e) =>
                            handleSwitchChange("delivery_start", e)
                          }
                          checked={customerNotification.delivery_start}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#000"
                          onHandleColor="#fff"
                          disabled={
                            !customerNotification.customer_email_notification
                          }
                        />
                        <span>Notify when driver starts the delivery</span>
                      </div>
                      <div className={`${styles.switch} `}>
                        <Switch
                          onChange={(e) =>
                            handleSwitchChange("delivery_complete", e)
                          }
                          checked={customerNotification.delivery_complete}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#000"
                          onHandleColor="#fff"
                          disabled={
                            !customerNotification.customer_email_notification
                          }
                        />
                        <span>Notify when driver is completed</span>
                      </div>
                    </>
                  )}
                </div>
                {customerNotification.customer_email_notification && (
                  <div className="col-sm-7">
                    <div className="d-flex" style={{ marginTop: 20, gap: 18 }}>
                      <ProgressBar
                        className="col-sm-6"
                        variant="dark"
                        now={emailCount?.customer?.used || 0}
                      />

                      <span className={styles.spanTxt}>
                        {emailCount?.customer?.used || 0}/
                        {emailCount?.customer?.quota || 0} remaining
                      </span>
                      <span
                        style={{ color: "#F2B31D", cursor: "pointer" }}
                        className={styles.spanTxt}
                      >
                        Upgrade
                      </span>
                    </div>
                    <div className={`${styles.switch} `}>
                      <Switch
                        onChange={(e) =>
                          handleSwitchChange("delivery_arrived", e)
                        }
                        checked={customerNotification.delivery_arrived}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor="#000"
                        onHandleColor="#fff"
                        disabled={
                          !customerNotification.customer_email_notification
                        }
                      />
                      <span>Notify when driver has arrived</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={` ${
                adminNotification.admin_email_notification
                  ? styles.notificationBg
                  : styles.withoutBg
              } col-sm-12`}
              style={{ marginTop: 40 }}
            >
              <div className="row">
                <div className={` col-sm-5`}>
                  <div className={`${styles.switch} `}>
                    <Switch
                      onChange={(e) =>
                        handleSwitchChangeAdmin("admin_email_notification", e)
                      }
                      checked={adminNotification.admin_email_notification}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#000"
                      onHandleColor="#fff"
                      disabled={
                        subscriptionDetails?.subscription_plan_id?.id ==
                        subscriptionId.ENTERPRISE
                          ? false
                          : true
                      }
                    />
                    <span>Enable Admin Email Notifications</span>
                  </div>
                  {adminNotification.admin_email_notification ? (
                    <>
                      <div className={`${styles.switch} `}>
                        <Switch
                          onChange={(e) =>
                            handleSwitchChangeAdmin("vehicle_added", e)
                          }
                          checked={adminNotification.vehicle_added}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#000"
                          onHandleColor="#fff"
                        />
                        <span>Notify when a new vehicle is added </span>
                      </div>
                      <div className={`${styles.switch} `}>
                        <Switch
                          onChange={(e) =>
                            handleSwitchChangeAdmin(
                              "delivery_not_successful",
                              e
                            )
                          }
                          checked={adminNotification.delivery_not_successful}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#000"
                          onHandleColor="#fff"
                        />
                        <span>Notify when delivery is not successfull</span>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-sm-7">
                  {!adminNotification.admin_email_notification ? (
                    <div
                      className={`d-flex `}
                      style={{ marginTop: 20, gap: 18 }}
                    >
                      {subscriptionDetails?.subscription_plan_id?.id !==
                        subscriptionId.ENTERPRISE && (
                        <div className={`d-flex `} style={{ gap: 18 }}>
                          <span className={styles.spanTxt}>
                            Available in Buddy Enterprise plan
                          </span>
                          <span
                            style={{ color: "#F2B31D", cursor: "pointer" }}
                            className={styles.spanTxt}
                          >
                            Upgrade
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        className="d-flex"
                        style={{ marginTop: 20, gap: 18 }}
                      >
                        <ProgressBar
                          className="col-sm-6"
                          variant="dark"
                          now={emailCount?.admin?.used || 0}
                        />

                        <span className={styles.spanTxt}>
                          {emailCount?.admin?.used || 0}/
                          {emailCount?.admin?.quota || 0} remaining
                        </span>
                        <span
                          style={{ color: "#F2B31D" }}
                          className={styles.spanTxt}
                        >
                          Upgrade
                        </span>
                      </div>
                      <div className={`${styles.switch} `}>
                        <Switch
                          onChange={(e) =>
                            handleSwitchChangeAdmin(
                              "delivery_complete_admin",
                              e
                            )
                          }
                          checked={adminNotification.delivery_complete_admin}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#000"
                          onHandleColor="#fff"
                        />
                        <span>Notify when delivery is completed</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer position="bottom-start" className="p-3">
        <Toast
          bg={toastMessage?.toastType || "success"}
          onClose={() => {
            setShow(false);
            setToastMessage({ toastMessage: null });
          }}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Body className={"text-white"}>
            {toastMessage?.toastMessage || "Copied to clipboard"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Block>
  );
}

export default Settings;
