import React from "react";
import styles from "./AddSettingUser.module.css";
import { Modal } from "react-bootstrap";
import InputForm from "../InputForm/InputForm";
import Button from "../Button/Button";
import DropdownSetting from "../DropdownSetting/DropdownSetting";
import { RolesHelper } from "../../constants";

function AddSettingUser({
  onHide,
  onAddClick,
  user,
  onChange,
  errors,
  loading,
}) {
  return (
    <Modal
      show={user?.modal}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.modal}
    >
      <Modal.Body className={styles.modalBody}>
        <p>Add User</p>

        <InputForm
          width="90%"
          label="Email"
          name="email"
          value={user?.email}
          onChange={onChange}
          inValidMessage={errors?.email}
        />
        <DropdownSetting
          width="90%"
          label="Select Role"
          name="role"
          options={[
            {
              name: "Delivery and Vehicles Ops",
              value: RolesHelper.roles.deliveryAndVehicleOps,
            },
            {
              name: "Vehicles Ops",
              value: RolesHelper.roles.vehicleOps,
            },
            {
              name: "Delivery Ops",
              value: RolesHelper.roles.deliveryOps,
            },
            {
              name: "Admin",
              value: RolesHelper.roles.tenantAdmin,
            },
          ]}
          value={user?.role}
          onChange={onChange}
          inValidMessage={errors?.role}
        />

        <Button
          width="90%"
          btnText="Add"
          loading={loading}
          onClick={onAddClick}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddSettingUser;
