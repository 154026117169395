import { RolesHelper } from "../constants";
import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const getDrivers = async () => {
  try {
    const { token } = await getToken();

    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(API.drivers, obj);
    const json = await response.json();

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const addDrivers = async ({
  first_name,
  last_name,
  email,
  password,
}) => {
  try {
    const { token, tenant_id } = await getToken();

    console.log(first_name, last_name, email, password);
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        first_name,
        last_name,
        password,
        email,
        role: RolesHelper.roles.driverRole,
        tenant_id,
      }),
    };

    const response = await fetch(API.users, obj);

    if (response.ok) return "Driver added successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
export const getAllDrivers = async (search, page) => {
  const { tenant_id, token } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API.users}?filter[tenant_id]=${tenant_id}&filter[role][_in]=${RolesHelper.roles.driverRole}&meta=filter_count&page=${page}&fields=*.*,tenant_id.tenant_vehicles.*`;
    const queries = [];

    if (search) queries.push(`search=${search}`);

    const query = queries?.join("&");
    console.log(search, "searchedDrivers");
    if (query?.length) url += `&${query}`;

    const response = await fetch(url, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return {
      data: json?.data,
      total_count: json?.meta?.filter_count,
      total_pages: Math.ceil(json?.meta?.filter_count / 10), // Calculate total number of pages
    };
  } catch (e) {
    throw e;
  }
};

export const editDrivers = async ({
  first_name,
  last_name,
  email,
  password,
  id,
}) => {
  try {
    const { token } = await getToken();
    console.log(first_name, last_name, email, password, id, "reqBody");
    // Create an object to hold the properties with values
    const requestBody = {};

    // Include properties in the request body only if they are provided
    if (first_name) requestBody.first_name = first_name;
    if (last_name) requestBody.last_name = last_name;
    if (email) requestBody.email = email;
    if (password) requestBody.password = password;
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(`${API.users}/${id}`, obj);

    if (response.ok) return "Driver edited successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
export const deactivateDrivers = async ({ driverID, status }) => {
  try {
    const { token } = await getToken();
    console.log(driverID, status, "reqBody");
    // Create an object to hold the properties with values
    const requestBody = {};
    if (status === "active") {
      requestBody.status = "suspended";
    } else {
      requestBody.status = "active";
    }
    // Include properties in the request body only if they are provided

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(`${API.users}/${driverID}`, obj);

    if (response.ok) return "Driver Status Changed Successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
export const deleteDrivers = async ({ driverID }) => {
  try {
    const { token } = await getToken();
    // console.log(driverID, "driverID");
    const obj = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API.users}/${driverID}`, obj);

    if (response.ok) return "Driver deleted successfully";

    const json = await response.json();
    throw Error(json?.errors[0]?.extensions?.code);
  } catch (e) {
    throw e;
  }
};
