import React, { useEffect } from "react";
import styles from "./DriversTableNew.module.css";
import { Table } from "react-bootstrap";
import Checkbox from "../Checkbox/Checkbox";
import { DateTimeHelper } from "../../helper";
import { DriversService } from "../../services";
import { useNavigate } from "react-router-dom";
import { Vehicle } from "../../views";

function DriversTableNew({ data, onClickEdit, onClickDelete }) {
  const navigate = useNavigate();

  return (
    <Table responsive>
      <thead>
        <tr>
          <th className={styles.th}>First Name</th>
          <th className={styles.th}>Last Name</th>
          <th className={styles.th}>Email</th>
          <th className={styles.th}>Last Access</th>
          <th className={styles.th}>Associated Vehicle</th>
          <th className={styles.th}>Status</th>
          <th className={styles.th}></th>
          <th className={styles.th}></th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => {
          const driverId = item?.id;
          const associatedVehicle = item?.tenant_id?.tenant_vehicles?.find(
            (vehicle) => vehicle.driver_id === driverId
          );
          const vehicleName = associatedVehicle
            ? associatedVehicle.name
            : "N/A";
          console.log(item?.last_access);
          return (
            <tr key={index}>
              <td className={styles.td}>{item?.first_name}</td>
              <td className={styles.td}>{item?.last_name}</td>
              <td className={styles.td}>{item?.email}</td>
              <td className={styles.td}>
                {DateTimeHelper.formatUTCDate(item?.last_access || new Date())}
              </td>
              <td
                onClick={() => {
                  navigate("/vehicle");
                }}
                className={styles.td}
                style={{ cursor: "pointer" }}
              >
                {vehicleName}
              </td>

              <td>
                <span
                  className={` ${
                    item?.status === "active"
                      ? styles.activate
                      : styles.deactivate
                  }`}
                >
                  {item?.status}
                </span>
              </td>
              <td
                className={styles.id}
                onClick={() => {
                  console.log(item, "sendItem");
                  onClickEdit(item);
                }}
              >
                Edit
              </td>
              <td>
                <span
                  className={` ${
                    item?.status === "active"
                      ? styles.deactivate
                      : styles.activate
                  }`}
                  onClick={() => onClickDelete(item)}
                >
                  {item?.status === "active" ? "deactivate" : "activate"}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default DriversTableNew;
