import { getISOTimeDifference } from "../helper/dateTime";
import { API } from "../utils/api";
import { getToken } from "./auth-service";

export const subscribeVehicleSocket = async (socket) => {
  const { tenant_id } = await getToken();

  const obj = {
    type: "subscribe",
    collection: "vehicles",
    query: {
      filter: {
        tenant_id: {
          _eq: tenant_id,
        },
      },
    },
    uid: "v1",
  };

  socket.send(JSON.stringify(obj));
};

export const unSubscribeVehicleSocket = async (socket) => {
  const obj = {
    type: "unsubscribe",
    uid: "v1",
  };

  socket.send(JSON.stringify(obj));
};

export const getVehiclesSocket = async (socket) => {
  const { tenant_id } = await getToken();

  const obj = {
    type: "GET",
    collection: "vehicles",
    query: {
      filter: {
        tenant_id: {
          _eq: tenant_id,
        },
      },
    },
  };

  socket.send(JSON.stringify(obj));
};

export const getVehicles = async (search) => {
  const { tenant_id, token } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${API.vehicle}?filter[tenant_id]=${tenant_id}&meta=filter_count&fields=*,delivery_tasks.*`;
    const queries = [];

    if (search) queries.push(`search=${search}`);

    const query = queries?.join("&");

    if (query?.length) url += `&${query}&${API.sortnLimit}`;

    const response = await fetch(url, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return { data: json?.data, total_count: json?.meta?.filter_count };
  } catch (e) {
    throw e;
  }
};

export const getVehiclesForTracking = async (vehicleId) => {
  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let url = `${API.vehicle}?filter[id]=${vehicleId}&fields=*,delivery_tasks.*`;

    const response = await fetch(url, obj);
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    return { data: json?.data };
  } catch (e) {
    throw e;
  }
};

export const getVehilceTabs = async () => {
  const { token, tenant_id } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${API.vehicles_status_tab}/${tenant_id}`,
      obj
    );
    const json = await response.json();

    if (!response?.ok) throw new Error("Something went wrong");

    const capitalizedData = json?.map((item) => ({
      status: item?.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1),
      count: item?.count,
    }));

    return capitalizedData;
  } catch (e) {
    throw e;
  }
};

export const getVehiclesTabData = async (status) => {
  console.log(status, "statusss");
  if (status !== "All") {
    const { token, tenant_id } = await getToken();
    const pastTimeISO = await getISOTimeDifference();
    console.log(pastTimeISO, "pastTimeISO");
    const tab =
      status === "Offline"
        ? `filter[last_ping][_lte]=${pastTimeISO}`
        : `filter[last_ping][_gte]=${pastTimeISO}`;

    try {
      const obj = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(
        `${API.vehicle}?${tab}&filter[tenant_id]=${tenant_id}&meta=total_count`,
        obj
      );
      const json = await response.json();

      if (!response?.ok) throw new Error("Something went wrong");

      return { data: json?.data, total_count: json?.meta?.total_count };
    } catch (e) {
      throw e;
    }
  }
};

export const getVehiclesById = async (id) => {
  const { token } = await getToken();

  try {
    const obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(`${API.vehicle}/${id}`, obj);
    const json = await response.json();

    const vehicles = json?.data;

    return vehicles;
  } catch (e) {
    throw e;
  }
};

export const vehicleRegistration = async (code) => {
  const { tenant_id, token } = await getToken();

  try {
    const obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ code, tenant_id }),
    };

    const response = await fetch(API.registration, obj);
    const json = await response.json();

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const updateVehcileName = async ({ id, name }) => {
  const { token } = await getToken();

  try {
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    };

    const response = await fetch(`${API.vehicle}/${id}`, obj);
    const json = await response.json();

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const removeVehichle = async ({ id, name }) => {
  const { token } = await getToken();

  try {
    const obj = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    };

    const response = await fetch(`${API.vehicle}/${id}`, obj);
    const json = await response.json();

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const updateVehcileType = async ({ id, type }) => {
  const { token } = await getToken();

  try {
    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ type }),
    };

    const response = await fetch(`${API.vehicle}/${id}`, obj);
    const json = await response.json();

    return json?.data;
  } catch (e) {
    throw e;
  }
};

export const updateVehcile = async ({ id, img }) => {
  try {
    const { token } = await getToken();
    const vehicle_img = await uploadImg(img);

    const obj = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ vehicle_img }),
    };

    const response = await fetch(`${API.vehicle}/${id}`, obj);
    const json = await response.json();

    const vehicles = json?.data;

    return vehicles;
  } catch (e) {
    throw e;
  }
};

export const uploadImg = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const obj = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await fetch(API.files, obj);
    const json = await response.json();

    if (json?.error?.length) throw new Error(json?.error?.[0]);

    return json?.data?.filename_disk?.split(".")?.[0];
  } catch (e) {
    throw e;
  }
};
