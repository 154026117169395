// icons
import { ReactComponent as HomeIcon } from "../assets/svg/home.svg";
import { ReactComponent as VehicleIcon } from "../assets/svg/vehicle.svg";
import { ReactComponent as VanIcon } from "../assets/svg/van.svg";
import { ReactComponent as TasksIcon } from "../assets/svg/tasks.svg";
import { ReactComponent as AccountIcon } from "../assets/svg/account.svg";
import { ReactComponent as SettingsIcon } from "../assets/svg/settings.svg";
import { ReactComponent as HelpIcon } from "../assets/svg/help.svg";
import { ReactComponent as ProductIcon } from "../assets/svg/product.svg";
import { ReactComponent as DriverIcon } from "../assets/svg/driver.svg";
import { ReactComponent as DeliveriesIcon } from "../assets/svg/deliveries.svg";
import { ReactComponent as PickupIcon } from "../assets/svg/pickup.svg";

// views
import {
  AssignOrder,
  CustomerSupport,
  Dashboard,
  Contacts,
  Vehicle,
  Settings,
  DeliveryJobs,
  AddNewJob,
  AddContact,
  EditContact,
  EditJob,
  Products,
  JobBatchUpload,
  VehicleTracking,
} from "../views";
import { RolesHelper } from "../constants";
import Pickup from "../views/Pickup/Pickup";
import DriversVehicle from "../views/DriversVehicle/DriversVehicle";
import { Product } from "../assets/svg";

export const routes = [
  {
    name: "Dashboard",
    path: "/",
    component: <Dashboard />,
    icon: HomeIcon,
    menu: true,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Vehicles",
    path: "/vehicle",
    component: <Vehicle />,
    icon: VanIcon,
    menu: true,
    roles: [
      RolesHelper.roles.appUserRole,
      RolesHelper.roles.tenantAdmin,
      RolesHelper.roles.vehicleOps,
      RolesHelper.roles.deliveryAndVehicleOps,
    ],
  },
  {
    name: "Vehicle Tracking",
    path: "/vehicle/vehicle-Tracking",
    component: <VehicleTracking />,
    roles: [
      RolesHelper.roles.appUserRole,
      RolesHelper.roles.tenantAdmin,
      RolesHelper.roles.vehicleOps,
      RolesHelper.roles.deliveryAndVehicleOps,
    ],
  },
  {
    name: "Deliveries",
    path: "/delivery-jobs",
    component: <DeliveryJobs />,
    icon: DeliveriesIcon,
    menu: true,
    roles: [
      RolesHelper.roles.appUserRole,
      RolesHelper.roles.tenantAdmin,
      RolesHelper.roles.deliveryOps,
      RolesHelper.roles.deliveryAndVehicleOps,
    ],
  },
  {
    name: "Add New Task",
    path: "/delivery-jobs/add-new-job",
    component: <AddNewJob />,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Add New Job Batch",
    path: "/delivery-jobs/batch-upload-delivery-tasks",
    component: <JobBatchUpload />,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Edit Task",
    path: "/delivery-jobs/edit-job",
    component: <EditJob />,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Assign to Driver",
    path: "/delivery-jobs/assign-driver",
    component: <AssignOrder />,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },

  {
    name: "Drivers",
    path: "/drivers",
    component: <DriversVehicle />,
    icon: DriverIcon,
    menu: true,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },

  {
    name: "Contacts",
    path: "/contacts",
    component: <Contacts />,
    icon: AccountIcon,
    menu: true,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Add Contact",
    path: "/contacts/add-contact",
    component: <AddContact />,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Add Contact",
    path: "/contacts/edit-contact",
    component: <EditContact />,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
  {
    name: "Settings",
    path: "/settings",
    component: <Settings />,
    icon: SettingsIcon,
    menu: true,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },

  {
    name: "Help",
    path: "/customer-support",
    component: <CustomerSupport />,
    icon: HelpIcon,
    menu: true,
    roles: [RolesHelper.roles.appUserRole, RolesHelper.roles.tenantAdmin],
  },
];
