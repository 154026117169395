import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Block,
  ConfirmationAlert,
  ContactsTable,
  Header,
  NoData,
  Pagination,
  TrialEnd,
} from "../../components";
import { AccountIcon } from "../../assets/svg";
import { ContactsService, SubscriptionService } from "../../services";
import { LoadingContext } from "../../context";
import { Toast, ToastContainer } from "react-bootstrap";
import { ErrorList, SubscriptionId } from "../../constants";
import styles from "./Contacts.module.css";
import { getSubscriptionByTenantId } from "../../services/subscription-service";
function Contacts() {
  // hooks
  const navigate = useNavigate();
  // context
  const { setLoading } = LoadingContext.useLoading();
  // states
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    toastMessage: null,
    toastType: null,
  });
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: null,
  });
  const [trialRemaining, setTrialRemaining] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const fetchSubscription = async () => {
    try {
      const { subscription } = await getSubscriptionByTenantId();
      subscription && setSubscriptionDetails(subscription);
      console.log(subscription, "subscriptionDetails");
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
  };

  useEffect(() => {
    fetchSubscription();
    SubscriptionService.trialRemainingDays()
      .then((res) => {
        setTrialRemaining(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setLoading(true);

    ContactsService.getContacts(null, page)
      .then((data) => setContacts(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    getContacts();
  }, [search, page]);

  const getContacts = () => {
    ContactsService.getContacts(search, page)
      .then((data) => setContacts(data))
      .catch((err) => console.log(err));
  };

  const onCheck = (e, item) => {
    const items = contacts;
    const itemArray = items?.data?.map((arrItem) => {
      if (arrItem.id === item.id) {
        arrItem.check = e.target.checked;
      }
      return arrItem;
    });

    // eslint-disable-next-line array-callback-return
    const checked = itemArray?.filter((item) => {
      if (item.check) return item;
    });

    setSelectedContacts(checked);
  };

  // const onDeleteContact = () => {
  //   ContactsService.deleteContact(deleteModal?.id )
  //     .then(() => getContacts())
  //     .catch((err) => console.log(err));
  // };
  const onDeleteContact = async () => {
    setLoading(true);
    ContactsService.deleteContact(deleteModal?.id)
      .then((res) => {
        setToastMessage({
          toastType: "success",
          toastMessage: res,
        });
        setShow(true);
        getContacts();
      })
      .catch((err) => {
        setToastMessage({
          toastType: "danger",
          toastMessage: ErrorList?.Errors?.[err.message] || err?.message,
        });
        setShow(true);
      })
      .finally(() => {
        setLoading(false);
        setDeleteModal({ show: false, id: null });
      });
  };
  return (
    <>
      <Block style={{ padding: "0px 25px" }}>
        <Header
          icon={AccountIcon}
          title="Contacts"
          searchMargin="0px 50px 0px 50px"
          btnText="+ Add"
          onAddClick={(event) => {
            event.preventDefault();
            navigate("add-contact");
          }}
          exportBtn={true}
          exportData={selectedContacts}
          exportAllData={contacts?.data}
          exportFile="Contacts"
          importBtn={false}
          actionBtn={false}
          viewStyles={false}
          searchValue={search}
          onChangeSearch={(e) => setSearch(e?.target?.value)}
        />

        <ContactsTable
          data={contacts?.data}
          check={selectedContacts}
          onCheck={onCheck}
          onClickEdit={(contact) =>
            navigate("edit-contact", {
              state: { contact },
            })
          }
          onClickDelete={(item) => setDeleteModal({ show: true, id: item?.id })}
        />

        {contacts?.data?.length || page > 1 ? (
          <Pagination
            page={page}
            totalPages={contacts?.total_pages}
            onPrev={() => page > 1 && setPage(page - 1)}
            onNext={() => page < contacts?.total_pages && setPage(page + 1)}
          />
        ) : null}

        <ConfirmationAlert
          message="Are you sure you want to delete?"
          show={deleteModal?.show}
          onDone={onDeleteContact}
          onClose={() => setDeleteModal({ show: false, id: null })}
        />
        {!contacts?.data?.length && <NoData />}

        <ToastContainer position="bottom-start" className="p-3">
          <Toast
            bg={toastMessage?.toastType || "success"}
            onClose={() => {
              setShow(false);
              setToastMessage({ toastMessage: null });
            }}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Body className={"text-white"}>
              {toastMessage?.toastMessage || "Copied to clipboard"}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Block>
      {trialRemaining < 1 &&
      subscriptionDetails?.subscription_plan_id?.id ==
        SubscriptionId.subscriptionId.TRIAL ? (
        <div className={styles.trial_end}>
          <TrialEnd />
        </div>
      ) : null}
    </>
  );
}

export default Contacts;
